<!-- 嘉宾介绍 -->
<template>
    <div class="m-guest">
        <div class="guest-item" v-for="(item, index) in guestList" :key="index" @click="toGuestInfo(item.speaker_info_url)">
            <div class="photo">
                <img :src="item.avatar" alt="">
            </div>
            <div class="user-info">
                <div class="user-name">{{item.real_name}}</div>
                <div class="user-company">{{item.org}}</div>
            </div>
        </div>
        <van-popup v-model="iframeShow">
            <div class="iframe-content">
                <span class="icons iconfont icon-guanbi1" @click="iframeShow = false"></span>
                <iframe :src="speakerInfoUrl" width="100%" height="100%"></iframe>
            </div>
        </van-popup>
    </div>
</template>
<script>
export default {
    props: {
        guestList: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            speakerInfoUrl: 'https://medcon.sciconf.cn/cn/minisite/speaker-tips/1111?user_id=73183',
            iframeShow: false
        }
    },
    methods: {
        toGuestInfo (url) {
            this.speakerInfoUrl = url
            this.iframeShow = true
        }
    },
    created () {
        console.log(this.guestList)
    }
}
</script>
<style lang="less" scoped>
.m-guest {
    .van-popup {
        width: 90% !important;
        height: 90% !important;
         &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        &::-webkit-scrollbar-track-piece {
            background: rgba(0, 0, 0, 0.1);
            -webkit-border-radius: 6px;
        }
        .iframe-content {
            width: 100%;
            height: 100%;
            position: relative;
            .icons {
                position: absolute;
                right: 0.5rem;
                top: 0.5rem;
                z-index: 66;
                cursor: pointer;
            }
        }
    }
    .guest-item {
        width: 100%;
        height: 2.5rem;
        border-bottom: 1px solid #E6E6E6;
        display: flex;
        align-items: center;
        justify-content: left;
        .photo {
            width: 1.7rem;
            height: 1.7rem;
            border-radius: 50%;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
                object-position: center center;
            }
        }
        .user-info {
            flex: 1;
            padding-left: 0.4rem;
            .user-name {
                font-size: 0.64rem;
                color: #333333;
            }
            .user-company {
                font-size: 0.56rem;
                color: #8f8f94;
                margin-top: 0.2rem;
            }
        }
    }
}

</style>