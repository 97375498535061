<template>
    <div class="m-caption">
        <div v-if="liveDetail.cnen == '1'">
            <div class="caption" v-if="liveDetail.captions_url_en"><!-- 英文 -->
                <iframe :src="liveDetail.captions_url_en" frameborder="0" width="100%" height="100%"></iframe>
            </div>
            <div class="caption" v-if="liveDetail.captions_url"> <!-- 中文 -->
                <iframe :src="liveDetail.captions_url" frameborder="0" width="100%" height="100%"></iframe>
            </div>
        </div>
        <div v-else>
            <div class="caption" v-if="liveDetail.captions_url"> <!-- 中文 -->
                <iframe :src="liveDetail.captions_url" frameborder="0" width="100%" height="100%"></iframe>
            </div>
            <div class="caption" v-if="liveDetail.captions_url_en"><!-- 英文 -->
                <iframe :src="liveDetail.captions_url_en" frameborder="0" width="100%" height="100%"></iframe>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
        }
    },
    computed: {
        ...mapState(['liveDetail'])
    },
    methods: {
    },
    created () {
    },
    mounted () {
    }
}
</script>
<style lang="less" scoped>
.m-caption {
    width: 100%;
    .caption {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        height: 3rem;
        padding: 0.3rem 0.3rem;
        box-sizing: border-box;
        background: #ebeffd;
        margin-top: 0.5rem;
    }
}
</style>