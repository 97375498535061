<!-- 广告弹窗 -->
<template>
    <div class="pop-adv">
        <div class="content">
            <a :href="advConfig.url">
                <img :src="advConfig.img | urlFilter" alt="">
                <span class="close iconfont icon-guanbi1" @click.prevent="$parent.isPopAdv = false"></span>
            </a>
        </div>
    </div>
</template>
<script>
export default {
    name: 'popAdv',
    props: {
        advConfig: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            imgUrl: 'https://files.sciconf.cn/',
            timer: null
        }
    },
    methods: {
    },
    created () {
    }
}
</script>
<style lang="less" scoped>
// 移动端
.pop-adv {
    position: fixed;
    width: 100%;
    z-index: 6;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    .content {
        width: 100%;
        height: 100%;
        position: relative;
        a {
            display: block;
            width: 274px;
            height: auto;
            position: relative;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            img {
                width: 274px;
                height: auto;
                
            }
        }
        span {
            color: #666666;
            background-color: rgba(255, 255, 255, 0.7);
            position: absolute;
            z-index: 6;
            right: 0.5rem;
            top: 0.5rem;
            cursor: pointer;
            padding: 0.3rem;
            border-radius: 1rem;
            font-size: 0.46rem;
        }
    }
}
// pc端
@media screen and (min-width: 550px) {
.pop-adv {
    position: fixed;
    width: 1200px;
    z-index: 6;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    .content {
        width: 100%;
        height: 100%;
        position: relative;
        a {
            display: block;
            width: 274px;
            height: auto;
            position: relative;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            img {
                width: 274px;
                height: auto;
                
            }
        }
        span {
            color: #666666;
            background-color: rgba(255, 255, 255, 0.7);
            position: absolute;
            z-index: 6;
            right: 0.5rem;
            top: 0.5rem;
            cursor: pointer;
            padding: 0.3rem;
            border-radius: 1rem;
            font-size: 0.46rem;
        }
    }
}
}
</style>