<!-- 暖场 -->
<template>
    <div class="container">
        <div class="live-warmup">
            <video
                ref="myVideoWarmup" 
                :src="liveDetail.is_ad_url"
                autoplay
                controls
                controlslist="nodownload noplaybackrate nokeyboard nodownload"
                loop>
            </video>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    name: 'live-warmup',
    data() {
        return {
        }
    },
    computed: {
        ...mapState(['liveDetail']),
    },
    methods: {
        // 已开始播放回调
        onPlayerPlaying(player) {
            this.$refs.myVideoWarmup.play()
        },
    },
    created() {
    },
    mounted() {
    },
}
</script>
<style lang="less" scoped>
.live-warmup {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    video {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
    video::-webkit-media-controls-timeline {
        display: none;
    }
}
@media screen and (min-width: 550px) {
    .fixed-style {
        position: fixed;
        right: 0px;
        bottom: 0px;
        width: 400px;
        z-index: 99;
    }
}
</style>