<!-- 广告弹窗 -->
<template>
    <div class="top-adv">
        <div class="content">
            <span class="close">
                {{count}}s{{$t('autoClose')}}
            </span>
            <van-swipe :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="(item, index) in advConfig" :key="index">
                    <a :href="item.url">
                        <img :src="item.img | urlFilter" alt="">
                    </a>
                </van-swipe-item>
            </van-swipe>
        </div>
    </div>
</template>
<script>
import { debounce } from '@/utils/tools';
export default {
    name: 'topAdv',
    props: {
        advConfig: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            imgUrl: 'https://files.sciconf.cn/',
            count: 15,
            timer: null,
            scrollTop: 0
        }
    },
    methods: {
        countdown () {
            this.timer = setInterval(() => {
                this.count--
                if (this.count === 0) {
                    clearInterval(this.timer)
                    this.timer = null
                    this.$parent.isTopAdv = false
                }
            }, 1000)
        },
        scrollHandler () {
            if (window.screen.width < 500) {
                let startY; // 滑动开始位置
                let endY; // 滑动结束位置
                let distance = 50; // 滑动距离
                window.addEventListener("touchstart", (event) => {
                    startY = event.touches[0].clientY;
                })
                window.addEventListener("touchend", (event) => {
                    endY = event.changedTouches[0].clientY;
                    if (this.$parent.advObject.top_ad && this.$parent.advObject.top_ad.length > 0) {
                        if (startY > endY) { // 上滑
                            this.$parent.isTopAdv = false
                            clearInterval(this.timer)
                            this.timer = null
                        } else if ((endY > startY) && (endY - startY) >= distance) { // 下拉
                            if (window.scrollY <= 0) {
                                if (this.$parent.isTopAdv) return;
                                this.$parent.isTopAdv = true
                                clearInterval(this.timer)
                                this.timer = null
                                this.count = 15
                                this.countdown()
                            }
                        }
                    }
                })
            }
        }
    },
    created () {
        this.countdown()
    },
    mounted () {
        this.scrollHandler()
        // if (window.screen.width < 500) {
        //     window.onscroll = debounce((e) => {
        //         if (window.scrollY > this.scrollTop) { // 上滑
        //             this.$parent.isTopAdv = false
        //             clearInterval(this.timer)
        //             this.timer = null
        //         } else { // 下滑
        //             this.$parent.isTopAdv = true
        //             clearInterval(this.timer)
        //             this.timer = null
        //             this.count = 15
        //             this.countdown()
        //         }
        //         this.scrollTop = window.scrollY
        //     }, 1000)
        // }
    },
    beforeDestroy () {
        clearInterval(this.timer)
        this.timer = null
    }
}
</script>
<style lang="less" scoped>
/deep/.van-swipe {
    height: 100%;
}
// 移动端
.top-adv {
    width: 100%;
    .content {
        width: 100%;
        position: relative;
        height: 100%;
        a {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        span {
            color: #666666;
            background-color: rgba(255, 255, 255, 0.7);
            position: absolute;
            z-index: 6;
            right: 0.4rem;
            top: 0.6rem;
            cursor: pointer;
            padding: 0.1rem 0.6rem;
            border-radius: 1rem;
            font-size: 0.46rem;
        }
    }
}
// pc端
@media screen and (min-width: 550px) {
.welcome-adv {
    position: fixed;
    width: 1200px;
    z-index: 66;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    .content {
        width: 400px;
        height: auto;
        overflow: hidden;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        a {
            display: block;
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
}
}

</style>