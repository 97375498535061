//  判断是否为移动端
export function isMobile () {
    let isMobile = false;
    if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        // console.log('移动端');
        isMobile = true;
    }
    if (document.body.clientWidth < 800) {
        isMobile = true;
    }
    return isMobile
}
// 是否是移动端微信
export function isWeiXin () {
    const ua = window.navigator.userAgent.toLowerCase();
    //平台、设备和操作系统
    const system = {
        win: false,
        mac: false,
        xll: false,
        ipad: false
    };
    //检测平台
    const p = navigator.platform;
    system.win = p.indexOf("Win") == 0;
    system.mac = p.indexOf("Mac") == 0;
    system.xll = (p == "xll") || (p.indexOf("Linux") == 0);
    system.ipad = (navigator.userAgent.match(/iPad/i) != null) ? true : false;
    if (system.win || system.mac || system.xll || system.ipad) {
        return false;
    } else if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        //在手机微信上打开的
        return true;
    } else {
        //在手机非微信上打开的
        return false;
    }
}