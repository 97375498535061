<template>
    <!-- 直播倒计时组件 -->
    <div v-if="show && is_open_countdown" class="__count-down flex x-center y-center flex-column">
        <div class="__count-down-wrapper">
            <div class="__count-down-header t-c">{{$t('liveStartText')}}</div>
            <div class="flex __count-down-list x-center y-center">
                <div class="__count-down-item flex x-center y-center flex-column">
                    <div class="__count-down-text">{{Days}}</div>
                    <div class="__count-down-title">{{$t('countDown.days')}}</div>
                </div>
                <p class="__count-down-p">:</p>
                <div class="__count-down-item flex x-center y-center flex-column">
                    <div class="__count-down-text">{{Hours}}</div>
                    <div class="__count-down-title">{{$t('countDown.hours')}}</div>
                </div>
                <p class="__count-down-p">:</p>
                <div class="__count-down-item flex x-center y-center flex-column">
                    <div class="__count-down-text">{{Minutes}}</div>
                    <div class="__count-down-title">{{$t('countDown.minutes')}}</div>
                </div>
                <p class="__count-down-p">:</p>
                <div class="__count-down-item flex x-center y-center flex-column">
                    <div class="__count-down-text">{{Seconds}}</div>
                    <div class="__count-down-title">{{$t('countDown.seconds')}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let timer = null;
export default {
    name: "countDown",
    data() {
        return {
            Days: '00',
            Hours: '00',
            Minutes: '00',
            Seconds: '00',
            show:false,
        };
    },

    components: {},

    computed: {
        // 开始时间
        startTime () {
            return this.$store.state.liveDetail.start_time
        },
        // 是否开启倒计时
        is_open_countdown () {
            return this.$store.state.live_config.is_open_countdown == 1
        }
    },

    watch:{
        startTime(newVal){
            newVal && this.getCountDown();
        }
     },

    methods: {
        // 根据时间戳计算具体持续时间,天 时 分 秒
        dateFormat: function (datetime) {
            let totalTime = datetime;
            if (totalTime <= 0) {
                //return '0,00:00:00';
                this.Days = '00';
                this.Hours = '00';
                this.Minutes = '00';
                this.Seconds = '00';
            }
            let days = num(parseInt(totalTime / (1000 * 60 * 60 * 24)));
            let hours = num(parseInt((totalTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
            let minutes = num(parseInt((totalTime % (1000 * 60 * 60)) / (1000 * 60)));
            let seconds = num(parseInt((totalTime % (1000 * 60)) / 1000));

            function num(val) {
                return val >= 10 ? val : '0' + val;
            }
            this.Days = days;
            this.Hours = hours;
            this.Minutes = minutes;
            this.Seconds = seconds;
            //return days + ',' + hours + ':' + minutes + ':' + seconds;
        },

        /* 获取北京时间 */
        UTCToLocalTimeString: function (d) {
            var timeOffsetInHours = (new Date().getTimezoneOffset() / 60) + 8; //这里的 8是时区，东八区为北京时间，PS：东区为正数 西区为负数
            d.setHours(d.getHours() + timeOffsetInHours);
            return d;
        },
        /* 获取倒计时 */
        getCountDown() {
            let nowTime = this.UTCToLocalTimeString(new Date()).getTime();
            let startTime = this.startTime.replace(/-/g, '/');
            startTime = +(new Date(startTime));
            // 未开始调用倒计时
            if (startTime > nowTime) {
                // 显示倒计时
                this.show = true;
                this.dateFormat(startTime - nowTime);
                timer = setTimeout(() => {
                    this.getCountDown();
                },1000)
            }else{
                // 刷新页面
                timer && window.location.reload();
                clearTimeout(timer);
            }
        }
    },

     mounted(){
     }
}

</script>
<style lang='less' scoped>
.__count-down {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    color: #fff;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    &-header {
        font-size: 0.65rem;
        position: relative;
        z-index: 2;
    }
    &-list {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 2;
    }
    &-item {
        height: 2.5rem;
        width: 2.5rem;
        &:nth-of-type(1) {
            background: url(~@/assets/images/circle1.png) no-repeat center
                center;
            background-size: 99% 99%;
        }
        &:nth-of-type(2) {
            background: url(~@/assets/images/circle2.png) no-repeat center
                center;
            background-size: 99% 99%;
        }
        &:nth-of-type(3) {
            background: url(~@/assets/images/circle3.png) no-repeat center
                center;
            background-size: 99% 99%;
        }
        &:nth-of-type(4) {
            background: url(~@/assets/images/circle4.png) no-repeat center
                center;
            background-size: 99% 99%;
        }
    }
    &-text {
        font-weight: bold;
        font-size: 0.6rem;
    }
    &-title {
        font-size: 0.45rem;
    }
    &-p {
        width: 0.8rem;
        text-align: center;
    }
}

@media screen and (min-width: 769px) {
    .__count-down {
        &-header {
            font-size: 1rem;
        }
        &-item {
            height: 4rem;
            width: 4rem;
        }
        &-text {
            font-size: 1rem;
        }
        &-title {
            font-size: 0.75rem;
        }
        &-p {
            width: 0.8rem;
        }
    }
}
</style>