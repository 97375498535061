<!-- 观看须知 -->
<template>
    <div class="m-notice" v-if="isShow">
        <div class="notice-content">
            <div class="notice-text" v-if="aggree_tan_content.indexOf('<') == -1">
                {{aggree_tan_content}}
            </div>
            <div class="notice-text" v-else v-html="aggree_tan_content"></div>
            <div class="btn">
                <van-button type="info" @click="clickHandle('consent')">{{$t('agree')}}</van-button>
                <van-button class="err" @click="clickHandle('refuse')" type="default">{{$t('refuse')}}</van-button>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            isShow: false
        }
    },
    computed: {
        ...mapState(['live_config', 'aggree_tan_content'])
    },
    watch: {
        live_config (newVal, oldVal) {
            if (newVal.is_open_tan == '1') {
                let isNotice = localStorage.getItem(`${this.$route.params.id}isNotice`) || ''
                if (isNotice == '') {
                    this.isShow = true
                }
            } else {
                this.isShow = false
            }
        }
    },
    methods: {
        clickHandle (type) {
            if (type === 'consent') {
                this.isShow = false
                localStorage.setItem(`${this.$route.params.id}isNotice`, '1')
            } else {
                const userAgent = navigator.userAgent
                const ua = navigator.userAgent.toLowerCase()
                if (userAgent.indexOf('Firefox') != -1 || userAgent.indexOf('Chrome') != -1) { 
                    window.location.href = 'about: blank'
                } 
                if (userAgent.indexOf('Android') > -1 || userAgent.indexOf('Linux') > -1) {
                    window.opener = null
                    window.open('about: blank', '_self', '').close()
                } 
                if (ua.match(/MicroMessenger/i) == "micromessenger") { 
                    WeixinJSBridge.call('closeWindow'); //微信
                } 
                if (ua.indexOf("baidu") != -1) {
                    BLightApp.closeWindow(); //百度 
                }
                window.opener = null
                window.open('about: blank', '_self')
                window.close()
            }
        }
    },
    created () {
    },
    mounted () {
    }
}
</script>
<style lang="less" scoped>
.m-notice {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 66;
    background: rgba(0, 0, 0, 0.3);
    .notice-content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 12rem;
        height: auto;
        padding: 0.6rem;
        background: #ffffff;
        box-sizing: border-box;
    }
    .btn {
        text-align: right;
        margin-top: 0.5rem;
        .err {
            background: #EEEEEE;
            margin-left: 0.5rem;
        }
         .van-button {
            border-radius:20px;
            height: 1.5rem;
        }
    }
}
// 移动端
@media screen and (max-width: 550px) {
}
// pc端
@media screen and (min-width: 550px) {
}
</style>