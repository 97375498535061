<!-- 在线展览 -->
<template>
    <div class="m-exhibition">
        <ul>
            <li v-for="(item, index) in exhibitionList" :key="index" @click="toExhibitionDetail(item.id)">
                <span>{{index + 1}}.</span>
                <div class="exhibition-info">
                    <div class="left-content">
                        <img :src="item.org_logo | urlFilter" alt="">
                    </div>
                    <div class="right-content">
                        <div class="title">{{ $i18n.locale == 'cn' ? item.team_name : item.team_enname}}</div>
                        <div class="icon-box">
                            <div>
                                <span class="icon iconfont icon-gerentouxiang_o"></span>
                                <span class="text">{{item.concern_num}}</span>
                            </div>
                            <div>
                                <span class="icon iconfont icon-fenxiang3"></span>
                                <span class="text">{{item.share_num}}</span>
                            </div>
                            <div>
                                <span class="icon iconfont icon-xingxing"></span>
                                <span class="text">{{item.collect_num}}</span>
                            </div>
                            <div>
                                <span class="icon iconfont icon-yanjing"></span>
                                <span class="text">{{item.browse_num}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="imgList">
                    <img v-for="(subItem,subIndex) in item.goods_list" :key="subIndex" :src="subItem.img_url" alt="">
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    props: {
        exhibitionList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapState(['meeting_id'])
    },
    methods: {
        toExhibitionDetail (id) {
            window.location.href = `https://medcon.sciconf.cn/cn/online-exhibitor/company-detail/${this.meeting_id}?team_id=${id}`
        }
    },
    created () {
    },
    mounted () {
        console.log(this.exhibitionList)
    }
}
</script>
<style lang="less" scoped>
.m-exhibition {
    width: 100%;
    height: 100%;
    ul {
        li {
            padding-left: 1rem;
            position: relative;
            box-sizing: border-box;
            margin-bottom: 1.5rem;
            cursor: pointer;
            >span {
                position: absolute;
                left: 0;
                top: 0;
                color: var(--fontColor);
            }
            &:before {
                content: '';
                width: calc(100% - 1rem);
                height: 1px;
                position: absolute;
                bottom: -0.75rem;
                left: 0;
                background: var(--borderColor);
                left: 1rem;
            }
            .exhibition-info {
                display: flex;
                align-items: center;
                .left-content {
                    width: 3rem;
                    height: 3rem;
                    border: 1px solid var(--borderColor);
                    overflow: hidden;
                    position: relative;
                    img {
                        position: absolute;
                        width: 90%;
                        height: auto;
                        margin: 0 auto;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .right-content {
                    flex: 1;
                    padding-left: 0.2rem;
                    box-sizing: border-box;
                    height: 3rem;
                    .title {
                        font-size: 0.56rem;
                        color: var(--fontColor);
                        font-weight: bold;
                        margin-bottom: 1.5rem;
                    }
                    .icon-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        div {
                            flex: 1;
                            span {
                                color: var(--fontColor1);
                            }
                            .icon {
                                font-size: 0.64rem;
                            }
                            .text {
                                font-size: 0.46rem;
                                margin-left: 0.1rem;
                            }
                        }
                    }
                }
            }
            .imgList {
                background: var(--bodyColor);
                overflow: hidden;
                margin-top: 0.2rem;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                padding: 0 0.2rem;
                box-sizing: border-box;
                img {
                    margin: 0.4rem 1.65%;
                    float: left;
                    width: 30%;
                    object-fit: cover;
                }
            }
        }
    }
}
// 移动端
@media screen and (max-width: 550px) {
    .imgList {
        img {
            height: 4rem !important;
        }
    }
}
// pc端
@media screen and (min-width: 550px) {
    .imgList {
        img {
            height: 8rem !important;
        }
    }
}
</style>