<template>
    <div class="__char-content">
        <div class="__char-content-wrap">
            <!-- 文本输入框 -->
            <div class="__char-text" ref="charElem" focus @input="handleInputEvent" contenteditable="true"></div>
            <!-- 表情与提交按钮 -->
            <div class="__char-edit flex y-center">
                <i @click="showEmotion = !showEmotion" class="iconfont icon-biaoqing1" :class="[ showEmotion ? 'icon-icon' : 'icon-biaoqing1']"></i>
                <a @click="handleSendEvent" class="__char-edit-btn" href="javascript:;">{{$t('message.sendBtn')}}</a>
            </div>
        </div>
        <!-- 表情弹框 -->
        <div :hidden="!showEmotion" class="__char-facebox">
            <a class="__char-emotion" @click="handleEmotionClickEvent(item.title)" v-for="(item,index) in emotionslist" :key="index" :style="{backgroundPosition:(-48*index)+'px 0px'}" href="javascript:;"></a>
        </div>
    </div>
</template>

<script>
import emotionslist from '@/utils/emotions.js'
export default {
    data() {
        return {
            showMessage:false,
            type:'message',
            // 输入的内容
            charMessage: '',
            showEmotion: false,
            emotionslist,
        }
    },
    methods: {
        // 数据绑定
        handleInputEvent(e) {
            this.showEmotion = false;
            this.charMessage = e.target.innerHTML;
        },

        //表情文字 替换 表情图片
        replace_em(str) {
            var emojiTxtArr = str.match(/[^\[]+(?=\])/g);
            str = str.replace(/<\/div>/g, "");
            str = str.replace(/<br>/g, "");
            str = str.replace(/<div>/g, "");
            if (emojiTxtArr) {
             emojiTxtArr.forEach(function (ele,idx) {
                emotionslist.forEach(function (value) {
                    let title = value.title.replace('[','').replace(']','');
                    if (ele == title) {
                        str = str.replace('[' + ele + ']', '<img style="width:0.7rem;height:0.7rem;" src = "' + value.url + '" alt= "' + ele + '" >');
                    }
                })
            })
        }
            return str;
        },

        // 表情图片 转化 表情文字
        replace_img(str) {
            var emojiImgArr = str.match(/<\s*img\b.*?(?:\>|\/>)/g);
            if (emojiImgArr) {
                emojiImgArr.forEach(function (ele,idx) {
                    var imgAlt = ele.match(/[\u4e00-\u9fa5]/g);
                    imgAlt = imgAlt.join('').split(',');
                    str = str.replace(ele, '[' + imgAlt + ']');
                })
            }
            return str;
        },

        // 点击表情
        handleEmotionClickEvent(title) {
            let replace_title = this.replace_em(title);
            this.charMessage = this.charMessage + replace_title;
            this.$refs.charElem.innerHTML = this.charMessage;
        },
        // 内容置底
        setContentBottom () {
            this.$nextTick(() => {
                const content = document.getElementsByClassName('van-tabs__content')[0]
                content.scrollTop = content.scrollHeight
            })
        },
        // 发送评论
        handleSendEvent () {
            // 触发发送事件
            let content = this.replace_img(this.charMessage);
            if (content.replace(/&nbsp;/g, '') != '') {
                this.$emit('sendComment',content)
            }
            //  恢复默认状态
            this.showEmotion = false;
            this.showMessage = false;
            this.$refs.charElem.innerHTML = '';
            this.charMessage = '';
            this.setContentBottom()
        },
    }
}
</script>
<style lang='less' scoped>
.__char-content {
    background: var(--backgroundColor);
    height: 100%;
    width: 100%;
    position: relative;
    &-wrap {
        padding: 0.5rem;
        .__char-text {
            resize: none;
            width: 100%;
            overflow-x: hidden;
            box-sizing: border-box;
            border-radius: 0.2rem;
            background-color: var(--bodyColor);
            border: none;
            padding: 0.37rem;
            overflow: auto;
            font-size: 0.6rem;
            outline: 0px solid transparent;
            height: 1.5rem;
            box-sizing: border-box;
            color: var(--fontColor);
            border: 1px solid rgba(255,255,255,0.3) !important;
            &:focus {
                border: 1px solid #fff !important;
            }
            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }
            &::-webkit-scrollbar-track-piece {
                background: rgba(0, 0, 0, 0.1);
                -webkit-border-radius: 6px;
            }
        }
        .__char-edit {
            margin-top: 0.1rem;
            .iconfont {
                font-size: 1rem;
                cursor: pointer;
                padding: 0.2rem;
                color: var(--fontColor1);
            }
            &-btn {
                border: 2px solid var(--tabActiveColorLine);
                font-size: 0.5rem;
                text-align: center;
                padding: 0.2rem 0.6rem;
                line-height: 1;
                border-radius: 0.2rem;
                background-color:var(--tabActiveColorLine) !important;
                color: #FFFFFF;
                cursor: pointer;
            }
        }
    }
    .__char-facebox {
        max-height: 100px;
        overflow: auto;
        font-size: 0;
        padding: 0 0.3rem;
        box-shadow: 0 -1px 0.2rem rgba(0, 0, 0, 0.1);
        position: absolute;
        left: 0;
        top: -100px;
        background: var(--bodyColor);
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        &::-webkit-scrollbar-track-piece {
            background: rgba(0, 0, 0, 0.1);
            -webkit-border-radius: 6px;
        }
    }
    .__char-emotion {
        width: 48px;
        height: 48px;
        transform: scale(0.55);
        display: inline-block;
        vertical-align: sub;
        background: url(https://files.sciconf.cn/asset/online/images/all.png)
            0 0 no-repeat;
        cursor: pointer;
    }
}
</style>