<!-- 广告弹窗 -->
<template>
    <div class="tips-adv">
        <welcome-adv v-if="isWelcomeAd" :advConfig='advObject.welcome_ad[0]'></welcome-adv>
        <pop-adv v-if="isPopAdv" :advConfig='advObject.pop_ad[0]'></pop-adv>
        <top-adv class="animeout"  :style="isTopAdv ? 'height:6.2rem;' : 'height:0;'" :advConfig="advObject.top_ad"></top-adv>
    </div>
</template>
<script>
import bottemAdv from './bottem-adv.vue'
import popAdv from './pop-adv.vue'
import topAdv from './top-adv.vue'
import welcomeAdv from './welcome-adv.vue'
export default {
    components: {
        welcomeAdv,
        bottemAdv,
        popAdv,
        topAdv
    },
    data() {
        return {
            advObject: {},
            isWelcomeAd: false,
            isPopAdv: false,
            isTopAdv: false
        }
    },
    computed: {
    },
    methods: {
        // 获取弹窗广告
        getadList () {
            this.request.get('getAdList', { live_id: this.$route.params.id }).then((res) => {
                this.advObject = res.data
                if (this.advObject.welcome_ad && this.advObject.welcome_ad.length > 0) {
                    this.isWelcomeAd = true
                }
                if (this.advObject.pop_ad && this.advObject.pop_ad.length > 0) {
                    this.isPopAdv = true
                }
                if (this.advObject.top_ad && this.advObject.top_ad.length > 0) {
                    this.isTopAdv = true
                }
            })
        }
    },
    created () {
        this.getadList()
    }
}
</script>
<style lang="less" scoped>
.animeout {
    transition: all 0.5s ease;
}
.tips-adv {
    overflow: hidden;
}
.v-enter,.v-leave-to{
    opacity: 0;
    transform: translateX(-80px);
}
.v-enter-active,.v-leave-active{
    transition: all 0.5S linear;
}
</style>