<template>
    <div :hidden="!showMessage" class="__char-shadow" @click.self="showMessage=false">
        <div class="__char-content">
            <div class="__char-content-wrap">
                <!-- 文本输入框 -->
                <div class="__char-text" ref="charElem" @input="handleInputEvent" contenteditable="true"></div>
                <!-- 表情与提交按钮 -->
                <div class="__char-edit flex y-center">
                    <i @click="showEmotion = !showEmotion" class="iconfont icon-biaoqing1" :class="[ showEmotion ? 'icon-icon' : 'icon-biaoqing1']"></i>
                    <a @click="handleSendEvent" class="__char-edit-btn" href="javascript:;">{{$t('message.sendBtn')}}</a>
                </div>
            </div>
            <!-- 表情弹框 -->
            <div :hidden="!showEmotion" class="__char-facebox">
                <a class="__char-emotion" @click="handleEmotionClickEvent(item.title)" v-for="(item,index) in emotionslist" :key="index" :style="{backgroundPosition:(-48*index)+'px 0px'}" href="javascript:;"></a>
            </div>
        </div>
    </div>
</template>

<script>
import emotionslist from '@/utils/emotions.js'
export default {
    data() {
        return {
            showMessage:false,
            type:'message',
            // 输入的内容
            charMessage: '',
            showEmotion: false,
            emotionslist,
        }
    },
    methods: {

        // 开启弹框
        showMessageFun(type){
            this.type = type;
            this.showMessage = true;
        },

        // 数据绑定
        handleInputEvent(e) {
            this.showEmotion = false;
            this.charMessage = e.target.innerHTML;
        },

        //表情文字 替换 表情图片
        replace_em(str) {
            var emojiTxtArr = str.match(/[^\[]+(?=\])/g);
            str = str.replace(/<\/div>/g, "");
            str = str.replace(/<br>/g, "");
            str = str.replace(/<div>/g, "");
            if (emojiTxtArr) {
             emojiTxtArr.forEach(function (ele,idx) {
                emotionslist.forEach(function (value) {
                    let title = value.title.replace('[','').replace(']','');
                    if (ele == title) {
                        str = str.replace('[' + ele + ']', '<img style="width:1rem;position:relative;top:0.2rem;" src = "' + value.url + '" alt= "' + ele + '" >');
                    }
                })
            })
        }
            return str;
        },

        // 表情图片 转化 表情文字
        replace_img(str) {
            var emojiImgArr = str.match(/<\s*img\b.*?(?:\>|\/>)/g);
            if (emojiImgArr) {
                emojiImgArr.forEach(function (ele,idx) {
                    var imgAlt = ele.match(/[\u4e00-\u9fa5]/g);
                    imgAlt = imgAlt.join('').split(',');
                    str = str.replace(ele, '[' + imgAlt + ']');
                })
            }
            return str;
        },

        // 点击表情
        handleEmotionClickEvent(title) {
            let replace_title = this.replace_em(title);
            this.charMessage = this.charMessage + replace_title;
            this.$refs.charElem.innerHTML = this.charMessage;
        },
        // 发送评论
        handleSendEvent(){
            // 触发发送事件
            let content = this.replace_img(this.charMessage);
            if (content.replace(/&nbsp;/g, '') != '') {
                this.$emit('sendComment',content)
            }
            //  恢复默认状态
            this.showEmotion = false;
            this.showMessage = false;
            this.$refs.charElem.innerHTML = '';
            this.charMessage = '';
        },
    }
}
</script>
<style lang='less' scoped>
.__char-shadow {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    .__char-content {
        position: absolute;
        // left: 50%;
        // transform: translateX(-50%);
        // width: @container;
        left: 0;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        background: var(--backgroundColor);
        &-wrap {
            padding: 0.5rem;
            .__char-text {
                resize: none;
                width: 100%;
                box-sizing: border-box;
                height: 3rem;
                border-radius: 0.2rem;
                background-color: var(--bodyColor);
                color: var(--fontColor);
                border: none;
                padding: 0.5rem;
                overflow: auto;
                font-size: 0.6rem;
                outline: 0px solid transparent;
                border: 1px solid rgba(255,255,255,0.3) !important;
                &:focus {
                    border: 1px solid #fff !important;
                }
            }
            .__char-edit {
                margin-top: 0.5rem;
                .iconfont {
                    font-size: 1.1rem;
                    cursor: pointer;
                    padding: 0.2rem;
                    color: var(--fontColor1);
                }
                &-btn {
                    border: 2px solid var(--tabActiveColorLine);
                    font-size: 0.6rem;
                    text-align: center;
                    padding: 0.3rem 0.7rem;
                    line-height: 1;
                    border-radius: 0.2rem;
                    background-color: var(--tabActiveColorLine);
                    color: #FFFFFF;
                    cursor: pointer;
                }
            }
        }
        .__char-facebox {
            max-height: 250px;
            overflow: auto;
            font-size: 0;
            padding: 0 0.3rem;
            box-shadow: 0 -1px 0.2rem rgba(0, 0, 0, 0.1);
        }
        .__char-emotion {
            width: 48px;
            height: 48px;
            transform: scale(0.55);
            display: inline-block;
            vertical-align: sub;
            background: url(https://files.sciconf.cn/asset/online/images/all.png)
                0 0 no-repeat;
            cursor: pointer;
        }
    }
}
</style>