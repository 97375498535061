<template>
    <div class="m-answer" v-show="question || survey">
        <!-- 图文类 -->
        <div class="question" v-show="question" >
            <div class="close iconfont icon-guanbi1" @click="question = false"></div>
            <div class="question-content">
                <div v-html="questionData"></div>
            </div>
        </div>
        <!-- 问答类 -->
        <div class="survey" v-show="survey" :style="`height:${surveyHeight} !important;`">
            <div class="survey-title">{{$t('answer.title')}}</div>
            <div class="close iconfont icon-guanbi1" @click="survey = false"></div>
            <div class="survey-content">
                <div class="survey-scroll">
                    <div class="survey-item" v-for="(item, index) in surveyList" :key="index">
                        <div class="survey-item-tit">
                            <span class="blod">{{ index + 1 }}.{{ item.type == '1' ? '【'+$t('answer.radio')+'】' : '【'+$t('answer.checkbox')+'】' }}</span>
                            <span :class="[item.allow_null == '0' ? 'required' : '']">{{ item.question_title }}</span>
                        </div>
                        <div :class="['survey-select', item.selectList.indexOf(subItem.id) != -1 ? 'active iconfont' : '']" v-for="(subItem, subIndex) in item.option_list" :key="subIndex" @click="selectHandle(item, subItem)">
                            <span class="blod">{{letterList[subIndex]}}.</span>
                            <span>{{ subItem.title }}</span>
                            <div class="percentage" :style="`width:${subItem.percentage};`" v-show="subItem.percentage"></div>
                            <div class="percentage-num" v-show="subItem.percentage">{{`${subItem.num} / ${subItem.percentage}`}}</div>
                        </div>
                    </div>
                    <div class="submit-btn" @click="submitHandle">{{$t('answer.submit')}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import mqtt from 'mqtt'
import { Toast } from 'vant'
export default {
    data() {
        return {
            client: null, // mq实例
            question: false,
            survey: false,
            questionData: '',
            surveyList: [],
            letterList: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'],
            surveyHeight: 0, // 移动端高度
            surveyId: '', // 问卷id
            isEnd: true
        }
    },
    computed: {
        ...mapState(['live_user_info', 'liveDetail']),
    },
    watch: {
        live_user_info: {
            handler () {
                this.mqttInit()
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        mqttInit () {
            if (!this.live_user_info.userId) {
                return false
            }
            const connectUrl = 'wss://exmq114.sciconf.cn:8084/mqtt'
            const mqTopic = `/mdk/live/${this.$route.params.id}`
            //todo 当前登陆人 /
            let clientId = `client_l${this.$route.params.id}_u${this.live_user_info.userId}_${Math.random()}`
            if (this.liveDetail.is_open_window_dan == '1') {
                clientId = `client_l${this.$route.params.id}_u${this.live_user_info.userId}`
            }
            const option = {
                reconnectPeriod: this.liveDetail.is_open_window_dan == '1' ? 0 : 1000,
                clean: false, // true: 清除会话, false: 保留会话
                connectTimeout: 4000, // 超时时间
                // 认证信息
                clientId: clientId,//todo 当前登陆人 /
                // 游客
                username: 'client@Mdk@live',
                password: 'pwd4client',
            }
            this.client = mqtt.connect(connectUrl, option)
            this.client.on('connect', () => {
                this.client.subscribe(mqTopic, (err) => {
                    if (!err) {
                        console.log('订阅成功')
                    }
                })
            })
            // 断开连接
            this.client.on('close', () => {
                console.log('Disconnected')
                if (this.liveDetail.is_open_window_dan == '1') {
                    this.$router.push({
                        name: 'tips'
                    })
                }
            })
            // 当断开连接后，经过重连间隔时间重新自动连接到 Broker 时触发
            this.client.on('reconnect', (error) => {
                console.log(error)
            })
            // 当客户端无法成功连接时或发生解析错误时触发，参数 error 为错误信息
            this.client.on('error', (error) => {
                console.log('连接失败:', error)
            })
            this.client.on('message', (topic, message) => {
                const data = JSON.parse(message.toString())
                this.surveyId = data.content.survey_id
                if (data.type == 'question') {
                    this.question = true
                    this.questionData = data.content
                } else {
                    this.isEnd = true
                    const historySurveryId = localStorage.getItem('historySurveryId') || ''
                    if (historySurveryId != data.content.survey_id) {
                        this.survey = true
                        this.surveyList = data.content.question_list
                        this.surveyList.forEach((item, index) => {
                            item.selectList = []
                        })
                    }
                }
            })
        },
        selectHandle (ite, option) {
            if (!this.isEnd) {
                return false
            }
            // 解决视图不更新
            this.$forceUpdate()
            const { question_id, type, max_answer_num } = ite
            const { id } = option
            this.surveyList.forEach((item, index) => {
                if (question_id == item.question_id) {
                    item.option_list.forEach((subItem, subIndex) => {
                        if (id == subItem.id) {
                            if (type == '1') { // 单选
                                if (item.selectList.indexOf(id) == -1) {
                                    item.selectList = []
                                    item.selectList.push(id)
                                }
                            } else if (type == '2') { // 多选
                                if (item.selectList.indexOf(id) == -1) {
                                    if (Number(max_answer_num) > item.selectList.length) {
                                        item.selectList = []
                                        item.selectList.push(id)
                                    } else {
                                        Toast(`${this.$t('answer.maxTip')}${max_answer_num}${this.$t('answer.xiang')}`)
                                    }
                                } else {
                                    item.selectList.splice(item.selectList.indexOf(id), 1)
                                }
                            }
                        }
                    })
                }
            })
            console.log(this.surveyList)
        },
        verifyHandle () {
            for (let i = 0; i < this.surveyList.length; i++) {
                if (this.surveyList[i].allow_null == '0') { // 必选
                    if (Number(this.surveyList[i].min_answer_num) > this.surveyList[i].selectList.length) { // 校验未通过
                        if (this.surveyList[i].type == '1') { // 单选
                            Toast(`${this.$t('answer.di')}【${i + 1}】${this.$t('answer.requiredText')}`)
                        } else if (this.surveyList[i].type == '2') { // 多选
                            Toast(`${this.$t('answer.di')}【${i + 1}】${this.$t('answer.minTip')}${this.surveyList[i].min_answer_num}${this.$t('answer.xiang')}`)
                        }
                        return false
                    }
                }
            }
            return true
        },
        submitHandle () {
            if (!this.isEnd) {
                Toast(this.$t('answer.repeatTip'))
                return false
            }
            const dataList = []
            const isVerify = this.verifyHandle()
            if (isVerify) {
                this.surveyList.forEach((item, index) => {
                    dataList.push({
                        type: Number(item.type),
                        answer: item.type == 1 ? Number(item.selectList.join(',')) : `,${item.selectList.join(',')}`,
                        questionId: Number(item.question_id)
                    })
                })
                this.request.post('submitQuestion', {
                    answer_info: dataList,
                    survery_id: Number(this.surveyId)
                }).then((res) => {
                    this.$forceUpdate()
                    if (res.code == 200) {
                        this.isEnd = false
                        res.data.forEach((item, index) => {
                            item.selectList = []
                        })
                        this.surveyList = res.data
                        localStorage.setItem('historySurveryId', this.surveyId)
                    } else {
                        Toast(res.Message)
                    }
                })
            }
        }
    },
    created () {
    },
    mounted () {
        if (window.screen.width < 500) {
            this.surveyHeight = `${(window.screen.height - (9 / 16 * window.screen.width)) - 20}px`
        } else {
            this.surveyHeight = '22rem'
        }
    }
}
</script>
<style lang="less" scoped>
.m-answer {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    left: 0;
    top: 0;
    z-index: 6;
    .question {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 14rem;
        height: 14rem;
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 20px;
        overflow: hidden;
        color: #333333;
        .question-content {
            width: 100%;
            height: 100%;
            overflow-y: scroll;
            padding: 1rem;
            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }
            &::-webkit-scrollbar-track-piece {
                background: rgba(0, 0, 0, 0.1);
                -webkit-border-radius: 6px;
            }
        }
        .close {
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
            font-size: 0.58rem;
            cursor: pointer;
            color: #333333;
        }
    }
    .survey {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 23rem;
        height: 25rem;
        background: #FFFFFF url('../../assets/images/live-question-bg.png') no-repeat center top;
        box-sizing: border-box;
        border-radius: 20px;
        overflow: hidden;
        color: #333333 !important;
        .close {
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;
            font-size: 0.58rem;
            cursor: pointer;
            color: #333333;
        }
        .survey-title {
            width: 100%;
            height: 2rem;
            line-height: 2rem;
            text-align: center;
            font-size: 0.69rem;
            color: #333333;
            font-weight: bold;
        }
        .survey-content {
            width: 100%;
            height: calc(100% - 2rem);
            padding: 0.5rem;
            box-sizing: border-box;
            font-size: 0.54rem;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }
            &::-webkit-scrollbar-track-piece {
                background: rgba(0, 0, 0, 0.1);
                -webkit-border-radius: 6px;
            }
            .survey-scroll {
                width: 100%;
                .survey-item {
                    margin-bottom: 1rem;
                    .blod {
                        font-weight: bold;
                        margin-right: 0.1rem;
                    }
                    .survey-item-tit {
                        margin-bottom: 0.5rem;
                        &:last-child {
                            margin-right: 0.3rem;
                        }
                        .required {
                            position: relative;
                            &:before {
                                position: absolute;
                                content: '*';
                                color: red;
                                right: -0.4rem;
                                top: -0.13rem;
                                font-size: 0.75rem;
                                font-weight: bold;
                            }
                        }
                    }
                    .survey-select {
                        height: 1.5rem;
                        width: 100%;
                        line-height: 1.5rem;
                        border-radius: 20px;
                        background: #f5f6f7;
                        margin-top: 0.5rem;
                        padding-left: 0.6rem;
                        box-sizing: border-box;
                        cursor: pointer;
                        position: relative;
                        white-space: nowrap;
                        overflow: hidden;
                        padding-right: 1.5rem;
                        text-overflow: ellipsis;
                        .percentage {
                            position: absolute;
                            height: 1.5rem;
                            left: 0;
                            top: 0;
                            z-index: 1;
                            background: #D0E0F7;
                            opacity: 0.3;
                            border-radius: 20px;
                            text-align: right;
                        }
                        .percentage-num {
                            width: 100%;
                            text-align: right;
                            height: 1.5rem;
                            line-height: 1.5rem;
                            position: absolute;
                            right: 0;
                            top: 0;
                            padding-right: 1rem;
                            box-sizing: border-box;
                        }
                        &.active {
                            border: 1px solid #5181f1;
                            position: relative;
                            font-size: 0.54rem;
                            box-sizing: border-box;
                            &:before {
                                position: absolute;
                                content: '\e671';
                                top: 50%;
                                right: 0.5rem;
                                transform: translate(0, -50%);
                                color: #5181f1;
                                font-size: 0.8rem;
                            }
                        }
                    }
                }
                .submit-btn {
                    width: 6rem;
                    height: 1.8rem;
                    line-height: 1.8rem;
                    font-size: 0.62rem;
                    text-align: center;
                    color: #FFFFFF;
                    border-radius: 20px;
                    background-color: #5181f1;
                    background-image: linear-gradient(-87deg, #0bbafb 0%, #4285ec 100%);
                    box-shadow: 0 2px 7px #4285ec;
                    margin: 0 auto;
                    cursor: pointer;
                }
            }
        }
    }
}
// 移动端
@media screen and (max-width: 550px) {
    .survey {
        position: relative;
        left: 0 !important;
        top: 100% !important;
        transform: translate(0, -100%) !important;
        width: 100% !important;
        height: 22rem !important;
        background: var(--backgroundColor);
        box-sizing: border-box;
        border-radius: 20px 20px 0 0 !important;
        overflow: hidden;
    }
}
// pc端
@media screen and (min-width: 550px) {
}
</style>