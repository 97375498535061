<template>
    <div class="__tab-scroll">
        <van-tabs :swipeable="true" v-model="active" :sticky="false" @change="changeDate">
            <van-tab v-for="(item, index) in liveList" :key="index" :title="dateTransition(index)">
                <div class="__tab-info">
                    <ul>
                        <li class="live-item" v-for="(subItem, subIndex1) in item" :key="subIndex1" @click="toLiveRoom(subItem.id, index)">
                            <div class="pic">
                                <img v-if="subItem.live_banner" :src="subItem.live_banner | urlFilter" alt="">
                                <img v-else src="https://files.sciconf.cn/asset/online-meeting/images/live-default-banner.jpg" alt="">
                            </div>
                            <div class="roomMes">
                                <div class="name">{{ subItem[$i18n.locale == 'cn' ? 'hall_name' : 'hall_name_en'] }}</div>
                                <div class="status-content">
                                    <div class="time">{{ starTtime(index, subItem) }}</div>
                                    <div class="state">
                                        <div class="note" v-if="subItem.live_status == 'live'">
                                            <div class="one"></div>
                                            <div class="two"></div>
                                            <div class="three"></div>
                                            <div class="four"></div>
                                        </div>
                                        <div :class="styleStatus(subItem)">{{setStatusText(subItem)}}</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>
<script>
import moment from 'moment'
export default {
    props: {
        liveList: {
            type: Object,
            default: {}
        }
    },
    inheritAttrs: false,
    data() {
        return {
            active: 0,
            imgUrl: 'https://files.sciconf.cn/',
        }
    },
    computed: {
        styleStatus () {
            return function (item) {
                if (item.live_status == 'end') {
                    if ((moment(item.live_start_time).valueOf() < moment(new Date()).valueOf()) || item.live_start_time == '') {
                        if (item.is_open_playback == '1') {
                            return 'status playback'
                        } else {
                            return 'status end'
                        }
                    } else if (moment(item.live_start_time).valueOf() > moment(new Date().valueOf())) {
                        return 'status noStarted'
                    }
                } else if (item.live_status == 'live') {
                    return 'status live'
                }
            }
        },
        setStatusText () {
            return function (item) {
                if (item.live_status == 'end') {
                    if ((moment(item.live_start_time).valueOf() < moment(new Date()).valueOf()) || item.live_start_time == '') {
                        if (item.is_open_playback == '1') {
                            return this.$t('liveRoom.playback')
                        } else {
                            return this.$t('liveRoom.lived')
                        }
                    } else if (moment(item.live_start_time).valueOf() > moment(new Date().valueOf())) {
                        return `${moment(item.live_start_time).format('HH:mm')}${this.$t('liveRoom.beginText')}`
                    }
                    return moment(item.live_start_time).valueOf()
                } else if (item.live_status == 'live') {
                    return this.$t('liveRoom.living')
                }
            }
        },
        starTtime () {
            return function (index, subItem) {
                let format = this.$i18n.locale == 'cn' ? 'YYYY年MM月DD日 HH:mm' : 'YYYY-MM-DD HH:mm'
                if (subItem.live_start_time) {
                    return moment(subItem.live_start_time).format(format)
                } else if (subItem.start_time && `${index}` == moment(subItem.start_time).format('YYYY-MM-DD')) {
                    return moment(subItem.start_time).format(format)
                } else {
                    return ''
                }
            }
        },
        // 日期转换
        dateTransition () {
            return function (index) {
                if (this.$i18n.locale == 'cn') {
                    return index.slice(5)
                } else {
                    return `${this.$t(`month.${index.slice(5, 7)}`)}${index.slice(8)},${index.slice(0, 4)}`
                }
            }
        }
    },
    methods: {
        changeDate (index, title) {
            this.$store.commit('setLiveTabDate', Object.keys(this.liveList)[index])
        },
        toLiveRoom (id, date) {
            setTimeout(() => {
                window.location.href = `${window.location.href.substr(0, window.location.href.indexOf('#'))}#/${id}?c_date=${date}&lang=${this.$i18n.locale}&time=${new Date().getTime()}`
                window.location.reload()
            }, 200);
        }
    },
    mounted () {
        const currentDate = moment(new Date()).format('YYYY-MM-DD')
        let num = 0
        if (this.$route.query.c_date) {
            for (let i in this.liveList) {
                num = num + 1
                if (i == this.$route.query.c_date) {
                    if (num > 0) {
                        this.active = num - 1
                    }
                }
            }
        } else {
            for (let i in this.liveList) {
                num = num + 1
                if (i == currentDate) {
                    if (num > 0) {
                        this.active = num - 1
                    }
                }
            }
        }
    }
}
</script>
<style lang="less" scoped>
@keyframes yuying1 {
    0% {
        height: 0%;
    }

    20% {
        height: 50%;
    }

    50% {
        height: 100%;
    }

    80% {
        height: 50%;
    }

    100% {
        height: 0%;
    }
}
.__tab-scroll {
    /deep/.van-tabs{
        height:100%;
    }
    /deep/.van-tabs__wrap {
        border: none;
        height: 2rem;
        max-width: @container;
        margin: 0 auto;
        position: sticky;
        top: 0;
        z-index: 2;
        box-sizing: border-box;
        background: var(--backgroundColor);
        position: relative;
        &:before {
            position: absolute;
            content: '';
            height: 1px;
            width: 100%;
            left: 0;
            bottom: 1px;
            background: var(--borderColor);
            z-index: 3;
            opacity: 0.3;
        }
        .van-tab {
            padding: 0 0.3rem;
            flex: 0 !important;
            .van-tab__text {
                padding: 0.4rem 0.6rem;
                border-radius: 0.2rem;
                font-size: 0.6rem;
                line-height: 1;
                background-color: var(--liveListBackgroundColor);
                color: var(--liveActiveListFontColor);
                min-width: 2rem;
                width: auto;
                text-align: center;
            }
        }
        .van-tab--active {
            .van-tab__text {
                background-color: var(--liveActiveListBackgroundColor);
                color: var(--liveActiveListFontActiveColor) !important;
                font-weight: bold;
                position: relative;
            }
            .van-tab__text::before {
                content: '';
                width: 0;
                height: 0;
                position: absolute;
                bottom: -0.7rem;
                left: 50%;
                transform: translate(-50%, 0);
                border: 0.5rem solid transparent;
                border-top-color: var(--liveActiveListBackgroundColor);
            }
        }
        .van-tabs__line {
            display: none;
        }
    }
    /deep/.van-tabs__content {
    }
    /deep/.van-tab__pane{
        height:100%;
    }
    /deep/.van-tabs__line {
        background-color: var(--liveActiveListBackgroundColor);
    }
    /deep/.van-tab {
        font-size: 0.6rem;
        line-height: 1.2rem;
    }
    /deep/.van-tab--active {
        color: var(--liveActiveListFontColor);
        font-weight: bold;
    }
    .__tab-info {
        padding: 0.5rem 0.2rem;
        width: 100%;
        box-sizing: border-box;
        // height: 100vh;
        ul {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            li {
                display: flex;
                width: 49%;
                height: 3.22rem;
                margin-left: 0.32rem;
                margin-bottom: 0.36rem;
                cursor: pointer;
                position: relative;
                .pic {
                    width: 5.69rem;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center center;
                    }
                }
                .roomMes {
                    width: calc(100% - 5.69rem);
                    height: 100%;
                    padding-left: 0.5rem;
                    box-sizing: border-box;
                    .name {
                        font-size: 0.6rem;
                        height: 2.1rem;
                        overflow: hidden;
                    }
                    .status-content {
                        .note {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 1rem;
                            > div {
                                width: 0.1rem;
                                margin-left: 0.1rem;
                                background: #1cb871;
                                height: 0.5rem;
                                vertical-align: middle;
                                display: inline-block;
                            }
                            .one {
                                animation: yuying1 1s infinite 0.1s;
                                -webkit-animation: yuying1 1s infinite 0.1s;
                            }
                            .two {
                                animation: yuying1 0.7s infinite 0.2s;
                                -webkit-animation: yuying1 0.7s infinite 0.2s;

                            }
                            .three  {
                            animation: yuying1 0.7s infinite 0.3s;
                                -webkit-animation: yuying1 0.7s infinite 0.3s; 
                            }
                            .four {
                                animation: yuying1 0.7s infinite 0.4s;
                                -webkit-animation: yuying1 0.7s infinite 0.4s;
                            }
                        }
                        .time {
                            font-size: 0.54rem;
                            color: #989898;
                            position: relative;
                        }
                        .state {
                            position: absolute;
                            right: 1rem;
                            bottom: 0;
                            font-size: 0.54rem;
                            padding: 0 0.5rem;
                            border-radius: 1rem;
                            height: 1rem;
                            line-height: 1rem;
                            display: flex;
                            align-items: center;
                            .status {
                                margin-left: 0.3rem;
                                &.end {
                                    color: #769AB5;
                                }
                                &.live {
                                    color: #1cb871;
                                }
                                &.playback {
                                    color: #769AB5;
                                    border: 1px solid #769AB5;
                                    border-radius: 20px;
                                    padding: 0 0.3rem;
                                    box-sizing: border-box;
                                }
                                &.noStarted {
                                    color: #F37242;
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }
}

// 移动端
@media screen and (max-width: 980px) {
    .__tab-scroll {
        max-height: 100%;
        .__tab-info {
            ul {
                li {
                    width: 100% !important;
                    .pic {
                        width: 5rem;
                    }
                    .roomMes {
                        .name {
                            height: 1.8rem;
                        }
                        .status-content {
                            .state {
                                right: 0 !important;
                                bottom: 0.3rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>