<template>
    <div class="container">
        <!-- playsinline：设置播放器在移动设备上不全屏[ Boolean, default: false ] -->
        <!-- customEventName：自定义状态变更时的事件名[ String, default: 'statechanged' ] -->
        <video-player :class="{'fixed-style':isFixed && !isClose}" :playsinline="true" class="video-player-custom" ref="videoPlayerLive" :options="playerOptions" customEventName="customstatechangedeventname" @play="onPlayerPlay" @pause="onPlayerPause" @ended="onPlayerEnded" @ready="playerReadied" @statechanged="playerStateChanged" @playing="onPlayerPlaying" @waiting="onPlayerWaiting" @loadeddata="onPlayerLoadeddata" @timeupdate="onPlayerTimeupdate" @canplay="onPlayerCanplay" @canplaythrough="onPlayerCanplaythrough">
        </video-player>
        <a v-if="isFixed && !isClose" @click="isFixed=false;isClose=true;" class="player-close" href="javascript:;">X</a>
        <div class="answer-content" v-if="isAnswer">
            <div class="answer">
                <div class="answer-title">请输入正确答案继续观看 {{num1}} + {{num2}} = ?</div>
                <input type="number" v-model="answerValue">
                <van-button type="info" @click="submitAnswer">确定</van-button>
            </div>
        </div>
    </div>
</template>
<script>
/** 直播播放器插件 */
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'
// 因vue-video-player 中 使用的事低版本的video.js 故不能修改node_modules/vue-video-play,所以需要把当前文件copy出来
import VueVideoPlayer from 'vue-video-player'
import { mapState } from 'vuex'
window.videojs = VueVideoPlayer.videojs //不设置这个，引用语言会报错
let { videoPlayer } = VueVideoPlayer;
require('video.js/dist/lang/zh-CN.js') //必须引用，后面设置语言才会生效

import { throttle } from '@/utils/tools';
import { max } from 'moment'
// 计费timer
let timer = null;
// 网络错误定时器、
let timerNetwork = null;
export default {
    components: {
        videoPlayer,
    },
    data() {
        return {
            answerValue: '',
            num1: 0,
            num2: 0,
            interval: 0,
            answerRange: [],
            timer2: null, // 答题倒计时
            // 是否需要答题才能观看
            isAnswer: false,
            // 直播是否卡顿
            isLiveLag:false,
            // 断流次数
            retriesNumber:0,
            // 是否显示小窗
            isFixed: false,
            // 是否关闭
            isClose: false,
            // 直播参数
            playerOptions: {
                playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
                autoplay: true, // 如果为true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 是否视频一结束就重新开始。
                preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: this.$i18n.locale == 'cn' ? 'zh-CN' : 'zh-EN',
                aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                // 兼容顺序，默认值是['html5']，其他已注册的技术将按其注册的顺序在该技术之后添加。
                techOrder: ['html5'],
                notSupportedMessage:  this.$t('player.videoErrText'), // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                    timeDivider: true, // 当前时间和持续时间的分隔符
                    durationDisplay: true, // 显示持续时间
                    remainingTimeDisplay: false, // 是否显示剩余时间功能
                    fullscreenToggle: true // 是否显示全屏按钮
                },
                // 等同于原生<video>标签中的一组<source>子标签，可实现优雅降级；type 属性规定媒体资源的 MIME 类型
                sources: [{
                    type: "application/x-mpegURL",
                    src: '',//'https://tlivepull.sciconf.cn/live/t11224.m3u8?txSecret=d23642c3b7c69c3edeffca23b4f8ca30&txTime=62F7383D&auth_token=D0_j2q4NHEMRQFNY3v96TIg_d_d'
                }],
                // 视频封面
                poster: '',
            }
        }
    },
    computed: {
        ...mapState(['sdk_params', 'stopConfigAdv', 'liveDetail']),
        //插件节点 用于添加自定义按钮事件
        player() {
            return this.$refs.videoPlayerLive.player
        },
    },
    watch: {
        // 拉流地址
        sdk_params: {
            handler(newVal) {
                this.$set(this.playerOptions.sources[0], 'src', newVal.pull_url);
            },
            immediate: true,
        }
    },
    methods: {
        submitAnswer () {
            if ((Number(this.answerValue) - Number(this.num1)) == this.num2) {
                this.player.play()
                this.isAnswer = false
                this.answerValue = ''
            }
        },
        // 获取随机数
        getRandomNum (min, max) {
            if ((max - min) <= 0) {
                return Number(max)
            } else {
                return Math.floor(Math.random() * (max - min))
            }
        },
        // 是否需要答题才能继续观看
        getNeedAnswer () {
            this.request.get('getNeedAnswer', { live_id: this.$route.params.id }).then((res) => {
                if (res.data == 1) { // 需要答题
                    clearInterval(this.timer2)
                    let min = Number(this.answerRange[0])
                    let max = Number(this.answerRange[1])
                    this.interval = (this.getRandomNum(min, max) * 60) * 1000
                    console.log(this.interval)
                    this.timer2 = setInterval(() => { // 答题
                        this.num1 = this.getRandomNum(0, 10)
                        this.num2 = this.getRandomNum(0, 10)
                        this.isAnswer = true
                        this.player.pause()
                    }, this.interval);
                }
            })
        },
        // 播放回调
        onPlayerPlay(player) {
            //console.log('播放回调')
            //console.log(player)
            if (this.liveDetail.is_open_answer == 1) {
                this.answerRange = this.liveDetail.answer_range.split('-')
                this.getNeedAnswer()
            }
        },
        // 暂停回调
        onPlayerPause(player) {
            //console.log(player)
        },
        // 视频播放结束回调
        onPlayerEnded(player) {
            //console.log(player)
        },
        // DOM元素上的readyState更改导致播放停止
        onPlayerWaiting(player) {
            //console.log(player)
        },
        // 已开始播放回调
        onPlayerPlaying(player) {
            //console.log('已开始播放回调')

            //console.log(player)
        },
        // 当播放器在当前播放位置下载数据时触发
        onPlayerLoadeddata($event) {
            //console.log($event)
        },
        // 当前播放位置发生变化时触发。
        onPlayerTimeupdate($event) {
            //console.log($event)
            let delayTime = $event.buffered().end(0) - $event.currentTime();
            if (delayTime <= 0.1) {
                // 网络卡顿或者断流
                this.isLiveLag = true;
            }else{
                this.isLiveLag = false;
                this.retriesNumber = 0;
            }
        },
        //媒体的readyState为HAVE_FUTURE_DATA或更高
        onPlayerCanplay($event) {
            //console.log($event)
            console.log('onPlayerCanplay')
        },
        //媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
        onPlayerCanplaythrough($event) {
            // console.log('视频播放结束回调')
            //console.log($event)
        },
        //播放状态改变回调
        playerStateChanged($event) {
        },
        //将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
        playerReadied($event) {

            !timer && this.updateStudyLog();
            // 出错了
            $event.on('error', (err) => {
                // 有错误停止计费
                clearTimeout(timer);
                // 回收废弃定时器
                //clearTimeout(timerNetwork);
                let errData = $event.error();
                console.log(errData)
                let errMsg = '';
                switch (errData.code) {
                    case 0:
                        errMsg = this.$t('player.code0');
                        break;
                    case 1:
                        errMsg = this.$t('player.code1');
                        break;
                    case 2:
                        errMsg = this.$t('player.code2');
                        break;
                    case 3:
                        errMsg = this.$t('player.code3');
                        break;
                    case 4:
                        errMsg = this.$t('player.code4');
                        break;
                    case 5:
                        errMsg = this.$t('player.code5');
                        break;
                }
                $event.errorDisplay.contentEl().innerText = errMsg
                timerNetwork = setTimeout(() => {
                    // 出错后 重新复制src 并刷新
                    $event.src(this.sdk_params.pull_url);
                    $event.load();
                },2000)
            })
            // 监听是否断开直播 流返回404
            $event.tech_.on('retryplaylist', (res) => {
                console.log(res);
                this.retriesNumber++;
                if (this.retriesNumber >= 5){
                    // 当直播出现卡顿，并且流错误5次，则判定直播结束
                    if(this.isLiveLag){
                        // 无直播停止计费
                        clearTimeout(timer);
                        this.$store.commit('setLiveStatus', false)
                    }
                    
                }
                // do something
            });
            // console.log($event)
        },

        // 记录直播时长
        updateStudyLog() {
            // console.log('正在累计');
            this.request.post('updateStudyLog', { obj_id: (this.sdk_params || {}).params4, type: 1 }, { hideLoading: true })
            // 每隔一分钟请求一次
            timer = setTimeout(() => {
                this.updateStudyLog();
            }, 60 * 1000)
        },

        // 监听滚动条
        listenerScroll() {
            function getScrollTop() {
                var scrollPos;
                if (window.pageYOffset) {
                    scrollPos = window.pageYOffset;                }
                else if (document.compatMode && document.compatMode != 'BackCompat') { scrollPos = document.documentElement.scrollTop; }
                else if (document.body) { scrollPos = document.body.scrollTop; }
                return scrollPos;
            }

            let videoPlayerEl = this.$refs.videoPlayerLive;
            if (!videoPlayerEl) return false;
            let videoPlayerHeight = videoPlayerEl.$el.offsetHeight;
            let offsetNum = 100;
            var that = this;
            window.onscroll = throttle(function (e) {
                let scrollTop = getScrollTop();
                if (scrollTop > videoPlayerHeight + offsetNum) {
                    that.isFixed = true;
                    videoPlayerEl.$el.parentNode.style.height = videoPlayerHeight + 'px';
                } else {
                    that.isFixed = false;
                    // 重置关闭开关
                    that.isClose = false;
                    videoPlayerEl.$el.parentNode.style.height = 'auto';
                }
            }, 200)
        },
        // 监听窗口变化
        listenerSize() {
            window.onresize = () => {
                let videoPlayerEl = this.$refs.videoPlayer;
                if (!videoPlayerEl) return false;
                // 判断是否全屏
                //if(!videoPlayerEl.player.isFullscreen()){
                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    let videoPlayerHeight = videoPlayerEl.$el.offsetHeight;
                    videoPlayerEl.$el.parentNode.style.height = videoPlayerHeight + 'px';
                }, 100)
                //}
            }
        },
        // 设置暂停广告
        setStopAdv() {
            if (this.stopConfigAdv) {
                if (this.stopConfigAdv.ad_resource == '1') {
                    this.playerOptions.poster = this.stopConfigAdv.src
                }
            }
        }
    },
    created() {
        this.setStopAdv()
    },
    mounted() {
        this.listenerSize();
        this.listenerScroll();
    },
    beforeDestroy () {
        clearInterval(this.timer2)
    }
}
</script>
<style lang="less" scoped>
.answer-content {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 6;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    .answer {
        width: 14rem;
        background: #ffffff;
        padding: 0.5rem;
        box-sizing: border-box;
        .answer-title {
            font-size: 0.56rem;
            color: #000000;
        }
        input {
            font-size: 0.56rem;
            color: #000000;
            width: 100%;
            height: 1rem;
            margin-top: 0.7rem;
        }
        .van-button {
            height: 1.3rem;
            font-size: 0.56rem;
            margin-top: 0.5rem;
            float: right;
        }
    }
}
/deep/ .vjs-big-play-button {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
}
@media screen and (min-width: 550px) {
    .fixed-style {
        position: fixed;
        right: 0px;
        bottom: 0px;
        width: 400px;
        z-index: 99;
    }
    .player-close {
        position: fixed;
        bottom: 225px;
        right: 0;
        z-index: 99;
        padding: 4px 10px;
        line-height: 1;
        background-color: #c74747;
        color: #fff;
    }
}
</style>