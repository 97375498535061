<template>
    <div>
        <van-tabs class="char-message" :swipeable="true" @change="(e)=>{active=e}" v-model="active">
            <van-tab v-for="(item,index) in charTab" :key="index" :title="item.name">
                <!-- 聊天 -->
                <div class="char-info" v-if="item.id == 1">
                    <!-- 聊天列表 -->
                    <van-pull-refresh v-model="showLoading" @refresh="commentMoreEvent">
                        <div class="t-c" v-if="loadingMore == 0"><a @click="commentMoreEvent" class="more-btn" href="javascript:;">{{$t('message.moreMessage')}}</a></div>
                        <char-message-item v-for="(it,idx) in commentList" :key="idx" :item="it"></char-message-item>
                    </van-pull-refresh>
                    <!-- 聊天互动按钮 -->
                    <div class="char-open">
                        <div @click="$refs.charTextarea.showMessageFun('message')" class="char-open-hidden-input">
                            <i class="iconfont icon-bianji"></i>
                            {{$t('message.joinCharBtn')}}
                        </div>
                    </div>
                </div>
                <!-- 提问 -->
                <div class="char-info" v-if="item.id == 2">
                    <!-- 提问列表 -->
                    <van-pull-refresh v-model="showLoading"  @refresh="questionMoreEvent">
                        <div class="t-c" v-if="!loadingMore2 == 0 && questionList.length > 0 && questionList % 20 == 0"><a @click="questionMoreEvent" class="more-btn" href="javascript:;">{{$t('message.moreQuestion')}}</a></div>
                        <char-message-item v-for="(item,index) in questionList" :key="index" :item="item"></char-message-item>
                    </van-pull-refresh>
                    <!-- 提问互动按钮 -->
                    <div class="char-open">
                        <div @click="$refs.charTextarea.showMessageFun('question')" class="char-open-hidden-input">
                            <i class="iconfont icon-bianji"></i>
                            {{$t('message.joinQuestionBtn')}}
                        </div>
                    </div>
                </div>
            </van-tab>
        </van-tabs>
        <!-- 聊天弹框 -->
        <char-message-textarea @sendComment="handleSendComment" ref="charTextarea"></char-message-textarea>
        <!-- 声网 RTM -->
        <agora-rtm ref="jsAgoraRtmComponent" v-if="sdk_params.live_type == 2 && sdk_params.rtm_appid && roomId" :agoraAppid="sdk_params.rtm_appid" :liveNo="roomId" @myMsg="handleMyMsg" @checkMsg="channelCheckMsg" @channelMessage="channelMessage" @memberCount="onlineAgoraPeople" @agoraLoad="handleAgoraLoad"></agora-rtm>
    </div>
</template>
<script>
import parseEmoji from '@/utils/parseEmoji';
import charMessageItem from '@/components/live-comps/char-message/char-message-item';
import charMessageTextarea from '@/components/live-comps/char-message/char-message-textarea';
import agoraRtm from '@/components/live-comps/char-message/agora-rtm';
import eventBus from '@/utils/eventBus'
import { mapState } from 'vuex'
export default {
    components: {
        charMessageItem,
        charMessageTextarea,
        agoraRtm
    },
    props:{
        title:{
            type:String,
            default:'',
        }
    },
    data() {
        return {
            charTab: [],
            active: 0,
            // 聊天
            loadingMore: 0,
            showLoading:false,
            page: 0,
            commentList: [],
            // 提问
            loadingMore2: 0,
            showLoading2:false,
            page2: 0,
            questionList: [],
            isLogin: '0'
        }
    },
    computed: {
        ...mapState(['sdk_params', 'live_config', 'live_user_info']),
        roomId () {
            return this.$store.state.liveDetail.live_no
        }
    },
    watch:{
        // 聊天与提问的切换监听
        active: {
            handler(){
                this.$emit('scrollBottom')
            },  
            immediate:true,
        },
        loadingMore(newVal){
            this.showLoading = newVal == 2 ? false : !!newVal
        },
        loadingMore2(newVal){
            this.showLoading2 = newVal == 2 ? false : !!newVal
        }
    },
    methods: {
        pageInit () {
            let charTab = [];
             // 是否显示留言
            if (this.live_config.is_hide_message == 1) {
                charTab.push({ id: 1, name: this.title || this.$t('message.charText') })
            }
            // 是否显示提问
            if (this.live_config.is_hide_question == 1) {
                charTab.push( { id: 2, name: this.$t('message.questionText') })
            }
            this.charTab = charTab;
        },
        // 获取大会议程评论
        getScheduleMessageInfo () {
            eventBus.$on('getScheduleMessageInfo', (item) => {
                this.commentList.push(item)
                const agoraRtmComponent = this.$refs.jsAgoraRtmComponent;
                if(!agoraRtmComponent)return;
                agoraRtmComponent.sendMessageHandle(item.content, 1)
            })
        },
        /**
         * 聊天历史加载更多
         */
        commentMoreEvent() {
            this.getHistoryConten('message', ++this.page)
        },
        /**
         * 提问历史加载更多
         */
        questionMoreEvent() {
            this.getHistoryConten('question', ++this.page2)
        },
        // 获取列表信息
        getHistoryConten(type, index = 0) {
            const pageSize = 20;
            this[type == 'message' ? 'loadingMore' : 'loadingMore2'] = 1;
            this.request.get('commentHistory',
                {
                    roomId: this.roomId,
                    type,
                    start_limit: index * pageSize,
                    end_limit: index * pageSize + pageSize,
                    type_id: this.__live_id
                },
                {
                    hideLoading: true,
                    hideModal: true,
                    compelSuccess: true,
                    isSuccessData: true
                }
            ).then(({ data }) => {
                const dataFormat = (arr, type) => {
                    let newArr = [];
                    // 用户id
                    const { Uid } = (this.$store.getters.userInfo || {});
                    for (let i = 0; arr[i]; i++) {
                        let { avatar, create_time, user_name, real_name, content, user_id } = arr[i];
                        if (type && user_id && user_id != Uid) continue;
                        let emojiTxtArr = content.match(/[^\[]+(?=\])/g)
                        while (this.judge.isArray(emojiTxtArr) && emojiTxtArr.length) {
                            const k = '[' + emojiTxtArr.shift() + ']';
                            content = content.replace(k, parseEmoji(k))
                        }
                        if (this.judge.isString(user_name) && user_name.indexOf('观众') == -1) {
                            if (/^[\u4E00-\u9FA5]+/.test(user_name)) {
                                user_name = user_name[0] + '**';
                            } else if (/[A-Za-z]+/.test(user_name)) {
                                user_name = user_name.substr(0, 1) + ' ***';
                            } else if (/^[1-9]+$/.test(user_name)) {
                                user_name = user_name[0] + '****' + user_name[user_name.length - 1];
                            }
                        }
                        if (this.judge.isString(real_name) && real_name.indexOf('观众') == -1) {
                            if (/^[\u4E00-\u9FA5]+/.test(real_name)) {
                                real_name = real_name[0] + '**';
                            } else if (/[A-Za-z]+/.test(real_name)) {
                                real_name = real_name.substr(0, 1) + ' ***';
                            } else if (/^[1-9]+$/.test(real_name)) {
                                real_name = real_name[0] + '****' + real_name[real_name.length - 1];
                            }
                        }
                        newArr.push({
                            avatar: avatar || 'https://files.sciconf.cn/asset/mobile/speaker/images/person-default.png',
                            create_time: create_time,
                            user_name: user_name,
                            real_name: real_name,
                            content: content
                        })
                    }
                    return newArr;
                }
                // 聊天消息列表
                if (data.length) {
                    // ...dataFormat(data, type !== 'message')
                    this[(type === 'message') ? 'commentList' : 'questionList'] = [...dataFormat(data, false), ...this[(type === 'message') ? 'commentList' : 'questionList']];
                    this[type == 'message' ? 'loadingMore' : 'loadingMore2'] = 0;
                } else {
                    this[type == 'message' ? 'loadingMore' : 'loadingMore2'] = 2;
                }
            }).catch(err => {
                console.log(err)
            })
        },

        /**
         * 我自己发送的消息
         */
        handleMyMsg(res, type) {
            // 自己发送消息
            if (res.scroll) {
                this.handleSendMsg(res, type)
            } else { // 收到消息
                let emojiTxtArr = res.msg.match(/[^\[]+(?=\])/g)
                while (this.judge.isArray(emojiTxtArr) && emojiTxtArr.length) {
                    const k = '[' + emojiTxtArr.shift() + ']';
                    res.msg = res.msg.replace(k, parseEmoji(k))
                }
                let commentItem = {
                    avatar: res.pic || 'https://files.sciconf.cn/asset/mobile/speaker/images/person-default.png',
                    create_time: this.dayjs(res.time).format('YYYY-MM-DD HH:mm:ss'),
                    user_name: res.nick,
                    real_name: res.nick,
                    content: res.msg
                }
                if (type || this.active === 0) {
                    // 实时评论列表
                    this.commentList.push(commentItem)
                } else {
                    this.questionList.push(commentItem)
                }
            }
            this.$emit('scrollBottom')
            this.setContentBottom();
        },
        // 内容置底
        setContentBottom () {
            this.$nextTick(() => {
                const content = document.getElementsByClassName('van-tabs__content')[0]
                content.scrollTop = content.scrollHeight
            })
        },
        /**
         * 发送留言/提问
         */
        handleSendMsg(res,type) {
            let requestArr = [this.request.post('addComment', { type: 4, type_id: 0, live_id: this.__live_id },{hideModal:true,hideLoading:true})];
            // is_message不存在 或者 值为1 时开启留言本地收集
            const { is_message } = this.live_config || 1;
            if (is_message == 1) {
                const user_info = { ...(this.live_user_info || {}) };
                requestArr.push(this.request.post('onlineMessage', { live_id: this.__live_id, type: this.charTab[this.active].id, content: res.msg, to_user_id: 0, user_id: user_info.userId, user_name: user_info.userName },{hideModal:true,hideLoading:true}));
            }
            Promise.all(requestArr).then(([res1, res2]) => {
                if (res1.code == '2000' || res2.code == '2000') {
                    this.$toast('您的留言信息中包含严禁词')
                } else {
                    let emojiTxtArr = res.msg.match(/[^\[]+(?=\])/g)
                    while (this.judge.isArray(emojiTxtArr) && emojiTxtArr.length) {
                        const k = '[' + emojiTxtArr.shift() + ']';
                         res.msg =  res.msg.replace(k, parseEmoji(k))
                    }
                    let commentItem = {
                        avatar: res.pic || 'https://files.sciconf.cn/asset/mobile/speaker/images/person-default.png',
                        create_time: this.dayjs(res.time).format('YYYY-MM-DD HH:mm:ss'),
                        user_name: res.nick,
                        real_name: res.nick,
                        content: res.msg
                    }
                    if (type || this.active === 0) {
                        // 实时评论列表
                        this.commentList.push(commentItem)
                        const agoraRtmComponent = this.$refs.jsAgoraRtmComponent;
                        if(!agoraRtmComponent)return;
                        agoraRtmComponent.sendMessageHandle(res.msg,this.charTab[this.active].id)
                    } else {
                        this.questionList.push(commentItem)
                    }
                    this.$emit('scrollBottom')
                    this.setContentBottom();
                }
             }).catch(err => { })
        },
        /**
         * 待审核消息    --群聊
         */
        channelCheckMsg(res) {
            this.handleMyMsg(res)
        },
        /**
         * 声网聊天消息  -- 群聊
         */
        channelMessage(res) {
            const dataFormat = (obj) => {
                let { nick: user_name, msg: content, pic } = obj;
                let emojiTxtArr = content.match(/[^\[]+(?=\])/g)
                while (this.judge.isArray(emojiTxtArr) && emojiTxtArr.length) {
                    const k = '[' + emojiTxtArr.shift() + ']';
                    content = content.replace(k, parseEmoji(k))
                }
                if (this.judge.isString(user_name) && user_name.indexOf('观众') == -1) {
                    if (/^[\u4E00-\u9FA5]+/.test(user_name)) {
                        user_name = user_name[0] + '**';
                    } else if (/[A-Za-z]+/.test(user_name)) {
                        user_name = user_name.split(' ')[0] + ' ***';
                    } else if (/^[1-9]+$/.test(user_name)) {
                        user_name = user_name[0] + '****' + user_name[user_name.length - 1];
                    }
                }
                return {
                    pic,
                    nick: user_name,
                    time: new Date().getTime(),
                    msg: content
                }
            }
            try {
                const obj = JSON.parse(res.text);
                if (obj.EVENT === 'SPEAK') {
                    // 聊天
                    this.handleMyMsg(dataFormat({ pic: obj.user.pic, nick: obj.user.nick, msg: obj.values[0] }), 1)
                } else if (obj.EVENT === 'S_QUESTION') {
                    // 提问 不用管
                }
            } catch (e) {
                console.log('数据格式异常')
            }
        },
        /**
         * 声网统计实时在线人数
         */
        onlineAgoraPeople(res) {
            console.log(res);
        },
        /**
         * 声网组件初始化完成
         */
        handleAgoraLoad (res) {
            this.isLogin = localStorage.getItem('isLogin')
            // 登录
            // if (this.isLogin == '1') {
                this.getHistoryConten('message', this.page);
                this.getHistoryConten('question', this.page2);
            // }
        },

        /**
         * 发布评论
         */
        handleSendComment(content){
            // 发送评论
            const agoraRtmComponent = this.$refs.jsAgoraRtmComponent;
            if(!agoraRtmComponent)return;
            agoraRtmComponent.handleAgoraChannelSendMessage(content,this.charTab[this.active].id)
        }
    },
    async mounted () {
        this.getScheduleMessageInfo()
        this.isLogin = localStorage.getItem('isLogin')
        this.__live_id = this.$route.params.id;

        this.pageInit();
    }
}
</script>
<style lang="less" scoped>
.char-info {
    padding: 0.5rem 0.5rem 3.5rem 0.5rem;
    position: relative;
    box-sizing: border-box;
}
// 聊天开关
.char-open {
    position: fixed;
    left: 50%;
    bottom: 0;
    width: @container;
    max-width: 100%;
    transform: translateX(-50%);
    padding: 0.5rem;
    box-sizing: border-box;
    box-shadow: 0 0 15px 1px var(--backgroundColor);
    background-color: var(--backgroundColor);
    &-hidden-input {
        color: var(--fontColor1);
        background-color: var(--bodyColor);
        text-align: center;
        font-size: 0.55rem;
        height: 2rem;
        line-height: 2rem;
        border-radius: 2rem;
        cursor: pointer;
    }
}
.more-btn {
    padding: 0.4rem 0.7rem;
    line-height: 1;
    display: inline-block;
    border-radius: 2rem;
    border: 1px solid rgba(255,255,255,0.3);
    color: var(--fontColor1);
    font-size: 0.55rem;
}
.char-message {
    /deep/.van-tabs__content{
        overflow: auto;
    }
    height: calc(100vh - 2rem)!important;
    /deep/.van-tabs__wrap {
        padding-left: 0.5rem;
        box-sizing: border-box;
        z-index: 1!important;
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
        border: none;
        position: sticky;
        top:0;
        &:before {
            position: absolute;
            content: '';
            height: 1px;
            width: 100%;
            left: 0;
            bottom: 1px;
            background: var(--borderColor);
            z-index: 3;
            opacity: 0.3;
        }
        .van-tabs__nav {
            margin: 0 auto;
            background: var(--backgroundColor) !important;
            .van-tab {
                padding: 0 0.3rem;
                flex: inherit;
                .van-tab__text {
                    padding: 0.4rem 0.6rem;
                    border-radius: 0.2rem;
                    font-size: 0.6rem;
                    line-height: 1;
                    // color: var(--tabColor);
                }
            }
            .van-tab--active {
                .van-tab__text {
                    background-color: var(--tabActiveColorLine);
                    color: #fff !important;
                    font-weight: bold;
                }
            }
            .van-tabs__line {
                display: none;
            }
        }
    }
}
</style>