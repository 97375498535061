<!-- 卫星会 -->
<template>
    <div class="m-satellite">
       <div v-for="(item, index) in satelliteList" :key="index">
            <div class="wxMeetLogo"></div>
            <div class="wxMeetTit">
                <div class="tit">
                    {{item.program[$i18n.locale == 'cn' ? 'abstract_title' : 'abstract_title_en']}}
                </div>
                <div class="videoPlayBtn" @click="toLiveRoom">
                    <div class="note">
                        <div class="one"></div>
                        <div class="two"></div>
                        <div class="three"></div>
                        <div class="four"></div>
                    </div>
                    <div class="text">{{isEndLive ? '观看直播' : $t('liveRoom.playback')}}</div>
                </div>
            </div>
            <ul>
                <li>
                    <div class="item-c">
                        <p class="title-label">{{$t('program.time')}}:</p>
                        <p class="text">{{item.program.meeting_date}} {{item.program.starttime_slot}}-{{item.program.endtime_slot}}</p>
                    </div>
                    <div class="item-c">
                        <p class="title-label">{{$t('program.hall')}}:</p>
                        <p class="text">{{$i18n.locale == 'cn' ? item.program.meeting_hall : item.program.meeting_hall_en}}</p>
                    </div>
                </li>
                <li>
                    <!-- 主席 -->
                    <div class="item-c" v-for="(item1, index1) in item.session_duty_list['主席']" :key="index1">
                        <p class="title-label">{{$t('program.chairman')}}:</p>
                        <p class="text">{{item1.real_name}} <span>{{`(${item1.org})`}}</span></p>
                    </div>
                    <div class="item-c" v-for="(item2, index2) in item.session_duty_list['主持']" :key="index2">
                        <p class="title-label">{{$t('program.host')}}:</p>
                        <p class="text">{{item2.real_name}} <span>({{item2.org}})</span></p>
                    </div>
                </li>
                <li>
                    <div class="item-c" v-for="(item3,index3) in item.duty_list" :key="index3">
                        <div class="item-t">
                            <p class="title-label">{{item3.duty_list[0] ? item3.duty_list[0][$i18n.locale == 'cn' ? 'typename_cn' : 'typename_en'] : ''}}:</p>
                            <p class="text">{{item3[$i18n.locale == 'cn' ? 'abstract_title' : 'abstract_title_en']}}</p>
                        </div>
                        <div class="sub2" v-for="(subItem, subIndex) in item3.duty_list" :key="subIndex">
                            <template v-if="$i18n.locale == 'cn'">
                                {{`${subItem.real_name} (${subItem.org_cnname})`}}
                            </template>
                             <template v-else>
                                 {{`${subItem.given_name} ${subItem.family_name} (${subItem.org_enname})`}}
                             </template>
                        </div>
                    </div>
                </li>
            </ul>
       </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    props: {
        satelliteList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
        }
    },
    computed: {
        isEndLive () {
            console.log(this.satelliteList[0].program.meeting_hall_live.end_time)
            return new Date(this.satelliteList[0].program.meeting_hall_live.end_time).getTime() > new Date().getTime()
        }
    },
    methods: {
        toLiveRoom () {
            const meeting_hall_live = this.satelliteList[0].program.meeting_hall_live
            let { live_source, live_id, live_url } = this.satelliteList[0].program
            let endTime = new Date(meeting_hall_live.end_time).getTime()
            let currentTime = new Date().getTime()
            if (live_source == '0') {
                if (endTime > currentTime) { // 观看直播
                } else { // 直播回放
                    window.location.href = `${window.location.href.substr(0, window.location.href.indexOf('#'))}#/${meeting_hall_live.id}`
                }
            } else if (live_source == '1') {
                window.location.href = `${window.location.href.substr(0, window.location.href.indexOf('#'))}#/${live_id}`
            } else if (live_source == '2') {
                window.location.href = live_url
            }
            window.location.reload()
        }
    }
}
</script>
<style lang="less" scoped>
@keyframes yuying1 {
    0% {
        height: 0%;
    }

    20% {
        height: 50%;
    }

    50% {
        height: 100%;
    }

    80% {
        height: 50%;
    }

    100% {
        height: 0%;
    }
}
.m-satellite {
    width: 100%;
    height: 100%;
    .wxMeetLogo {
        width: 100%;
        height: 1rem;
        margin-bottom: 0.3rem;

    }
    .wxMeetTit {
        display: flex;
        border-bottom: 1px solid var(--borderColor);
        padding-bottom: 0.4rem;
        box-sizing: border-box;
        margin-bottom: 0.5rem;
        align-items: center;
        .tit {
            flex: 1;
            font-size: 0.62rem;
            font-weight: bold;
            color: var(--fontColor);
        }
        .videoPlayBtn {
            background: url('../../assets/images/btn-line1.png');
            padding: 0.38rem 0.58rem;
            display: flex;
            align-items: center;
            justify-items: center;
            border-radius: 1rem;
            font-size: 0.54rem;
            color: #ffffff;
            cursor: pointer;
            .note {
                height: 0.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 0.3rem;
                > div {
                    width: 0.1rem;
                    margin-left: 0.1rem;
                    background: #ffffff;
                    height: 0.5rem;
                    vertical-align: middle;
                    display: inline-block;
                }
                .one {
                    animation: yuying1 1s infinite 0.1s;
                    -webkit-animation: yuying1 1s infinite 0.1s;
                }
                .two {
                    animation: yuying1 0.7s infinite 0.2s;
                    -webkit-animation: yuying1 0.7s infinite 0.2s;

                }
                .three  {
                    animation: yuying1 0.7s infinite 0.3s;
                    -webkit-animation: yuying1 0.7s infinite 0.3s; 
                }
                .four {
                    animation: yuying1 0.7s infinite 0.4s;
                    -webkit-animation: yuying1 0.7s infinite 0.4s;
                }
            }
        }
    }
    ul {
        li {
            font-size: 0.54rem;
            border-bottom: 1px solid var(--borderColor);
            margin-bottom: 0.5rem;
            .item-c {
                width: 100%;
                overflow: hidden;
                margin-bottom: 0.5rem;
                padding-left: 3.6rem;
                box-sizing: border-box;
                position: relative;
                p {
                    display: inline-block;
                }
                .item-t {
                    width: 100%;
                    display: block;
                    overflow: hidden;
                }
                .sub2 {
                    color: var(--fontColor1);
                    margin-top: 0.5rem;
                    box-sizing: border-box;
                }
                .title-label {
                    width: 2.6rem;
                    text-align: left;
                    float: left;
                    color: var(--fontColor1);
                    position: absolute;
                    left: 0;
                    word-break: break-all;
                }
                .text {
                    float: left;
                    color: var(--fontColor);
                    font-weight: bold;
                    span {
                        color: var(--fontColor1);
                    }
                }
            }
        }
    }
}
// 移动端
@media screen and (max-width: 550px) {
}
// pc端
@media screen and (min-width: 550px) {
}
</style>