<!-- 广告弹窗 -->
<template>
    <div class="welcome-adv">
        <div class="content">
            <a :href="advConfig.url">
                <img :src="advConfig.img | urlFilter" alt="">
            </a>
            <span class="close" @click="$parent.isWelcomeAd = false">
                {{count}}s{{$t('skip')}}<!--跳过-->
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'welcomeAdv',
    props: {
        advConfig: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            imgUrl: 'https://files.sciconf.cn/',
            count: 5,
            timer: null
        }
    },
    methods: {
        countdown () {
            this.timer = setInterval(() => {
                this.count--
                if (this.count === 0) {
                    clearInterval(this.timer)
                    this.timer = null
                    this.$parent.isWelcomeAd = false
                }
            }, 1000)
        }
    },
    created () {
        this.countdown()
    },
    beforeDestroy () {
        clearInterval(this.timer)
        this.timer = null
    }
}
</script>
<style lang="less" scoped>
// 移动端
.welcome-adv {
    position: fixed;
    width: 100%;
    z-index: 66;
    height: 100vh;
    background: rgba(0, 0, 0, 0.1);
    .content {
        width: 100%;
        height: 100%;
        a {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        span {
            color: #666666;
            background-color: rgba(255, 255, 255, 0.7);
            position: fixed;
            z-index: 6;
            right: 0.4rem;
            top: 0.6rem;
            cursor: pointer;
            padding: 0.1rem 0.6rem;
            border-radius: 1rem;
            font-size: 0.46rem;
        }
    }
}
// pc端
@media screen and (min-width: 550px) {
.welcome-adv {
    position: fixed;
    width: 1200px;
    z-index: 66;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    .content {
        width: 400px;
        height: auto;
        overflow: hidden;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        a {
            display: block;
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
    }
}
}

</style>