<!-- 直播预约 -->
<template>
    <div class="subscribe">
        <div class="btn" @click="appointmentHandle">
            {{appointment.is_appointment ? $t('liveAppointment.reserved') : $t('liveAppointment.reserveLive')}}
        </div>
        <div class="num"> <strong>{{appointment.appointment_count}}</strong> {{$t('liveAppointment.reserveNum')}}</div>
        <div class="table">
            <div class="table-tit">
                <div>{{$t('liveAppointment.name')}}</div>
                <div>{{$t('liveAppointment.org')}}</div>
                <div>{{$t('liveAppointment.reserveTime')}}</div>
            </div>
            <div class="table-content" ref="viewConent">
                <ul class="anima" ref="srcollContent" :style=" `
                    animation-name: aitoScroll;
                    animation-timing-function: linear;
                    animation-delay: 0s;
                    animation-iteration-count: infinite;
                    animation-duration: ${animTime}s;`">
                    <li v-for="(item, index) in appointment.appointment_list" :key="index">
                        <p>{{item.real_name}}</p>
                        <p>{{item.org_cnname}}</p>
                        <p>{{item.createTime}}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
    },
    props: {
        appointment: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            animTime: 10,
            isAnimate: true
        }
    },
    methods: {
        appointmentHandle () {
            if (this.appointment.is_appointment) return;
            this.request.post('appointment', { live_id: this.$route.params.id, type: 1 }).then((res) => {
                this.appointment.is_appointment = true
            })
        },
        initAnimate1 () {
            this.$nextTick(() => {
                const appointment_list = this.appointment.appointment_list
                const viewConent = this.$refs.viewConent
                const srcollContent = this.$refs.srcollContent
                this.animTime = appointment_list.length
                const keyframes = `@keyframes aitoScroll {
                        0%{
                            top: 0;
                        }
                        100%{
                            top: -${srcollContent.clientHeight - viewConent.clientHeight}px;
                        }
                    }`
                // 创建style标签
                const style = document.createElement('style');
                // 设置style属性
                style.type = 'text/css';
                // 将 keyframes样式写入style内
                style.innerHTML = keyframes;
                // 将style样式存放到box标签
                document.getElementsByTagName('body')[0].appendChild(style);
            })
        },
    },
    mounted () {
        this.initAnimate1()
    }
}
</script>
<style lang="less">
// .anima {
//     animation-name: likes; // 动画名称
//     animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
//     animation-delay: 0s; // 动画延迟时间
//     animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
//     animation-duration: 10s; // 动画完成时间
// }
.subscribe {
    width: 100%;
    height: 100%;
    .btn {
        border: 1px solid #fe8e02;
        background-color: #fe8e02;
        color: #fff;
        font-size: 0.58rem;
        cursor: pointer;
        width: 3.5rem;
        margin: 0 auto;
        padding: 0.3rem 0;
        text-align: center;
        border-radius: 20px;
        font-weight: 700;
    }
    .num {
        color: #fe9819;
        text-align: center;
        font-size: 0.46rem;
        margin: 0.35rem 0 1.16rem 0;
    }
    .table {
        width: 100%;
        height: 20rem;
        padding: 0.38rem;
        box-sizing: border-box;
        .table-tit {
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: space-between;
            height: 40px;
            align-items: center;
            font-size: 0.5rem;
            font-weight: 700;
            & div:nth-of-type(1) {
                flex: 1;
            }
            & div:nth-of-type(2) {
                flex: 2;
            }
            & div:nth-child(3) {
                flex: 1;
            }
        }
        .table-content {
            width: 100%;
            height: 10rem;
            overflow: hidden;
            position: relative;
            ul {
                width: 100%;
                position: absolute;
                left: 0;
                li {
                    width: 100%;
                    height: 2rem;
                    text-align: center;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 0.46rem;
                    & p:nth-of-type(1) {
                        flex: 1;
                    }
                    & p:nth-of-type(2) {
                        flex: 2;
                    }
                    & p:nth-child(3) {
                        flex: 1;
                    }
                }
            }
            & ul:hover {
                animation-play-state: paused;
            }
        }
    }
}
</style>