export default [
  { url: 'https://livestatic.polyv.net/assets/images/em/1.png', title: '[微笑]', position: '0% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/2.png', title: '[撇嘴]', position: '1.0101010101010102% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/3.png', title: '[色]', position: '2.0202020202020203% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/4.png', title: '[发呆]', position: '3.0303030303030303% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/5.png', title: '[得意]', position: '4.040404040404041% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/6.png', title: '[流泪]', position: '5.05050505050505% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/7.png', title: '[害羞]', position: '6.0606060606060606% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/8.png', title: '[闭嘴]', position: '7.07070707070707% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/9.png', title: '[睡]', position: '8.080808080808081% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/10.png', title: '[大哭]', position: '9.090909090909092% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/11.png', title: '[尴尬]', position: '10.1010101010101% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/12.png', title: '[发怒]', position: '11.11111111111111% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/13.png', title: '[调皮]', position: '12.121212121212121% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/14.png', title: '[呲牙]', position: '13.131313131313133% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/15.png', title: '[惊讶]', position: '14.14141414141414% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/16.png', title: '[难过]', position: '15.151515151515152% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/17.png', title: '[酷]', position: '16.161616161616163% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/18.png', title: '[冷汗]', position: '17.17171717171717% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/19.png', title: '[抓狂]', position: '18.181818181818183% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/20.png', title: '[吐]', position: '19.19191919191919% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/21.png', title: '[偷笑]', position: '20.2020202020202% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/22.png', title: '[可爱]', position: '21.21212121212121% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/23.png', title: '[白眼]', position: '22.22222222222222% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/24.png', title: '[傲慢]', position: '23.232323232323232% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/25.png', title: '[饥饿]', position: '24.242424242424242% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/26.png', title: '[困]', position: '25.252525252525253% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/27.png', title: '[惊恐]', position: '26.262626262626267% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/28.png', title: '[流汗]', position: '27.27272727272727% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/29.png', title: '[憨笑]', position: '28.28282828282828% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/30.png', title: '[大兵]', position: '29.292929292929294% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/31.png', title: '[奋斗]', position: '30.303030303030305% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/32.png', title: '[咒骂]', position: '31.313131313131315% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/33.png', title: '[疑问]', position: '32.323232323232325% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/34.png', title: '[嘘]', position: '33.33333333333333% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/35.png', title: '[晕]', position: '34.34343434343434% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/36.png', title: '[折磨]', position: '35.35353535353536% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/37.png', title: '[衰]', position: '36.36363636363637% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/38.png', title: '[骷髅]', position: '37.37373737373738% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/39.png', title: '[敲打]', position: '38.38383838383838% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/40.png', title: '[再见]', position: '39.39393939393939% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/41.png', title: '[擦汗]', position: '40.4040404040404% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/42.png', title: '[抠鼻]', position: '41.41414141414141% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/43.png', title: '[鼓掌]', position: '42.42424242424242% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/44.png', title: '[糗大了]', position: '43.43434343434344% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/45.png', title: '[坏笑]', position: '44.44444444444444% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/46.png', title: '[左哼哼]', position: '45.45454545454545% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/47.png', title: '[右哼哼]', position: '46.464646464646464% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/48.png', title: '[哈欠]', position: '47.474747474747474% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/49.png', title: '[鄙视]', position: '48.484848484848484% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/50.png', title: '[委屈]', position: '49.494949494949495% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/51.png', title: '[快哭了]', position: '50.505050505050505% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/52.png', title: '[阴险]', position: '51.515151515151516% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/53.png', title: '[亲亲]', position: '52.52525252525253% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/54.png', title: '[吓]', position: '53.535353535353536% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/55.png', title: '[可怜]', position: '54.54545454545454% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/56.png', title: '[菜刀]', position: '55.55555555555556% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/57.png', title: '[西瓜]', position: '56.56565656565656% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/58.png', title: '[啤酒]', position: '57.57575757575758% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/59.png', title: '[篮球]', position: '58.58585858585859% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/60.png', title: '[乒乓]', position: '59.59595959595959% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/61.png', title: '[咖啡]', position: '60.60606060606061% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/62.png', title: '[饭]', position: '61.61616161616161% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/63.png', title: '[猪头]', position: '62.62626262626263% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/64.png', title: '[玫瑰]', position: '63.63636363636363% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/65.png', title: '[凋谢]', position: '64.64646464646465% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/66.png', title: '[示爱]', position: '65.65656565656566% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/67.png', title: '[爱心]', position: '66.66666666666666% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/68.png', title: '[心碎]', position: '67.67676767676768% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/69.png', title: '[蛋糕]', position: '68.68686868686868% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/70.png', title: '[闪电]', position: '69.6969696969697% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/71.png', title: '[炸弹]', position: '70.70707070707071% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/72.png', title: '[刀]', position: '71.71717171717171% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/73.png', title: '[足球]', position: '72.72727272727273% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/74.png', title: '[瓢虫]', position: '73.73737373737373% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/75.png', title: '[便便]', position: '74.74747474747475% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/76.png', title: '[月亮]', position: '75.75757575757575% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/77.png', title: '[太阳]', position: '76.76767676767676% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/78.png', title: '[礼物]', position: '77.77777777777779% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/79.png', title: '[拥抱]', position: '78.78787878787878% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/80.png', title: '[强]', position: '79.7979797979798% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/81.png', title: '[弱]', position: '80.8080808080808% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/82.png', title: '[握手]', position: '81.81818181818183% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/83.png', title: '[胜利]', position: '82.82828282828282% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/84.png', title: '[抱拳]', position: '83.83838383838383% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/85.png', title: '[勾引]', position: '84.84848484848484% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/86.png', title: '[拳头]', position: '85.85858585858585% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/87.png', title: '[差劲]', position: '86.86868686868688% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/88.png', title: '[爱你]', position: '87.87878787878788% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/89.png', title: '[NO]', position: '88.88888888888889% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/90.png', title: '[OK]', position: '89.8989898989899% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/91.png', title: '[爱情]', position: '90.9090909090909% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/92.png', title: '[飞吻]', position: '91.91919191919192% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/93.png', title: '[跳跳]', position: '92.92929292929293% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/94.png', title: '[发抖]', position: '93.93939393939394% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/95.png', title: '[怄火]', position: '94.94949494949495% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/96.png', title: '[转圈]', position: '95.95959595959596% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/97.png', title: '[磕头]', position: '96.96969696969697% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/98.png', title: '[回头]', position: '97.97979797979798% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/99.png', title: '[跳绳]', position: '98.98989898989899% 0' },
  { url: 'https://livestatic.polyv.net/assets/images/em/100.png', title: '[挥手]', position: '100% 0' },
]
