<template>
    <div class="live-playback">
        <ul class="videoList">
            <li class="videoItem" v-for="(item, index) in videoList" :key="index" @click="playVideo(item.video_id)">
                <div class="b-img">
                    <div class="play-icon">
                        <span class="iconfont icon-bofang"></span>
                    </div>
                    <img :src="item.avatar | urlFilter(250)" alt="">
                </div>
                <div class="content">
                    <div class="tit">{{item.title}}</div>
                    <div class="uname" v-if="$i18n.locale == 'cn'">{{item.real_name}} {{item.org_cnname}}</div>
                    <div class="uname" v-else>{{item.given_name}} {{item.family_name}} {{item.org_enname}}</div>
                    <div class="hits">{{$t('hits')}} {{item.hits}}</div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    props: {
        videoList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapState(['liveDetail'])
    },
    methods: {
        playVideo (videoId) {
            let { query,params } = this.$route;
            let lang = 'cn'
            if (this.liveDetail.cnen == '1') {
                lang = 'en'
            }
            setTimeout(() => {
                window.location.href = `${window.location.href.substr(0, window.location.href.indexOf('#'))}#/${params.id}?video_id=${videoId}&lang=${lang}&time=${new Date().getTime()}`
                window.location.reload()
            }, 200);
        }
    },
    created () {
    }
}
</script>
<style lang="less" scoped>
.live-playback {
    width: 100%;
    height: 100%;
    .videoItem {
        width: 100%;
        margin-bottom: 0.38rem;
        display: flex;
        cursor: pointer;
        .b-img {
            width: 5rem;
            height: 3rem;
            margin-right: 0.5rem;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
            .play-icon {
                width: 1rem;
                height: 1rem;
                border-radius: 50%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background: rgba(0, 0, 0, 0.4);
                text-align: center;
                line-height: 1rem;
                span {
                    font-size: 0.46rem;
                    color: #FFFFFF;
                }
            }
        }
        .content {
            flex: 1;
            overflow: hidden;
            .tit {
                font-size: 0.54rem;
                color: var(--fontColor);
            }
            .uname {
                font-size: 0.46rem;
                color: var(--fontColor1);
                margin-top: 0.7rem;
            }
            .hits {
                font-size: 0.46rem;
                color: var(--fontColor1);
                margin-top: 0.1rem;
            }
        }
    }
}
</style>