module.exports = {
    /**
     * 一般简单通用数据格式化
     * @param {数据列表} list 
     * @param {需要格式化成最终的格式} 
     * obj = {
     *      // img格式化的key 
     *      // banner_url格式化前的key
     *      img:banner_url
     * } 
     */
    jsonFormat: function (list, obj) {
        let result = [];
        list.forEach((item, index) => {
            let currItem = {}
            for (let key in obj) {
                currItem[key] = item[obj[key]];
            }
            result.push(currItem)
        })
        return result;
    }


    /************************ 其他格式化 *************************/
}