<template>
    <div class="__adv" v-if="duration > 0" :style="{position:position}">
        <a class="__adv-time" @click="isAdvTimeClose ? duration = 0 : ''" :style="{[location[0]]:'6px',[location[1]]:'6px'}" href="javascript:;">{{beforeAdvText}}{{duration}}s{{afterAdvText}}</a>
        <a class="adv-img-content" style="display:block" :href="href ? href : 'javascript:;'">
            <img :style="{width,height}" :src="src" alt="">
            <slot></slot>
        </a>
        <!-- <a class="__adv-btn" href="javascript:;">关闭广告</a> -->
    </div>
</template>
<script>
let timer = null;
export default {
    props: {
        position: {
            type: String,
            default: 'relative',
        },
        location:{
            type:Array,
            default(){
                return ['left','top'];
            }
        },
        width: {
            type: String,
            default: '100%',
        },
        height: {
            type: String,
            default: 'auto',
        },
        src: {
            type: String,
            default: '',
        },
        href:{
            type: String,
            default: '',
        },
        // 倒计时前
        beforeAdvText: {
            type: String,
            default: '',
        },
        // 倒计时后
        afterAdvText: {
            type: String,
            default: '',
        },
        isAdvTimeClose:{
            type: Boolean,
            default: false,
        },
        time: {
            type: [String, Number],
            default: 3,
        },
        isCloseBtn: {
            type: Boolean,
            default: false,
        },
        btnText: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            duration: 0,
        }
    },
    methods: {
        countdownTime () {
            if (this.duration <= 0) {
                clearTimeout(timer);
                this.$emit('close');
                return false;
            }
            timer = setTimeout(() => {
                --this.duration;
                this.countdownTime();
            }, 1000)
        },
    },
    mounted() {
        this.duration = this.time;
        this.countdownTime();
    }
}
</script>
<style lang="less" scoped>
.__adv {
    width: 100%;
    height: auto;
    position: relative;
    .adv-img-content {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        img {
            position: absolute;
            width: 100% !important;
            height: 100% !important;
            left: 0;
            top: 0;
        }
    }
    &-time {
        position: absolute;
        border-radius: 30px;
        background-color: rgba(0, 0, 0, 0.48);
        color: #fff;
        text-align: center;
        font-size: 12px;
        padding: 0.2rem 0.5rem;
        opacity: 0.8;
        z-index: 9999;
    }
    &-btn{
        padding: 0.2rem 0.5rem;
    }
}
</style>