<template>
    <div class="live-list" v-if="Object.keys(tabList).length">
        <!-- 直播间里 -->
        <live-across v-if="live_config.meeting_hall == '1' || live_config.meeting_hall == '2'" :liveList="tabList"></live-across>
        <!-- 直播间列表table里 -->
        <tab-across :liveList="tabList" v-if="live_config.meeting_hall == '4'"></tab-across>
        <tab-vertical :liveList="tabList" v-if="live_config.meeting_hall == '3'"></tab-vertical>
    </div>
</template>
<script>
import liveAcross from './live/live-across.vue'
import tabAcross from './tab/tab-across.vue'
import tabVertical from './tab/tab-vertical.vue'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
    props: {
        liveList: {
            type: Object,
            default: {}
        }
    },
    components: {
        liveAcross,
        tabAcross,
        tabVertical
    },
    inheritAttrs: false,
    data() {
        return {
            active: 0,
            live_id: '',
            tabList: {},
            imgUrl: 'https://files.sciconf.cn/'
        }
    },
    computed: {
        ...mapState(['live_config', 'liveDetail'])
    },
    methods: {
        // 获取会议列表
        getMeetingHallList () {
            this.request.get('getMeetingHallList', { live_id: this.live_id }).then(res => {
                this.tabList = res.data;
                this.$store.commit('setLiveTabDate', Object.keys(this.tabList)[0])
            })
        },
        changeDate (index, title) {
            this.$store.commit('setLiveTabDate', Object.keys(this.tabList)[index])
        }
    },
    created () {
        this.live_id = this.$route.params.id
        if (Object.keys(this.liveList).length == 0) {
            if (this.live_config.meeting_hall == '1' || this.live_config.meeting_hall == '2') {
                this.getMeetingHallList()
            }
        } else {
            this.tabList = this.liveList
        }
    }
}
</script>
<style lang="less" scoped>
.live-list {
    position: relative;
    background: var(--backgroundColor);
    font-size: 0.6rem;
    line-height: 1.2rem;
}

// 移动端固定tabbar
@media screen and (max-width: 550px) {
    .__tab-scroll {
        max-height: 100%;
    }
}
</style>