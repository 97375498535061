<template>
    <div class="__cm-item flex x-left">
        <img class="__cm-item-avatar" :src="item.avatar" alt="">
        <div class="__cm-item-info flex-item">
            <div class="flex __cmi-user x-left y-center">
                <span class="__cmi-user-name"> {{item.real_name || item.user_name || $t('message.anonymous')}}</span>
                <span class="__cmi-user-date">{{item.create_time.substr(0,16)}}</span>
            </div>
            <div class="__cmi-info-content" v-html="item.content"></div>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        item:{
            type:Object,
            default:() => {}
        },
    },
    watch:{
        item(val){
        }
    },
    methods: {

    }
}
</script>
<style lang="less" scoped>
.__cm-item {
    margin-top:0.6rem;
    .__cm-item-info {
        width: calc(100% - 1.5rem);
        overflow: hidden;
    }
    &-avatar{
        width:1.5rem;
        height:1.5rem;
        border-radius: 50%;
        margin-right:0.3rem;
        object-fit: cover;
        object-position: center center;
    }
    &-info{
        padding-bottom:0.6rem;
        border-bottom: 1px dashed rgba(200,200,200,0.5);
        .__cmi-user{
            white-space: nowrap;         //让文本不换行，在同一行里面
            overflow: hidden;           //让超出的范围进行隐藏
            text-overflow: ellipsis;
            &-name{
                font-weight: bold;
                font-size: 0.56rem;
                color:var(--fontColor);
                max-width: 3rem;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &-date{
                color: var(--fontColor1);
                font-size: 0.48rem;
                font-weight: 400;
                margin-left: 0.3rem;
            }
        }
        .__cmi-info-content{
            color: var(--fontColor);
            font-size:0.5rem;
            line-height: 1.5;
            margin-top:0.3rem;
            padding: 0.2rem;
            word-break: break-all;
            //background: var(--bodyColor);
            //box-shadow: 0px 3px 8px 0px rgb(70 104 224 / 10%);
            border-radius: 0 8px 8px 8px;
            /deep/img{
                max-width: 0.8rem;
                position: relative;
                top:.2rem;
            }
        }
    }
    &:last-child{
        .__cm-item-info{
            border-bottom:0;
            padding-bottom:0;
        }
    }
}
</style>