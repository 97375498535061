import emotionData from './emotions.js'
const emotionMap = {}
emotionData.forEach((item) => {
  if (item.title) {
    emotionMap[item.title] = item
  }
})

const parseEmoji = (content) => {
  let emojiIndexList = []
  for (const k in emotionMap) {
    let idx = content.indexOf(k)
    while (idx >= 0) {
      emojiIndexList.push({ idx, code: k, type: 2 })
      idx = content.indexOf(k, idx + k.length)
    }
  }

  emojiIndexList = emojiIndexList.sort((a, b) => {
    return a.idx - b.idx
  })


  const newContentList = []
  let lastTextIndex = 0
  emojiIndexList.forEach((item) => {
    if (lastTextIndex !== item.idx) {
      newContentList.push( content.substring(lastTextIndex, item.idx) )
    }
    if (item.type === 2) {
      newContentList.push(`<img src="${emotionMap[item.code].url}" alt="${(item.code).substring(1, item.code.length - 1)}"/>`)
    }
    lastTextIndex = item.idx + item.code.length
  })

  const lastText = content.substring(lastTextIndex)
  if (lastText) {
    newContentList.push(lastText)
  }
  return newContentList.join('');
}

export default parseEmoji;
