<!-- 大会日程item -->
<template>
    <div class="schedule-item">
        <div>
            <div class="time-title" v-if="$i18n.locale == 'cn'">
                {{scheduleItem.start_time}}
            </div>
            <div class="time-title" v-if="$i18n.locale == 'en'">
                <p>{{scheduleItem.start_time}} (UTC+8)</p>
                <p>{{scheduleItem.start_time}} Local Time</p>
            </div>
            <div class="sub-title">{{scheduleItem.title}}</div>
            <div class="box-content">
                <!-- 其他 -->
                <div class="other" v-for="(item, index) in scheduleItem.session_duty" :key="index">
                    <div class="line">
                        <div class="chunk">{{item.session_duty_name}}</div>
                    </div>
                    <div class="user-info-item" v-for="(subItem, subIndex) in item.user_list" :key="subIndex">
                        <div class="left-content">
                            <div class="user-photo">
                                <img :src="subItem.avatar | urlFilter(250)" alt="">
                            </div>
                            <div class="user-info">
                                <div class="real_name">{{ subItem.real_name }}</div>
                                <div class="org_cnname">{{ subItem.org_cnname }}</div>
                            </div>
                        </div>
                        <div class="user-icon">
                            <div class="remark" @click="openRemark(subItem)">
                                <div class="iconfont icon-pinglun"></div>
                                <p>评论</p>
                            </div>
                            <div class="tags" v-if="live_config.is_zan != '0'" @click="likeHandle(subItem, 'session_duty', index)">
                                <div :class="['iconfont icon-aixin_shixin', subItem.is_dz == 0 ? '' : 'active']"></div>
                                <p>点赞</p>
                            </div>
                            <!-- <div class="give" v-if="live_config.is_shang != '0'" @click="giveHandle(subItem)">
                                <div class="iconfont icon-dashang2"></div>
                                <p>打赏</p>
                            </div> -->
                        </div>
                    </div>
                </div>
                <!-- 日程安排 -->
                <div class="line" v-if="scheduleItem.duty_list">
                    <div class="chunk">{{$t('program.title')}}<!--日程安排--></div>
                </div>
                <div class="scheduleList" v-for="(item, index) in scheduleItem.duty_list" :key="index + Math.random()">
                    <div class="title" v-if="$i18n.locale == 'cn'">
                        <span class="iconfont icon-shijian"></span> 
                        {{item.start_time}}-{{item.end_time}}
                    </div>
                    <div class="title" v-if="$i18n.locale == 'en'">
                        <span class="iconfont icon-shijian"></span> 
                        <p>{{item.start_time}}-{{item.end_time}} (UTC+8)</p>
                        <p>{{item.start_time}}-{{item.end_time}} Local Time</p>
                    </div>
                    <div class="content" :style="$i18n.locale == 'en' ? 'margin-top: 1.58rem;' : ''">
                        <div class="subTitle">
                            {{item.abstract_title}}
                        </div>
                        <div class="user-info-item" v-for="(subItem, subIndex) in item.user_list" :key="subIndex">
                            <div class="left-content">
                                <div class="user-photo">
                                    <img :src="subItem.avatar | urlFilter(250)" alt="">
                                </div>
                                <div class="user-info">
                                    <div class="real_name">{{ subItem.real_name }}</div>
                                    <div class="org_cnname">{{ subItem.org_cnname }}</div>
                                </div>
                            </div>
                            <div class="user-icon">
                                <div class="remark" @click="openRemark(subItem)">
                                    <div class="iconfont icon-pinglun"></div>
                                    <p>评论</p>
                                </div>
                                <div class="tags" v-if="live_config.is_zan != '0'" @click="likeHandle(subItem, 'duty_list', index)">
                                    <div :class="['iconfont icon-aixin_shixin', subItem.is_dz == 0 ? '' : 'active']"></div>
                                    <p>点赞</p>
                                </div>
                                <!-- <div class="give"  v-if="live_config.is_shang != '0'" @click="giveHandle(subItem)">
                                    <div class="iconfont icon-dashang2"></div>
                                    <p>打赏</p>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { isWeiXin } from '@/utils/common.js'
export default {
    props: {
        scheduleItem: {
            type: Object,
            value: {}
        }
    },
    data() {
        return {
            isMobile: false,
            likeList: []
        }
    },
    computed: {
        ...mapState(['live_config', 'liveDetail'])
    },
    methods: {
        // 打赏
        giveHandle (item) {
            if (isWeiXin()) {
                this.$parent.givePopup = true
                this.$parent.pdy_id = item.pdy_id
            } else {
                this.$toast('请使用微信打开进行打赏！');
            }
        },
        // 评论
        openRemark (item) {
            if (this.isMobile) {
                this.$parent.showRemark = true
                this.$parent.pdy_id = item.pdy_id
            }
        },
        // 点赞
        likeHandle (item, type, index) {
            const itemArr = this.scheduleItem[type][index].user_list
            this.request.post('like', {
                for_user_id: Number(item.pdy_id), // userid
                type_id: Number(item.pdy_id), // 对应点赞的日程id
                dz_type: item.is_dz == 0 ? 'dz' : 'cancel', // 点赞类型 dz点赞 cancel取消点赞
                program_type: Number(this.liveDetail.program_show), // 日程类型1嘉宾日常 2会议日程 3自定义日程
                live_id: Number(this.$route.params.id)
            }).then((res) => {
                if (res.code == 200) {
                     if (Array.isArray(itemArr)) { // 数组
                        for (let i = 0; i < itemArr.length; i++) {
                            if (item.pdy_id == itemArr[i].pdy_id) {
                                if (item.is_dz == 0) {
                                    this.scheduleItem[type][index].user_list[i].is_dz = 1
                                    this.$toast('点赞成功')
                                } else {
                                    this.scheduleItem[type][index].user_list[i].is_dz = 0
                                    this.$toast('取消成功')
                                }
                            }                    
                        }
                    } else { // 对象
                        for (let i in itemArr) {
                            if (item.pdy_id == itemArr[i].pdy_id) {
                                if (item.is_dz == 0) {
                                    this.scheduleItem[type][index].user_list[i].is_dz = 1
                                    this.$toast('点赞成功')
                                } else {
                                    this.scheduleItem[type][index].user_list[i].is_dz = 0
                                    this.$toast('取消成功')
                                }
                            }
                        }
                    }
                } else {
                    this.$toast('操作失败')
                }
            })
        }
    },
    created () {
        if (window.screen.width > 500) { // pc端
            this.isMobile = false
       } else { // 移动端
            this.isMobile = true
       }
    }
}
</script>
<style lang="less" scoped>
.schedule-item {
    .box-content {
        background: var(--scheduleContentBgColor);
        border-radius: 0 0 0.38rem 0.38rem;
        overflow: hidden;
        padding: 0 0.53rem 0.65rem 0.53rem;
        box-sizing: border-box;
    }
    margin-bottom: 0.4rem;
    .time-title {
        width: 100%;
        font-size: 0.6rem;
        color: #FFFFFF;
        font-family: MicrosoftYaHei-Bold;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.69rem;
        padding: 0.57rem 0 0.57rem 0.53rem;
        letter-spacing: 0;
        border-radius: 0.38rem 0.38rem 0 0;
        background: var(--scheduleDateTitleBgColor);
        box-sizing: border-box;
        font-weight: bold;
    }
    .sub-title {
        width: 100%;
        font-size: 0.64rem;
        color: #FFFFFF;
        font-family: MicrosoftYaHei-Bold;
        font-weight: normal;
        font-stretch: normal;
        line-height: 0.81rem;
        padding: 0.62rem 0 0.68rem 0.51rem;
        letter-spacing: 0;
        background: var(--scheduleTitleBgColor);
        box-sizing: border-box;
        font-weight: bold;
    }
    .line {
        border-bottom: 1px solid var(--labelLineColor);
        overflow: hidden;
        position: relative;
        padding-top: 0.65rem;
        .chunk {
            font-size: 0.62rem;
            color: var(--labelFontColor);
            font-weight: bold;
            letter-spacing: 0px;
            padding: 0.19rem 0.53rem 0.19rem 0.41rem;
            border-radius: 0.19rem 0.19rem 0 0;
            background: var(--labelLineColor);
            float: left;
        }
        &:before {
            content: '';
            position: absolute;
            width: 21px;
            height: 4px;
            background-color: var(--labelRightColor);
            border-radius: 0px 3px 0px 0px;
            opacity: 0.6;
            right: 0;
            bottom: 0;
        }
    }
    .other {
        .user-info-item:last-child {
            border: none;
        }
    }
    .scheduleList {
        padding: 0.38rem 0 0.38rem 0.38rem;
        margin-top: 0.58rem;
        background: var(--scheduleListBgColor);
        border-radius: 0.38rem;
        .title {
            font-size: 0.64rem;
            color:var(--scheduleContentTitleDateFontColor);
            font-weight: bold;
            height: 1rem;
            line-height: 1rem;
            position: relative;
            padding-left: 0.8rem;
            box-sizing: border-box;
            span {
                font-size: 0.6rem;
                position: absolute;
                left: 0;
                top: 46%;
                transform: translate(0, -50%);
            }
        }
        .content {
            padding-left: 0.58rem;
            margin-top: 0.58rem;
            box-sizing: border-box;
            overflow: hidden;
            .subTitle {
                font-family: MicrosoftYaHei-Bold;
                font-size: 0.6rem;
                font-weight: bold;
                font-stretch: normal;
                letter-spacing: 0px;
                color: var(--scheduleContentTitleFontColor);
                word-break: break-all;
            }
            .user-info-item:last-child {
                border: none;
            }
            .user-info-item {
            }
        }
    }
    .user-info-item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0.62rem 0;
        box-sizing: border-box;
        border-bottom: solid 1px rgba(220,220,220,0.5);
        .left-content {
            flex: 3;
            .user-photo {
                width: 1.54rem;
                height: 1.54rem;
                border-radius: 50%;
                overflow: hidden;
                float: left;
                object-fit: cover;
                object-position: center center;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center top;
                }
            }
            .user-info {
                color: var(--scheduleContentDescFontColor);
                margin-left: 0.27rem;
                float: left;
                overflow: hidden;
                text-overflow: ellipsis;
                .real_name{
                    font-size: 0.55rem;
                }
                .org_cnname {
                    font-size: 0.45rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
       .user-icon {
            padding-right: 0.38rem;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            .remark {
                cursor: pointer;
                div {
                    text-align: center;
                    font-size: 0.77rem;
                    color: #CCCCCC;
                    &.active {
                        color: #1268ca;
                    }
                }
                p {
                    font-size: 0.45rem;
                    color: #888888;
                }
            }
            .tags {
                margin-left: 0.38rem;
                cursor: pointer;
                div {
                    text-align: center;
                    font-size: 0.77rem;
                    color: #CCCCCC;
                    &.active {
                        color: #e95c4e;
                    }
                }
                p {
                    font-size: 0.42rem;
                    color: #888888;
                }
            }
            .give {
                margin-left: 0.38rem;
                cursor: pointer;
                div {
                    text-align: center;
                    font-size: 0.77rem;
                    color: #e95c4e;
                    &.active {
                        color: #1268ca;
                    }
                }
                p {
                    font-size: 0.45rem;
                    color: #e95c4e;
                }
            }
       }
    }
}
</style>