<!-- 广告弹窗 -->
<template>
    <div class="tips-adv">
    </div>
</template>
<script>
export default {
    props: {
        advConfig: {
            type: Array,
            default: []
        },
        advType: {
            type: String,
            default: ''
        }
    },
    components: {
    },
    data() {
        return {
        }
    },
    computed: {
    },
    watch: {
    },
    methods: {
    },
    created () {
    }
}
</script>
<style lang="less" scoped>
</style>