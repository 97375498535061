<template>
    <div class="__tab-box" v-if="isShow">
        <div class="__tab-scroll">
            <van-tabs @click="handleTabEvent" :swipeable="true" v-model="active" class="tabs-box">
                <van-tab v-for="(item,index) in tabList" :key="index" :title="item[$i18n.locale == 'cn' ? 'title' : 'title_en']">
                    <!-- 富文本类型 -->
                    <div class="__tab-info infoTable" v-if="item.module_type == 'info'" v-html="item[$i18n.locale == 'cn' ? 'content' : 'content_en']"></div>
                    <!-- 预约直播 -->
                    <div class="__tab-info" v-if="item.module_type == 'appointment'">
                        <live-subscribe :appointment="item.appointment"></live-subscribe>
                    </div>
                    <!-- 大会日程 -->
                    <div class="__tab-info" v-if="item.module_type == 'program'">
                        <schedule :programData="item.program_data"></schedule>
                    </div>
                    <!-- 互动留言 -->
                    <div ref="charMessageBox" class="__tab-info" style="padding:0;" v-if="item.module_type == 'message' && disPlayMessage">
                        <char-message type="1" :title="item[$i18n.locale == 'cn' ? 'title' : 'title_en']" @scrollBottom="handleScrollBottomEvent"></char-message>
                    </div>
                    <!-- 直播回放 -->
                    <div class="__tab-info" v-if="item.module_type == 'open_live_playback'">
                        <live-playback :video-list="item.video_list"></live-playback>
                    </div>
                    <!-- 直播间列表 -->
                    <div class="__tab-info" v-if="item.module_type == 'live_list'" style="padding:0;">
                        <live-list :liveList='item.live_list_data'></live-list>
                    </div>
                    <!-- 在线展览 -->
                    <div class="__tab-info" v-if="item.module_type == 'online_exhibitor'">
                        <m-exhibition :exhibition-list="item.online_exhibitor"></m-exhibition>
                    </div>
                    <!-- 卫星会 -->
                    <div class="__tab-info" v-if="item.module_type == 'satellite_symposium'">
                        <m-satellite :satellite-list="item.satellite_program"></m-satellite>
                    </div>
                    <!-- 自定义 -->
                    <div class="__tab-info infoTable" v-if="item.module_type == 'content'">
                        <div v-html="item.content"></div>
                    </div>
                    <!-- 嘉宾介绍 -->
                    <div class="__tab-info" v-if="item.module_type == 'special_guest'">
                        <m-guest :guestList='item.special_guest'></m-guest>
                    </div>
                    <!-- 返回官网 -->
                    <div class="__tab-info" v-if="item.module_type == 'return_home'">
                    </div>
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>
<script>
import charMessage from '@/components/live-comps/char-message/index'
import schedule from '@/components/schedule/index.vue'
import liveSubscribe from '@/components/live-subscribe/index.vue'
import liveList from '@/components/live-list/index.vue'
import livePlayback from '@/components/live-playback/index.vue'
import mExhibition from '@/components/m-exhibition/index.vue'
import mSatellite from '@/components/m-satellite/index.vue'
import mGuest from '@/components/m-guest/index.vue'
import { mapState } from 'vuex'
export default {
    components: {
        charMessage,
        schedule,
        liveSubscribe,
        liveList,
        livePlayback,
        mExhibition,
        mSatellite,
        mGuest
    },
    inheritAttrs: false,
    data() {
        return {
            active: 0,
            tabList: [],
            offsetTop: (() => {
                return window.innerWidth / 16 * 9;
            })(),
            isShow: true
        }
    },
    computed: {
        ...mapState(['isMessage']),
        live_config () {
            return this.$store.state.live_config
        },
        disPlayMessage () {
            if (!this.$store.state.isPower) {
                return false
            }
            if (!this.isMessage) {
                return false
            }
            if (window.screen.width < 980) { // 移动端
                return true
            } else { // pc端
                if (this.$store.state.live_config.display_mode == '1') { // 播放器右侧
                    return false
                } else if (this.$store.state.live_config.display_mode == '2') { // 直播页tab展示
                    return true
                } else {
                    return false
                }
            }
        },
        liveStatus () {
            return this.$store.state.liveStatus
        }
    },
    methods: {
        toGuestPages () {
            console.log(132)
        },
        // 获取标签栏
        getLiveTabList() {
            this.request.get('getLiveTabList', { live_id: this.__live_id, source_type: 'h5', lang: this.$i18n.locale }).then((res) => {
                // 删除聊天
                if (res.data) {
                    for (let i = 0; i < res.data.length; i++) {
                        let item = res.data[i]
                        if (item.module_type == 'message') { // 存在聊天
                            if (!this.live_config.display_mode) {
                                this.$store.commit('setLiveConfig', { ...this.live_config, display_mode: '1' })
                                if (window.screen.width > 980) {
                                    this.$store.commit('setMessageInfo',item);
                                    res.data.splice(i, 1);
                                }
                            } else if (this.live_config.display_mode == '1') {
                                if (window.screen.width > 980) {
                                    this.$store.commit('setMessageInfo',item);
                                    res.data.splice(i, 1);
                                }
                            }
                            if (!this.live_config.is_hide_message) {
                                this.$store.commit('setLiveConfig', { ...this.live_config, is_hide_message: '1' })
                            }
                            if (!this.live_config.is_hide_question) {
                                this.$store.commit('setLiveConfig', { ...this.live_config, is_hide_question: '1' })
                            }
                            this.$store.commit('setIsMessage', true)
                            break;
                        } else {
                            this.$store.commit('setIsMessage', false)
                        }
                    }
                    // 直播状态，删除预约直播
                    if (this.liveStatus) {
                        res.data.forEach((item, index) => {
                            if (item.module_type == 'appointment') {
                                res.data.splice(index, 1);
                            }
                        })
                    }
                    this.tabList = res.data;
                }
                if (this.tabList.length == 0) {
                    this.isShow = false
                }
            })
        },
        // 聊天功能置底
        handleScrollBottomEvent () {
            this.$nextTick(() => {
                // 紧移动端滚动置底
                this.$refs.charMessageBox && (this.$refs.charMessageBox[0].scrollTop = 9999999999)
                this.$refs.charMessageBox[0].getElementsByClassName('van-tabs__content')[0] && ( this.$refs.charMessageBox[0].getElementsByClassName('van-tabs__content')[0].scrollTop = 9999999999)
            })
        },
        // 切换tabbar
        handleTabEvent(index){
            let timer = setTimeout(() => {
                clearTimeout(timer);
                this.active = this.__currIndex || 0;
            }, 100)
            let item = this.tabList[index];
            if (item.module_type == 'return_home') {
                window.location.href = item.url
                return false
            }
            if(item.module_type == 'link'){
                window.open(item.url);
                return false;
            }
            if (item.module_type == 'special_guest') {
                if (window.screen.width > 500) {
                    window.open(`https://mm.sciconf.cn/cn/minisite/speaker/${this.$store.state.meeting_id}`, 'example')
                } else {
                    window.location.href = `https://mm.sciconf.cn/cn/minisite/speaker/${this.$store.state.meeting_id}`
                }
                return false
            }
            this.__currIndex = index;
        }
    },
    created () {
        this.__live_id = this.$route.params.id;
    },
    mounted () {
        this.getLiveTabList();
    }
}
</script>
<style lang="less">
.tabs-box {
    .van-tabs__nav {
        background: var(--backgroundColor);
        .van-tab__text {
            color: var(--tabColor);
        }
        .van-tab--active {
            .van-tab__text {
                color: var(--tabActiveColorLine);
            }
        }
        .van-tabs__line {
            background: var(--tabActiveColorLine);
        }
    }
}
.__tab-box {
    margin-top: 0.3rem;
    position: relative;
    background: var(--backgroundColor) !important;
    // 皮肤定制
    /deep/.van-tabs{
        height:100%;
    }
    /deep/.van-tabs__wrap {
        border: none !important;
        height: 2rem;
        max-width: @container;
        margin: 0 auto;
        position: sticky;
        top: 0;
        z-index: 2;
        box-sizing: border-box;
        background: var(--backgroundColor);
        // position: relative;
        &:before {
            position: absolute;
            content: '';
            height: 1px;
            width: 100%;
            left: 0;
            bottom: 1px;
            background: var(--borderColor);
            z-index: 3;
            opacity: 0.3;
        }
    }
    /deep/.van-tabs__nav{
        background: var(--backgroundColor) !important;
    }
    /deep/.van-tabs__content {
        height:calc(100% - 2rem);
    }
    /deep/.van-tab__pane{
        height:100%;
    }
    /deep/.van-tabs__line {
        background-color: var(--tabActiveColorLine);
    }
    /deep/.van-tab {
        font-size: 0.6rem;
        line-height: 1.2rem;
    }
    /deep/.van-tab--active {
        .van-tab__text {
            color: var(--tabActiveColor) !important;
        }
    }
    /deep/.van-tab__text {
        color: var(--tabColor);
    }
    .__tab-info {
        padding: 0.5rem;
        // height:calc(100vh - 2rem);
        overflow: auto;
        background: var(--backgroundColor);
        color: var(--fontColor);
    }
    .infoTable {
        img {
            max-width: 100% !important;
            height: auto;
            display: block;
            margin: 0 auto;
        }
    }
}

// 移动端固定tabbar
@media screen and (max-width: 550px) {
    .__tab-scroll {
        max-height: 100%;
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
    }
}
</style>