<template>
    <div>
        <template v-if="isAd">
            <live-warmup>
            </live-warmup>
        </template>
        <!-- 有直播状态 -->
        <template v-else-if="liveStatus">
            <!-- 直播间 -->
            <!-- <live-player v-if="playerStatus" v-bind="$attrs" v-on="$listeners"></live-player> -->
            <tc-player v-if="playerStatus" v-bind="$attrs" v-on="$listeners"></tc-player>
            <template v-else-if="prerollConfigAdv">
                <!-- 图片广告 -->
                <adv
                    v-if="prerollConfigAdv.src && prerollConfigAdv.ad_resource == 1" 
                    :src="prerollConfigAdv.src"
                    :href="prerollConfigAdv.href"
                    :time="prerollConfigAdv.skipTime" >
                </adv>
                <!-- 视频广告 -->
                <!-- playsinline：设置播放器在移动设备上不全屏[ Boolean, default: false ] -->
                <!-- customEventName：自定义状态变更时的事件名[ String, default: 'statechanged' ] -->
                <a class="adv-video-container" v-if="prerollConfigAdv.src && prerollConfigAdv.ad_resource == 2" @click="handleClickPrerollEvent"  href="javascript:;">
                    <video 
                        ref="myVideoAdv" 
                        :src="prerollConfigAdv.src"
                        autoplay
                        controls
                        controlslist="nodownload noplaybackrate nokeyboard nodownload"
                        @pause="onPlayerPause"
                        @timeupdate="onPlayerTimeupdate">
                    </video>
                </a>
            </template>
        </template>
        <!-- 无直播状态 -->
        <div class="live-banner" v-else>
            <img v-if='live_banner' style="width:100%;display:block" :src="live_banner | urlFilter" alt="">
            <img v-else style="width:100%;display:block" src="https://files.sciconf.cn/asset/online-meeting/images/live-default-banner.jpg" alt="">
            <count-down></count-down>
        </div>
    </div>
</template>
<script>
let timer = null;
import { adv,livePlayer,countDown, liveWarmup } from '@/components/live-comps'
import tcPlayer from './tc-player.vue'
import { mapState } from 'vuex'
export default {
    components: {
        livePlayer,
        countDown,
        adv,
        liveWarmup,
        tcPlayer
    },
    inheritAttrs: false,
    data() {
        return {
            // 是否开始直播
            playerStatus:true,
            isAd: false, // 是否开启暖场
        }
    },
    computed: {
        ...mapState(['liveStatus', 'live_banner', 'prerollConfigAdv', 'liveDetail']),
        //插件节点 用于添加自定义按钮事件
        player () {
            return this.$refs.videoPlayer.player
        }
    },
    watch:{
        // 直播前广告
        prerollConfigAdv: {
            handler (newVal) {
                if(newVal){
                    // 有广告需要暂停直播
                    this.playerStatus = false;
                    // 如果是图片广告，则开启倒计时
                    if(newVal.ad_resource == 1) {
                        this.countTimeFun(newVal.skipTime);
                    }
                }else{
                    // 没有广告直接播放
                    this.playerStatus = true;
                }
            }
        },
        liveDetail: {
            handler (newVal) {
                if (newVal && newVal.is_ad)  {
                    if (newVal.is_ad != '0') {
                        this.isAd = true
                    } else {
                        this.isAd = false
                    }
                } else {
                    this.isAd = false
                }
            },
            immediate: true
        }
    },
    mounted() {
    },
    methods: {
        // 广告点击事件
        handleClickPrerollEvent(){
            let { href } = this.prerollConfigAdv;
            if(href){
                window.open(src);
            }
        },
        // 图片广告倒计时
        countTimeFun(skipTime){
            if(skipTime <= 0){
                // 开启直播
                this.playerStatus = true;
                clearTimeout(timer);
                return false;
            }
            --skipTime;
            timer = setTimeout(() => {
                this.countTimeFun(skipTime);
            },1000)
        },

        // 暂停回调
        onPlayerPause() {
            this.$refs.myVideoAdv.play()
            //console.log(player)
        },
        // 当前播放位置发生变化时触发。
        onPlayerTimeupdate() {
            let currTime = this.$refs.myVideoAdv.currentTime
            // 广告结束，可以开始直播
            if(currTime >= this.prerollConfigAdv.skipTime){
                this.playerStatus = true;
            }
        }
    }
}
</script>
<style lang="less" scoped>
    .adv-video-container {
        display: block;
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        video {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
        video::-webkit-media-controls-timeline {
            display: none;
        }
    }
    .live-banner{
        position: relative;
    }
    .pc-style{
        /deep/.van-sticky--fixed{
            position: static!important;
        }
    }
    /deep/ .vjs-big-play-button {
        left: 50% !important;
        top: 50% !important;
        transform: translate(-50%, -50%);
    }
</style>