<template>
    <div>
        <div class="m-vod">
            <img ref="myImg" style="width:100%;display:block" :src="live_banner | urlFilter" alt="">
            <div class="content" id="player"></div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    name: 'Mvod',
    data() {
        return {
            vodPlayerJs: 'https://player.polyv.net/script/player.js',
            vid:'',
            sign: '',
            ts_video: '',
            token: ''
        }
    },
    computed: {
        ...mapState(['live_banner', 'userInfo', 'meeting_id'])
    },
    methods: {
        loadPlayerScript(callback) {
            if (!window.polyvPlayer) {
                const myScript = document.createElement('script')
                myScript.setAttribute('src', this.vodPlayerJs)
                myScript.onload = callback
                document.body.appendChild(myScript)
            } else {
                callback()
            }
        },
        loadPlayer() {
            const polyvPlayer = window.polyvPlayer;
            console.log(polyvPlayer)
            this.player = polyvPlayer({
                wrap: '#player',
                width: this.$refs.myImg.width,
                height: this.$refs.myImg.height,
                autoplay: false,
                speed: false, //倍速播放
                audioMode:false, //是否启用音频播放模式
                df: 1,
                vid: this.vid,
                ts: this.ts_video,
                sign:this.sign,
                viewerInfo: {
                    viewerId:  this.userInfo.user_id,
                    viewerName: this.userInfo.RealName,
                    viewerAvatar: ''
                },
                playsafe: (vid, next) => {
                    this.request.get('getVideoToken', {
                        vid: vid,
                        user_name: this.userInfo.RealName,
                        user_id: this.userInfo.user_id
                    }).then((res) => {
                        next(res.data.token)
                    })
                }
            });
        }
    },
    mounted () {
        if (this.$route.query.video_id) {
            this.request.get('getVideoParams', {
                is_applet: 0,
                video_id: this.$route.query.video_id,
                device_type: '1'
            }).then((res) => {
                if (!res.data) {
                    window.location.href = `https://medcon.sciconf.cn/cn/reg/index/${this.meeting_id}`
                    return false
                }
                const { data } = res
                this.vid = data.vid
                this.sign = data.sign
                this.ts_video = data.ts_video
                this.loadPlayerScript(this.loadPlayer)
            })
        }
    },
}
</script>
<style lang="less" scoped>
.m-vod {
    position: relative;
    width: 100%;
    display: block;
    .content {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 66;
    }
}
</style>