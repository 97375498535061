<template>
    <div class="tab-vertical">
        <ul class="left-tab">
            <li :class="active == index ? 'table-item active' : 'table-item'" v-for="(item, index) in dateList" :key="index" @click="changeDate(item, index)">{{dateTransition(item)}}</li>
        </ul>
        <ul class="live-content">
            <li class="__tab-info" v-for="(item, index) in dataList" :key="index" v-show="index == active">
                <div class="live-item" v-for="(subItem, subIndex1) in item" :key="subIndex1" @click="toLiveRoom(subItem.id, index)">
                    <div class="pic">
                        <img v-if="subItem.live_banner" :src="subItem.live_banner | urlFilter" alt="">
                        <img v-else src="https://files.sciconf.cn/asset/online-meeting/images/live-default-banner.jpg" alt="">
                    </div>
                    <div class="roomMes">
                        <div class="name">{{ subItem[$i18n.locale == 'cn' ? 'hall_name' : 'hall_name_en'] }}</div>
                        <div class="status-content">
                            <div class="time">{{ starTtime(index, subItem) }}</div>
                            <div class="state">
                                <div class="note" v-if="subItem.live_status == 'live'">
                                    <div class="one"></div>
                                    <div class="two"></div>
                                    <div class="three"></div>
                                    <div class="four"></div>
                                </div>
                                <div :class="styleStatus(subItem)">{{setStatusText(subItem)}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import moment from 'moment'
export default {
    props: {
        liveList: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            active: 0,
            imgUrl: 'https://files.sciconf.cn/',
            dateList: [],
            dataList: []
        }
    },
    computed: {
        styleStatus () {
            return function (item) {
                if (item.live_status == 'end') {
                    if ((moment(item.live_start_time).valueOf() < moment(new Date()).valueOf()) || item.live_start_time == '') {
                        if (item.is_open_playback == '1') {
                            return 'status playback'
                        } else {
                            return 'status end'
                        }
                    } else if (moment(item.live_start_time).valueOf() > moment(new Date().valueOf())) {
                        return 'status noStarted'
                    }
                } else if (item.live_status == 'live') {
                    return 'status live'
                }
            }
        },
        setStatusText () {
            return function (item) {
                if (item.live_status == 'end') {
                    if ((moment(item.live_start_time).valueOf() < moment(new Date()).valueOf()) || item.live_start_time == '') {
                        if (item.is_open_playback == '1') {
                            return this.$t('liveRoom.playback')
                        } else {
                            return this.$t('liveRoom.lived')
                        }
                    } else if (moment(item.live_start_time).valueOf() > moment(new Date().valueOf())) {
                        return `${moment(item.live_start_time).format('HH:mm')}${this.$t('liveRoom.beginText')}`
                    }
                    return moment(item.live_start_time).valueOf()
                } else if (item.live_status == 'live') {
                    return this.$t('liveRoom.living')
                }
            }
        },
        starTtime () {
            return function (index, subItem) {
                if (subItem.live_start_time) {
                    return moment(subItem.live_start_time).format('YYYY年MM月DD日 HH:mm')
                } else if (subItem.start_time && `${index}` == moment(subItem.start_time).format('YYYY-MM-DD')) {
                    return moment(subItem.start_time).format('YYYY年MM月DD日 HH:mm')
                } else {
                    return ''
                }
            }
        },
         // 日期转换
        dateTransition () {
            return function (index) {
                if (this.$i18n.locale == 'cn') {
                    return index.slice(5)
                } else {
                    return `${this.$t(`month.${index.slice(5, 7)}`)}${index.slice(8)},${index.slice(0, 4)}`
                }
            }
        }
    },
    methods: {
        changeDate (item, index) {
            console.log(item)
            this.$store.commit('setLiveTabDate', Object.keys(this.liveList)[index])
            this.active = index
        },
        liveListHandle () {
            this.dateList = Object.keys(this.liveList)
            this.dataList = Object.values(this.liveList)
        },
        toLiveRoom (id, date) {
            setTimeout(() => {
                window.location.href = `${window.location.href.substr(0, window.location.href.indexOf('#'))}#/${id}?c_date=${date}&lang=${this.$i18n.locale}&time=${new Date().getTime()}`
                window.location.reload()
            }, 200);
        }
    },
    created () {
        this.liveListHandle()
        const currentDate = moment(new Date()).format('YYYY-MM-DD')
        if (this.$route.query.c_date) {
            this.dateList.forEach((item, index) => {
                if (item == this.$route.query.c_date) {
                    this.active = index
                }
            })
        } else {
            this.dateList.forEach((item, index) => {
                if (item == currentDate) {
                    this.active = index
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
@keyframes yuying1 {
    0% {
        height: 0%;
    }

    20% {
        height: 50%;
    }

    50% {
        height: 100%;
    }

    80% {
        height: 50%;
    }

    100% {
        height: 0%;
    }
}
.tab-vertical {
    width: 100%;
    height: 100%;
    display: flex;
    .left-tab {
        width: 4rem;
        height: 100%;
        .table-item {
            background-color: var(--liveListBackgroundColor);
            color: var(--liveActiveListFontColor);
            width: 100%;
            height: 2.5rem;
            line-height: 2.5rem;
            text-align: center;
            font-size: 0.62rem;
            cursor: pointer;
            &:hover {
                background: var(--liveActiveListBackgroundColor);
                color: var(--liveActiveListFontActiveColor);
                font-weight: bold;
            }
            &.active {
                background: var(--liveActiveListBackgroundColor);
                color: var(--liveActiveListFontActiveColor);
                font-weight: bold;
            }
        }
    }
    .live-content {
        width: calc(100% - 2rem);
        .__tab-info {
            padding: 0.5rem 0.2rem;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            .live-item  {
                display: flex;
                width: 48%;
                height: 3.22rem;
                margin-left: 0.32rem;
                margin-bottom: 0.36rem;
                cursor: pointer;
                position: relative;
                .pic {
                    width: 5.69rem;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center center;
                    }
                }
                .roomMes {
                    width: calc(100% - 5.69rem);
                    height: 100%;
                    padding-left: 0.5rem;
                    box-sizing: border-box;
                    .name {
                        font-size: 0.6rem;
                        height: 2.1rem;
                        color: var(--fontColor);
                        overflow: hidden;
                    }
                    .status-content {
                        position: relative;
                        height: 1.5rem;
                        .time {
                            font-size: 0.54rem;
                            color: var(--fontColor1);
                        }
                        .note {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 1rem;
                            > div {
                                width: 0.1rem;
                                margin-left: 0.1rem;
                                background: #1cb871;
                                height: 0.5rem;
                                vertical-align: middle;
                                display: inline-block;
                            }
                            .one {
                                animation: yuying1 1s infinite 0.1s;
                                -webkit-animation: yuying1 1s infinite 0.1s;
                            }
                            .two {
                                animation: yuying1 0.7s infinite 0.2s;
                                -webkit-animation: yuying1 0.7s infinite 0.2s;

                            }
                            .three  {
                            animation: yuying1 0.7s infinite 0.3s;
                                -webkit-animation: yuying1 0.7s infinite 0.3s; 
                            }
                            .four {
                                animation: yuying1 0.7s infinite 0.4s;
                                -webkit-animation: yuying1 0.7s infinite 0.4s;
                            }
                        }
                        .state {
                            position: absolute;
                            right: 1rem;
                            bottom: 0.44rem;
                            font-size: 0.54rem;
                            padding: 0 0.5rem;
                            border-radius: 1rem;
                            height: 1rem;
                            line-height: 1rem;
                            display: flex;
                            align-content: center;
                            justify-content: center;
                            .status {
                                margin-left: 0.2rem;
                                &.end {
                                    color: #769AB5;
                                }
                                &.playback {
                                    color: #769AB5;
                                    border: 1px solid #769AB5;
                                    border-radius: 20px;
                                    padding: 0 0.3rem;
                                    box-sizing: border-box;
                                }
                                &.live {
                                    color: #1cb871;
                                }
                                &.noStarted {
                                    color: #F37242;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// 移动端
@media screen and (max-width: 980px) {
    .tab-vertical {
        max-height: 100%;
        .live-content {
            .__tab-info {
            .live-item {
                width: 100% !important;
                .pic {
                    width: 5rem;
                }
                .roomMes {
                    padding-left: 0.4rem;
                    .name {
                        height: 1.1rem;
                    }
                    .status-content {
                        .time {
                            font-size: 0.46rem;
                        }
                        .state {
                            left: 0 !important;
                            bottom: -0.5rem;
                            height: auto;
                            line-height: 0.7rem;
                            padding: 0;
                            text-align: center;
                            justify-content: left;
                            align-items: center;
                            .status {
                                margin-left: 0.2rem;
                            }
                        }
                    }
                }
                }
            }
        }
    }
}
</style>