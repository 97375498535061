<template>
    <div class="interpret">
        <!-- 按钮模式 -->
        <div class="right-content" v-if="liveDetail.is_tc == '1' && Object.keys(liveDetail.channel_button).length <= 3">
            <div class="cn item" v-for="(item, index) in liveDetail.channel_button" :key="index" @click="clickHandle(item.link)">
                <div class="national">
                    <img :src="item.image_url" alt="">
                </div>
                <div v-if="item.button_name == '中文'">{{item.button_name }}</div>
                <div v-if="item.button_name == 'English'">EN</div>
            </div>
        </div>
        <!-- 按钮模式 下拉 -->
        <div class="btn-select-model" 
            v-if="liveDetail.is_tc == '1' && Object.keys(liveDetail.channel_button).length >= 3"
            :style="isUnfold ? 'border:1px solid #3d65e8;' : 'height:1.2rem;overflow:hidden;'"
            @click="unfoldHandle">
            <div class="btn-text">
                <span>同传</span>
                <span class="iconfont icon-xialajiantouxiao icon" :style="isUnfold ? 'transform: rotate(180deg);' : ''"></span>
            </div>
            <div class="btn-select" v-for="(item, index) in liveDetail.channel_button" :key="index" @click="clickHandle(item.link)">
                <img :src="item.image_url" alt="">
                <div v-if="item.button_name == '中文'">{{item.button_name }}</div>
                <div v-if="item.button_name == 'English'">EN</div>
            </div>
        </div>
        <!-- 列表模式 -->
        <div v-if="liveDetail.is_tc == '2' || liveDetail.is_tc == '3'" :class="liveDetail.is_tc == '2' ? 'scroll-mode' : 'scroll-mode3'">
            <ul>
                <li v-for="(item, index) in liveDetail.channel_button" :key="index" @click="clickHandle(item.link)">
                    <img class="bg-img" src="https://files.sciconf.cn/asset/online-meeting/images/live-default-banner.jpg" alt="">
                    <div class="pic-bg">
                        <div class="topic">
                            <img :src="item.image_url" alt="">
                            <div v-if="item.button_name == '中文'">{{item.button_name }}</div>
                            <div v-if="item.button_name == 'English'">EN</div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    inheritAttrs: false,
    data() {
        return {
            isUnfold: false
        }
    },
    computed: {
        ...mapState(['liveDetail'])
    },
    methods: {
        unfoldHandle () {
            this.isUnfold = !this.isUnfold
        },
        clickHandle (link) {
            if (link) {
                window.location.href = `${window.location.href.substr(0, window.location.href.indexOf('#'))}#/${link}`
            }
            this.$router.go(0)
        }
    },
    mounted () {
    },
    beforeDestroy () {
    }
}
</script>
<style lang="less" scoped>
.interpret {
    width: 100%;
    height: 100%;
    background-color: var(--backgroundColor);
    .right-content {
        display: flex;
        align-items: center;
        .item {
            display: flex;
            align-content: center;
            justify-content: center;
            box-sizing: border-box;
            font-size: 0.38rem;
            margin-left: 0.17rem;
            background: var(--bodyColor);
            color: var(--fontColor1);
            border-radius: 5rem;
            padding: 0.23rem 0.36rem;
            cursor: pointer;
            align-items: center;
            .national {
                width: 0.45rem;
                height: 0.45rem;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 0.17rem;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .btn-select-model {
        border: 1px solid #dde3ef;
        border-radius: 20px;
        position: absolute;
        font-size: 0.54rem;
        text-align: left;
        line-height: 1.2rem;
        right: 0;
        z-index: 6;
        background: #FFFFFF;
        cursor: pointer;
        .btn-text {
            width: 4rem;
            height: 1.2rem;
            padding-left: 0.3rem;
            box-sizing: border-box;
            color: #919191;
            display: flex;
            align-items: center;
            .icon {
                margin-left: 1.6rem;
            }
        }
        .btn-select {
            width: 4rem;
            height: 1.2rem;
            display: flex;
            align-items: center;
            padding-left: 0.3rem;
            box-sizing: border-box;
            overflow: hidden;
            white-space: nowrap;
            img {
                width: 0.5rem;
                height: 0.5rem;
                margin-right: 0.2rem;
            }
        }
    }
    .scroll-mode, .scroll-mode3 {
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        &::-webkit-scrollbar-track-piece {
            background: rgba(0, 0, 0, 0.1);
            -webkit-border-radius: 6px;
        }
        ul {
            width: auto;
            display: flex;
            li {
                position: relative;
                flex: 0 0 auto;
                width: 3.63rem;
                margin-left: 0.3rem;
                .bg-img {
                    width: 100%;
                }
                .pic-bg {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: url('https://files.sciconf.cn/asset/online/images/picscrollBg.png') center bottom no-repeat;
                    .topic {
                        position: absolute;
                        bottom: 0.1rem;
                        width: 100%;
                        left: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 0.5rem;
                            height: 0.5rem;
                            margin-right: 0.3rem;
                        }
                        span {
                            color: #FFFFFF;
                            font-size: 0.48rem;
                        }
                    }
                }
            }
        }
    }
    .scroll-mode3 {
        overflow: hidden;
        ul {
            flex-wrap: wrap;
            li {
                margin-top: 0.3rem;
            }
        }
    }
}
</style>