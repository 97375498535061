<!-- 大会日程 -->
<template>
    <div class="schedule">
        <schedule-item v-for="(item, index) in schduleList" :key="index" :scheduleItem="item">{{index}}</schedule-item>
         <!-- 打赏金额弹窗 -->
        <van-popup overlay-class='overlayStyle' v-model="givePopup" position="bottom" :overlay="true">
            <div class="figure-content">
                <div class="figure-close iconfont icon-guanbi1" @click="givePopup = false"></div>
                <div class="figure-title">选择金额</div>
                <div class="figure-box">
                    <div class="figure-item" v-for="(item, index) in moneyList" :key="index" @click="selectFigure(index, item.figure)">
                        <div :class="figureIndex == index ? 'active' : ''">{{item.title}}</div>
                    </div>
                    <div class="figure-item" v-if="!isInput" @click="isInput = true; figureIndex = null; figure = null">
                        <div>自定义金额</div>
                    </div>
                    <input v-if="isInput" type="number" placeholder="请输入金额" v-model="figure">
                </div>
                <div class="wechatPlay" @click="wechatPlayHandle"></div>
            </div>
        </van-popup>
        <!-- 评论 -->
        <div v-if="showRemark" class="__char-shadow" @click.self="showRemark = false">
            <div class="__char-content">
                <div class="__char-content-wrap">
                    <!-- 文本输入框 -->
                    <div class="__char-text" ref="charElem" @input="handleInputEvent" contenteditable="true"></div>
                    <!-- 表情与提交按钮 -->
                    <div class="__char-edit flex y-center">
                        <i @click="showEmotion = !showEmotion" class="iconfont icon-biaoqing1" :class="[ showEmotion ? 'icon-icon' : 'icon-biaoqing1']"></i>
                        <a @click="handleSendEvent" class="__char-edit-btn" href="javascript:;">{{$t('message.sendBtn')}}</a>
                    </div>
                </div>
                <!-- 表情弹框 -->
                <div v-if="showEmotion" class="__char-facebox">
                    <a class="__char-emotion" @click="handleEmotionClickEvent(item.title)" v-for="(item,index) in emotionslist" :key="index" :style="{backgroundPosition:(-48*index)+'px 0px'}" href="javascript:;"></a>
                </div>
            </div>
        </div>
        <!-- <div v-for="(item, index) in schduleList" :key="index">{{index}}</div> -->
    </div>
</template>
<script>
import scheduleItem from './schedule-item.vue'
import { mapState } from 'vuex'
import emotionslist from '@/utils/emotions.js'
import eventBus from '@/utils/eventBus'
export default {
    props: {
        programData: {
            type: Array,
            default: () => []
        }
    },
    components: {
        scheduleItem
    },
    data() {
        return {
            schduleList: [],
            live_id: '',
            givePopup: false, // 打赏金额弹窗
            isInput: false,
            showRemark: false,
            showEmotion: false,
            // 输入的内容
            charMessage: '',
            emotionslist,
            pdy_id: '',
            moneyList: [
                {
                    title: '2￥',
                    figure: 2
                },
                {
                    title: '5￥',
                    figure: 5
                },
                {
                    title: '10￥',
                    figure: 10
                },
                {
                    title: '50￥',
                    figure: 50
                },
                {
                    title: '100￥',
                    figure: 100
                },
                {
                    title: '200￥',
                    figure: 200
                },
            ],
            figure: 2, // 打赏金额
            figureIndex: 0 // 金额列表下标
        }
    },
    computed: {
        ...mapState(['liveTabDate', 'liveDetail', 'live_config', 'live_user_info'])
    },
    watch: {
        liveTabDate (newval, oldval) {
            this.getLivesProgramList()
        }
    },
    methods: {
        // 微信支付
        wechatPlayHandle () {
            const fix_amountTest = /^(([1-9]\d*)|\d)(\.\d{1,2})?$/;
            if (fix_amountTest.test(this.figure) == false) {
                this.$toast('金额必须为数字z')
                return
            }
            if (parseInt(this.figure) < 2) {
                this.$toast('金额最低2元')
                return
            }
            this.request.post('getOrderDetail', {
                live_id: this.$route.params.id,
                openid: this.$store.state.openid,
                to_user_id: this.pdy_id,
                type_id: this.pdy_id,
                type: 2,
                money: this.figure
            }).then((res) => {
                if (res.code == 100000) {
                    this.givePopup = false
                    const { order_no, jsApiParameters } = res.data
                    console.log(JSON.parse(jsApiParameters))
                    WeixinJSBridge.invoke('getBrandWCPayRequest', JSON.parse(jsApiParameters), (wxres) => {
                        if (wxres.err_msg == "get_brand_wcpay_request:ok") { // 支付成功
                            this.request.post('wxPayUser', {
                                mark: order_no,
                                to_user_id: this.pdy_id,
                                type_id: this.type_id,
                                type: 2
                            })
                        } else if (wxres.err_msg == "get_brand_wcpay_request:cancel") {
                            this.$toast('用户取消支付')
                        } else {
                            alert(res.err_msg);
                            alert("打赏失败!");
                        }
                    })
                } else {
                    this.$toast(res.message)
                }
            })
        },
        // 选择金额
        selectFigure (index, value) {
            this.figureIndex = index
            this.figure = value
            this.isInput = false
        },
        //  获取日程列表
        getLivesProgramList () {
            if (this.programData.length > 0) {
                this.schduleList = this.programData
                return
            }
            this.request.get('getLivesProgram', {
                live_id: this.live_id,
                lang: this.$i18n.locale,
                date: this.liveTabDate,
                program_show: this.$store.state.liveDetail.program_show
            }).then((res) => {
                this.schduleList = res.data
            })
        },
        // 数据绑定
        handleInputEvent(e) {
            this.showEmotion = false;
            this.charMessage = e.target.innerHTML;
        },
        //表情文字 替换 表情图片
        replace_em(str) {
            var emojiTxtArr = str.match(/[^\[]+(?=\])/g);
            str = str.replace(/<\/div>/g, "");
            str = str.replace(/<br>/g, "");
            str = str.replace(/<div>/g, "");
            if (emojiTxtArr) {
                emojiTxtArr.forEach(function (ele,idx) {
                    emotionslist.forEach(function (value) {
                        let title = value.title.replace('[','').replace(']','');
                        if (ele == title) {
                            str = str.replace('[' + ele + ']', '<img style="width:1rem;position:relative;top:0.2rem;" src = "' + value.url + '" alt= "' + ele + '" >');
                        }
                    })
                })
            }
            return str;
        },
        // 表情图片 转化 表情文字
        replace_img(str) {
            var emojiImgArr = str.match(/<\s*img\b.*?(?:\>|\/>)/g);
            if (emojiImgArr) {
                emojiImgArr.forEach(function (ele,idx) {
                    var imgAlt = ele.match(/[\u4e00-\u9fa5]/g);
                    imgAlt = imgAlt.join('').split(',');
                    str = str.replace(ele, '[' + imgAlt + ']');
                })
            }
            return str;
        },
        // 点击表情
        handleEmotionClickEvent(title) {
            let replace_title = this.replace_em(title);
            this.charMessage = this.charMessage + replace_title;
            this.$refs.charElem.innerHTML = this.charMessage;
        },
         // 发送评论
        handleSendEvent () {
            // 触发发送事件
            let content = this.replace_img(this.charMessage);
            if (content.replace(/&nbsp;/g, '') != '') {
                this.scheduleSendMessage(content)
            }
            //  恢复默认状态
            this.showEmotion = false;
            this.showRemark = false;
            this.$refs.charElem.innerHTML = '';
            this.charMessage = '';
            
        },
        // 发送群聊消息
        scheduleSendMessage(msg, type = 1) {
            const user_info = { ...(this.live_user_info || {}) };
            console.log(user_info)
           let requestArr = [this.request.post('addComment', { type: 4, type_id: 0, live_id: this.$route.params.id },{hideModal:true,hideLoading:true})];
            // is_message不存在 或者 值为1 时开启留言本地收集
            const { is_message } = this.live_config || 1;
            if (is_message == 1) {
                requestArr.push(this.request.post('onlineMessage',
                    { live_id: this.$route.params.id,
                    type: 1, 
                    content: msg, 
                    to_user_id: this.pdy_id,
                    user_id: user_info.userId,
                    user_name: user_info.userName }, 
                    { hideModal:true, hideLoading:true })
                );
            }
            Promise.all(requestArr).then(([res1, res2]) => {
                if (res1.code == '2000' || res2.code == '2000') {
                    this.$toast('您的留言信息中包含严禁词')
                } else {
                    this.$toast('评论成功')
                    let commentItem = {
                        avatar: user_info.avatar || 'https://files.sciconf.cn/asset/mobile/speaker/images/person-default.png',
                        create_time: this.dayjs().format('YYYY-MM-DD HH:mm:ss'),
                        user_name: user_info.userName,
                        real_name: user_info.userName,
                        content: msg
                    }
                    eventBus.$emit('getScheduleMessageInfo', commentItem)
                }
             }).catch(err => { })
        },
    },
    created () {
        this.live_id = this.$route.params.id;
        this.getLivesProgramList()
    }
}
</script>
<style lang="less" scoped>
.overlayStyle {
    background: rgba(0, 0, 0, 0.1);
}
/deep/.van-popup {
    width: 100%;
    background: none;
    .figure-content {
        width: 100%;
        margin: 0 auto;
        background: url('../../assets/images/gift-bg2.png') no-repeat;
        background-size: 100% 100%;
        object-fit: cover;
        height: 14rem;
        .figure-close {
            text-align: right;
            position: absolute;
            right: 1.5rem;
            top: 1.5rem;
        }
        .figure-title {
            font-size: 0.56rem;
            text-align: center;
            height: 3.5rem;
            line-height: 4rem;
        }
        .wechatPlay {
            width: 100%;
            height: 2rem;
        }
        .figure-box {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            padding: 0 0.58rem;
            box-sizing: border-box;
            .figure-item {
                width: 4.5rem;
                height: 1.2rem;
                margin-bottom: 1rem;
                div {
                    width: 4rem;
                    height: 1.2rem;
                    line-height: 1.2rem;
                    border-radius: 20px;
                    border: 1px solid #E6E6E6;
                    text-align: center;
                    margin-bottom: 1rem;
                    font-size: 0.56rem;
                    margin: 0 auto;
                    &.active {
                        background: #0078ff;
                        color: #FFFFFF;
                    }
                }
            }
            input {
                width: 8rem;
                height: 1.2rem;
                border-radius: 20px;
                border: 1px solid #E6E6E6;
                font-size: 0.56rem;
                padding-left: 0.4rem;
                box-sizing: border-box;
                margin-bottom: 1rem;
            }
        }
    }
}
.__char-shadow {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    .__char-content {
        position: absolute;
        // left: 50%;
        // transform: translateX(-50%);
        // width: @container;
        left: 0;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        background: var(--backgroundColor);
        &-wrap {
            padding: 0.5rem;
            .__char-text {
                resize: none;
                width: 100%;
                box-sizing: border-box;
                height: 3rem;
                border-radius: 0.2rem;
                background-color: var(--bodyColor);
                color: var(--fontColor);
                border: none;
                padding: 0.5rem;
                overflow: auto;
                font-size: 0.6rem;
                outline: 0px solid transparent;
                border: 1px solid rgba(255,255,255,0.3) !important;
                &:focus {
                    border: 1px solid #fff !important;
                }
            }
            .__char-edit {
                margin-top: 0.5rem;
                .iconfont {
                    font-size: 1.1rem;
                    cursor: pointer;
                    padding: 0.2rem;
                    color: var(--fontColor1);
                }
                &-btn {
                    border: 2px solid var(--tabActiveColorLine);
                    font-size: 0.6rem;
                    text-align: center;
                    padding: 0.3rem 0.7rem;
                    line-height: 1;
                    border-radius: 0.2rem;
                    background-color: var(--tabActiveColorLine);
                    color: #FFFFFF;
                    cursor: pointer;
                }
            }
        }
        .__char-facebox {
            max-height: 250px;
            overflow: auto;
            font-size: 0;
            padding: 0 0.3rem;
            box-shadow: 0 -1px 0.2rem rgba(0, 0, 0, 0.1);
        }
        .__char-emotion {
            width: 48px;
            height: 48px;
            transform: scale(0.55);
            display: inline-block;
            vertical-align: sub;
            background: url(https://files.sciconf.cn/asset/online/images/all.png)
                0 0 no-repeat;
            cursor: pointer;
        }
    }
}
</style>