<template>
    <div></div>
</template>
<script>

import AgoraRTM from '@/lib/agora-rtm-sdk-1.5.0';
import { throttle } from '@/utils/tools';

export default {
    props: {
        liveNo: {
            type: String,
            value: ''
        },
        agoraAppid: {
            type: String,
            value: ''
        }
    },
    data() {
        return {

        }
    },
    /**
 * 组件的方法列表
 */
    methods: {
        // 开始AgoraRTM各种监听
        handleAgoraRTMevent() {

            /* ====================获取频道成员列表===================== */
            this.handleChannelMemberCount();
            this.agoraChannel.on('MemberJoined', this.handleChannelMemberCount.bind(this))
            this.agoraChannel.on('MemberLeft', this.handleChannelMemberCount.bind(this))
            /* ====================获取频道成员列表===================== */

            /* ===================聊天--广播消息===================== */
            this.agoraChannel.on('ChannelMessage', this.handleAgoraChannelMessage.bind(this))
            /* ===================聊天--广播消息===================== */

            this.$emit('agoraLoad', this.liveNo)
        },
        // 实时在线人数统计
        handleChannelMemberCount: throttle(function () {
            this.agoraChannel.getMembers()
                .then(res => {
                    this.$emit('memberCount', { data: res.length })
                })
                .catch(err => {
                    console.log(err)
                })
        }, 1000, {
            // 在节流开始前调用回调函数
            leading: false,
            // 在节流结束后调用回调函数
            trailing: true
        }),
        // 发送群聊消息
        handleAgoraChannelSendMessage(msg, type = 1) {
            const content_send = {
                EVENT: type == 1 ? 'SPEAK' : 'S_QUESTION', // 聊天SPEAK   提问S_QUESTION
                values: [msg], //发言内容
                roomId: this.liveNo, //当前房间号
                user: { nick: this.$store.state.live_user_info.userName, pic: this.$store.state.live_user_info.avatar, userId: this.$store.state.live_user_info.userId, time: new Date().getTime(), msg, scroll: 1 }
            }
            this.$emit('myMsg', content_send.user)
        },
        sendMessageHandle (msg, type = 1) {
            const content_send = {
                EVENT: type == 1 ? 'SPEAK' : 'S_QUESTION', // 聊天SPEAK   提问S_QUESTION
                values: [msg], //发言内容
                roomId: this.liveNo, //当前房间号
                user: { nick: this.$store.state.live_user_info.userName, pic: this.$store.state.live_user_info.avatar, userId: this.$store.state.live_user_info.userId, time: new Date().getTime(), msg, scroll: 1 }
            }
            this.agoraChannel.sendMessage({ text: JSON.stringify(content_send) })
            .then(res => {
                // 你的代码：频道消息发送成功处理逻辑。
                this.$toast('信息发送成功');
                console.log('发送成功')
            }).catch(err => {
                this.$toast('信息发送失败');
            })
        },
        // 接收群聊消息
        handleAgoraChannelMessage(res) {
            this.$emit('channelMessage', res)
        }
    },

    mounted() {
        const { userId } = this.$store.state.live_user_info
        this.$emit('agoraLoad', this.liveNo)
        if (!userId) return;
        const { agoraAppid, liveNo } = this;
        this.agoraClient = AgoraRTM.createInstance(agoraAppid);
        // 开始登录
        try {
            this.agoraClient.login({ uid:String(userId) }).then(() => {
                // 登录成功，创建RTM 频道
                this.agoraChannel = this.agoraClient.createChannel(this.liveNo)
                this.agoraChannel.join().then(() => {
                    // 加入频道成功的处理逻辑
                    this.handleAgoraRTMevent()
                }).catch(_ => {
                    // 加入频道失败的处理逻辑
                    console.log('加入频道失败的处理逻辑')
                });
            }).catch(_ => {
                console.log('登录失败 userId:' + userId)
            })
        } catch(err){
            console.log('加入频道出错', 'err')
        }
    },

    unmounted() {
        // 退出频道
        this.agoraChannel.leave();
        // 退出登录
        this.agoraClient.logout();
    },

}
</script>