<template>
    <div class="vtc-169" style="width:100%;padding-bottom:56.25%;position: relative;">
        <div class="vtc-169-content" style="width:100%;height:100%;position: absolute;left:0;top:0;">
            <div :class="{'fixed-style':isFixed && !isClose}" class="vtc-container">
                <div class="vtc-content">
                    <VTcPlayer
                        :playsinline="true"
                        class="video-player-custom vtp-player"
                        ref="videoPlayer"
                        :options="playerOptions"
                        @load="onLoad"
                        @play="onPlayerPlay"
                        @playing="onPlaying"
                        @pause="onPause"
                        @timeupdate="onPlayerTimeupdate"
                        @error="errorHandle"
                        @waiting="waitingHandle"
                        @loadeddata="loadeddataHandle"
                        @loadedmetadata="loadedmetadataHandle"/>
                </div>
                <a v-if="isFixed && !isClose" @click="isFixed=false;isClose=true;" class="player-close" href="javascript:;">X</a>
                <div class="answer-content" v-if="isAnswer">
                    <div class="answer">
                        <div class="answer-title">请输入正确答案继续观看 {{num1}} + {{num2}} = ?</div>
                        <input type="number" v-model="answerValue">
                        <van-button type="info" @click="submitAnswer">确定</van-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import { mapState } from 'vuex'
import { VTcPlayer } from 'v-tcplayer'
import { throttle } from '@/utils/tools';
// 计费timer
let timer = null;
// 网络错误定时器、
let timerNetwork = null;
export default {
    components: {
        VTcPlayer,
    },
    data() {
        return {
            answerValue: '',
            num1: 0,
            num2: 0,
            interval: 0,
            answerRange: [],
            timer2: null, // 答题倒计时
            // 是否需要答题才能观看
            isAnswer: false,
            // 直播是否卡顿
            isLiveLag:false,
            // 断流次数
            retriesNumber:0,
            // 是否显示小窗
            isFixed: false,
            // 是否关闭
            isClose: false,
            playerOptions: {
                // 播放流
                m3u8: '',
                // 0.0.5增加
                hlsUrl: 'https://lib.baomitu.com/hls.js/0.8.9/hls.min.js',
                // poster 支持传入一个对象，在对象中可以对封面的展现样式（style）和图片地址（src）进行设置。
                // style 支持的样式如下：
                // 	default：居中并且以图片的实际分辨率进行显示。
                // 	stretch：拉伸铺满播放器区域，图片可能会变形。
                // 	cover：优先横向等比拉伸铺满播放器区域，图片某些部分可能无法显示在区域内。 
                poster: {
                    style:"stretch",
                    src:''
                },
                pausePosterEnabled: true, // 暂停时显示封面
                language: this.$i18n.locale == 'cn' ? 'zh-CN' : 'en',
                // 是否自动播放
                // iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
                autoplay: true,
                // 设置视频是否为直播类型，将决定是否渲染时间轴等控件，以及区分点直播的处理逻辑。
                // 默认为 false
                live: true,
                systemFullscreen: true, /** 开启后，在不支持 Fullscreen API 的浏览器环境下，尝试使用浏览器提供的 webkitEnterFullScreen 方法进行全屏，如果支持，将进入系统全屏，控件为系统控件 */
                // default 显示默认控件，none 不显示控件，system 移动端显示系统控件。 （备注：如果需要在移动端使用系统全屏，就需要设置为 system。默认全屏方案是使用 Fullscreen API + 伪全屏的方式，在线示例 ）   示例："system"
                controls: 'default'
            }
        }
    },
    computed: {
        ...mapState(['sdk_params', 'stopConfigAdv', 'liveDetail']),
        //插件节点 用于添加自定义按钮事件
        player() {
            return this.$refs.videoPlayer.vTcPlayer
        },
    },
    watch: {
        // 拉流地址
        sdk_params: {
            handler(newVal) {
                this.playerOptions.m3u8 = newVal.pull_url
            },
            immediate: true,
        }
    },
    methods: {
        // 已经开始播放 调用 play() 方法或者设置了 autoplay 为 true 且生效时触发，这时 paused 属性为 false
        onPlayerPlay($event) {
            console.log($event, 'onplay')
            if (this.liveDetail.is_open_answer == 1) {
                this.answerRange = this.liveDetail.answer_range.split('-')
                this.getNeedAnswer()
            }
        },
        // 因缓冲而暂停或停止后恢复播放时触发，paused 属性为 false 。通常用这个事件来标记视频真正播放，play 事件只是开始播放，画面并没有开始渲染
        onPlaying ($event) {
            console.log($event)
        },
        // 暂停时触发
        onPause () {
            console.log('onPause')
        },
        onLoad () {
            console.log('load')
        },
        // 当前帧的数据已加载，但没有足够的数据来播放视频的下一帧时，触发该事件
        loadeddataHandle ($event) {
            console.log($event, 'loadeddataHandle')
        },
        // 已加载视频的 metadata。
        loadedmetadataHandle ($event) {
            !timer && this.updateStudyLog();
            // this.player.mute(true)
            this.player.play()
        },
        submitAnswer () {
            if ((Number(this.answerValue) - Number(this.num1)) == this.num2) {
                this.player.play()
                this.isAnswer = false
                this.answerValue = ''
            }
        },
        // 获取随机数
        getRandomNum (min, max) {
            if ((max - min) <= 0) {
                return Number(max)
            } else {
                return Math.floor(Math.random() * (max - min))
            }
        },
        // 是否需要答题才能继续观看
        getNeedAnswer () {
            this.request.get('getNeedAnswer', { live_id: this.$route.params.id }).then((res) => {
                if (res.data == 1) { // 需要答题
                    clearInterval(this.timer2)
                    let min = Number(this.answerRange[0])
                    let max = Number(this.answerRange[1])
                    this.interval = (this.getRandomNum(min, max) * 60) * 1000
                    console.log(this.interval)
                    this.timer2 = setInterval(() => { // 答题
                        this.num1 = this.getRandomNum(0, 10)
                        this.num2 = this.getRandomNum(0, 10)
                        this.isAnswer = true
                        this.player.pause()
                    }, this.interval);
                }
            })
        },
        // 当前播放位置发生变化时触发。
        onPlayerTimeupdate($event) {
            // console.log($event)
            let delayTime = this.player.buffered() - this.player.currentTime();
            if (delayTime <= 0.1) {
                // 网络卡顿或者断流
                this.isLiveLag = true;
            }else{
                this.isLiveLag = false;
                this.retriesNumber = 0;
            }
        },
        waitingHandle ($event) {
            console.log($event, 'waiting')
        },
        errorHandle ($event) {
            console.log('errorHandle', $event)
            // 有错误停止计费
            clearTimeout(timer);
            // 回收废弃定时器
            //clearTimeout(timerNetwork);
            let errData = $event.detail.code;
            let errMsg = '';
            switch (errData) {
                case 0:
                    errMsg = this.$t('player.code0');
                    break;
                case 1:
                    errMsg = this.$t('player.code1');
                    break;
                case 2:
                    errMsg = this.$t('player.code2');
                    break;
                case 3:
                    errMsg = this.$t('player.code3');
                    break;
                case 4:
                    errMsg = this.$t('player.code4');
                    break;
                case 5:
                    errMsg = this.$t('player.code5');
                    break;
            }
            // $event.errorDisplay.contentEl().innerText = errMsg
            timerNetwork = setTimeout(() => {
                // 出错后 重新复制src 并刷新
                // this.player.src(this.sdk_params.pull_url);
                this.player.load(this.sdk_params.pull_url);
                this.player.play();
            }, 2000)
            this.retriesNumber++;
            if (this.retriesNumber >= 5){
                // 当直播流错误5次，则判定直播结束
                if(this.isLiveLag) {
                    // 无直播停止计费
                    clearTimeout(timer);
                    this.$store.commit('setLiveStatus', false)
                }
                
            }
        },
        // 记录直播时长
        updateStudyLog() {
            console.log('updateStudyLog')
            // console.log('正在累计');
            this.request.post('updateStudyLog', { obj_id: (this.sdk_params || {}).params4, type: 1 }, { hideLoading: true })
            // 每隔一分钟请求一次
            timer = setTimeout(() => {
                this.updateStudyLog();
            }, 60 * 1000)
        },

        // 监听滚动条
        listenerScroll() {
            function getScrollTop() {
                var scrollPos;
                if (window.pageYOffset) {
                    scrollPos = window.pageYOffset;                }
                else if (document.compatMode && document.compatMode != 'BackCompat') { scrollPos = document.documentElement.scrollTop; }
                else if (document.body) { scrollPos = document.body.scrollTop; }
                return scrollPos;
            }
            
            let videoPlayerEl = this.$refs.videoPlayer;
            if (!videoPlayerEl) return false;
            let videoPlayerHeight = videoPlayerEl.$el.offsetHeight;
            let offsetNum = 100;
            var that = this;
            window.onscroll = throttle(function (e) {
   
                let scrollTop = getScrollTop();
                if (scrollTop > videoPlayerHeight + offsetNum) {
                    that.isFixed = true;
                } else {
                    that.isFixed = false;
                    // 重置关闭开关
                    that.isClose = false;
                }
            }, 200)
        },
        // 监听窗口变化
        listenerSize() {
            window.onresize = () => {
                let videoPlayerEl = this.$refs.videoPlayer;
                if (!videoPlayerEl) return false;
                // 判断是否全屏
                //if(!videoPlayerEl.player.isFullscreen()){
                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    let videoPlayerHeight = videoPlayerEl.$el.offsetHeight;
                    videoPlayerEl.$el.parentNode.style.height = videoPlayerHeight + 'px';
                }, 100)
                //}
            }
        },
        // 设置暂停广告
        setStopAdv () {
            if (this.stopConfigAdv) {
                if (this.stopConfigAdv.ad_resource == '1') {
                    this.playerOptions.poster.src = this.stopConfigAdv.src
                }
            }
        }
    },
    created() {
        this.setStopAdv()
    },
    mounted() {
        // this.listenerSize();
        this.listenerScroll();
    },
    beforeDestroy () {
        clearInterval(this.timer2)
        this.player.destroy()
    }
}
</script>
<style lang="less" scoped>
.answer-content {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 6;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    .answer {
        width: 14rem;
        background: #ffffff;
        padding: 0.5rem;
        box-sizing: border-box;
        .answer-title {
            font-size: 0.56rem;
            color: #000000;
        }
        input {
            font-size: 0.56rem;
            color: #000000;
            width: 100%;
            height: 1rem;
            margin-top: 0.7rem;
        }
        .van-button {
            height: 1.3rem;
            font-size: 0.56rem;
            margin-top: 0.5rem;
            float: right;
        }
    }
}
.vtc-container {
    display: flex;
    align-items: center;
   .vtc-content {
        position: relative;
        width:100%;
        padding-bottom:56.2%;
        .vtp-player {
            max-width:100%;
            max-height:100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            /deep/ .touchable {
                width: 100% !important;
                height: 100% !important;
                video {
                    width: 100% !important;
                    height: 100% !important;
                }
            }
            /deep/.vcp-player {
                width: 100% !important;
                height: 100% !important;
                video {
                    max-width: 100% !important;
                    max-height: 100% !important;
                }
            }
        }
   }
}

@media screen and (min-width: 550px) {
    .fixed-style {
        position: fixed;
        right: 0px;
        bottom: 0px;
        width: 400px;
        z-index: 99;
    }
    .player-close {
        position: fixed;
        bottom: 225px;
        right: 0;
        z-index: 99;
        padding: 4px 10px;
        line-height: 1;
        background-color: #c74747;
        color: #fff;
    }
}
</style>