<template>
    <div class="live-info">
        <div class="info-title">
            <div class="left-content">
                <span class="iconfont icon-didiandingwei_o"></span>
                {{$i18n.locale == 'cn' ? liveDetail.hall_name : liveDetail.hall_name_en}}
            </div>
            <div class="info-right-content">
                <m-interpret v-if="liveDetail.is_tc == '1'"></m-interpret>
            </div>
        </div>
        <div class="num" v-if="liveDetail.online_people || online_num">
            <div v-if="liveDetail.online_people" class="watch">
                <span class="iconfont icon-yanjing icon"></span>
                <span>{{online_people}}</span>
            </div>
            <template>
                <div class="line" v-if="(liveDetail.online_people || online_num) && live_config.is_open_online_polvy_people == '1'"></div>
                <div class="people" v-if="live_config.is_open_online_polvy_people == '1'">
                    <span class="iconfont icon-gerentouxiang_o icon"></span>
                    <span>{{online_num}}</span>
                </div>
            </template>
        </div>
        <van-notice-bar v-if="liveDetail.notice" :text="liveDetail.notice" left-icon="volume-o" color="#e22b34" />
    </div>
</template>
<script>
import { mapState } from 'vuex'
import mInterpret from '../m-interpret/index.vue'
export default {
    components: {
        mInterpret
    },
    inheritAttrs: false,
    data() {
        return {
            online_num: 0,
            timer: null,
            online_people: 0,
            timer2: null
        }
    },
    computed: {
        ...mapState(['liveDetail', 'live_config', 'liveStatus'])
    },
    watch:{
        liveStatus () {
            // this.getPeopleNumber();
        },
        liveDetail (newVal) {
            this.online_people = newVal.online_people
        },
        live_config (newVal) {
            if (newVal.is_online_add == '1') {
                clearInterval(this.timer)
                this.timer = setInterval(() => {
                    this.getLookNum()
                }, 15000);
            }
        }
    },
    methods: {
        // 切换语言
        changeLang (lang) {
            this.$i18n.locale = lang
        },
        getPeopleNumber() {
            if (!this.liveStatus) {
                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    this.getPeopleNumber();
                }, 10000)
                return false;
            };
            this.request.get('onlinePeople', { live_id: Number(this.$route.params.id) }, { hideLoading: true, hideModal: true }).then((res) => {
                if (res.code == 200 && res.data.online_num) {
                    this.online_num = res.data.online_num;
                }
                let timer = setTimeout(() => {
                    clearTimeout(timer);
                    this.getPeopleNumber();
                }, 10000)
            })
        },
        // 获取观看数量
        getLookNum () {
            this.request.get('getLookNum', { live_id: Number(this.$route.params.id) }, { hideLoading: true, hideModal: true } ).then((res) => {
                let historyNum = this.online_people
                let currentNum = res.data.online_num
                let difference = currentNum - historyNum // 差值
                let count = 0
                if (currentNum != historyNum) {
                    if (difference > 0) { // 自增加
                        if (difference >= 10) {
                            this.timer2 = setInterval(() => {
                                if (count >= 9) {
                                    this.online_people = parseInt(this.online_people) + parseInt((currentNum - this.online_people))
                                    clearInterval(this.timer2)
                                } else {
                                    this.online_people = parseInt(this.online_people) + parseInt(difference / 10)
                                }
                                count = count + 1
                            }, 1000);
                        } else {
                            this.timer2 = setInterval(() => {
                                this.online_people = parseInt(this.online_people) + 1
                                if (this.online_people == currentNum) {
                                    clearInterval(this.timer2)
                                }
                            }, 500);
                        }
                    } else { // 自减
                        if (difference < -10) {
                            this.timer2 = setInterval(() => {
                                if (count >= 9) {
                                    this.online_people = parseInt(this.online_people) - parseInt((currentNum - this.online_people))
                                    clearInterval(this.timer2)
                                } else {
                                    this.online_people = parseInt(this.online_people) - parseInt(difference / 10)
                                }
                                count = count + 1
                            }, 1000);
                        } else {
                            this.timer2 = setInterval(() => {
                                this.online_people = parseInt(this.online_people) - 1
                                if (this.online_people == currentNum) {
                                    clearInterval(this.timer2)
                                }
                            }, 500);
                        }
                    }
                }
            })
        },
    },
    mounted () {
    },
    beforeDestroy () {
        clearInterval(this.timer)
        clearInterval(this.timer2)
    }
}
</script>
<style lang="less" scoped>
.live-info {
    padding: 0.43rem;
    background-color: var(--backgroundColor);
    margin: 0.3rem 0;
    .info-title {
        display: flex;
        align-items: center;
        padding-left: 0.4rem;
        .left-content {
            flex: 1;
            font-size: 0.64rem;
            color: var(--fontColor);
            display: flex;
            align-items: center;
            white-space: nowrap;
            overflow: hidden;
            span {
                font-size: 0.8rem;
                margin-right: 0.21rem;
            }
        }
        .info-right-content {
            margin-left: 1rem;
            position: relative;
        }
    }
    .num {
        display: flex;
        align-content: center;
        margin-top: 0.58rem;
        padding-left: 0.4rem;
        div {
            color: var(--fontColor1);
            display: flex;
            align-items: flex-end;
            span {
                font-size: 0.51rem;
            }
            .icon {
                font-size: 0.7rem;
                margin-right: 0.11rem;
            }
        }
        .line {
            width: 0.04rem;
            background: var(--fontColor1);
            margin: 0 0.47rem;
            height: 0.5rem;
            margin-top: 0.1rem;
        }
    }
    /deep/ .van-notice-bar {
        padding: 0.47rem 0 0.43rem 0.36rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin-top: 0.49rem;
        color: var(--fontColor) !important;
        background: var(--afficheColor);
        height: 1.5rem;
    }
    /deep/ .van-notice-bar__content {
        position: relative !important;
    }
}
</style>