<template>
    <div class="__tab-scroll">
        <van-tabs :swipeable="true" v-model="active" :sticky="false" @change="changeDate">
            <van-tab v-for="(item, index) in liveList" :key="index" :title="dateTransition(index)">
                <div class="__tab-info">
                    <ul>
                        <li :class="['live-item', subItem.id == currentLive ? 'active' : '']" v-for="(subItem, subIndex1) in item" :key="subIndex1" @click="toLiveRoom(subItem.id,index)">
                            <img v-if="subItem.live_banner" :src="subItem.live_banner | urlFilter" alt="">
                            <img v-else src="https://files.sciconf.cn/asset/online-meeting/images/live-default-banner.jpg" alt="">
                            <div>{{ subItem[$i18n.locale == 'cn' ? 'hall_name' : 'hall_name_en'] }}</div>
                            <p></p>
                            <div class="note" v-if="subItem.id == currentLive">
                                <div class="one"></div>
                                <div class="two"></div>
                                <div class="three"></div>
                                <div class="four"></div>
		                    </div>
                        </li>
                    </ul>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>
<script>
import moment from 'moment'
export default {
    props: {
        liveList: {
            type: Object,
            default: {}
        }
    },
    inheritAttrs: false,
    data() {
        return {
            active: 0,
            imgUrl: 'https://files.sciconf.cn/',
            currentLive: ''
        }
    },
    computed: {
        // 日期转换
        dateTransition () {
            return function (index) {
                if (this.$i18n.locale == 'cn') {
                    return index.slice(5)
                } else {
                    return `${this.$t(`month.${index.slice(5, 7)}`)}${index.slice(8)},${index.slice(0, 4)}`
                }
            }
        }
    },
    methods: {
        changeDate (index, title) {
            this.$store.commit('setLiveTabDate', Object.keys(this.liveList)[index])
        },
        toLiveRoom (id, date) {
            setTimeout(() => {
                window.location.href = `${window.location.href.substr(0, window.location.href.indexOf('#'))}#/${id}?c_date=${date}&lang=${this.$i18n.locale}&time=${new Date().getTime()}`
                window.location.reload()
            }, 200);
        }
    },
    mounted () {
        this.currentLive = this.$route.params.id
        const currentDate = moment(new Date()).format('YYYY-MM-DD')
        let num = 0
        if (this.$route.query.c_date) {
            for (let i in this.liveList) {
                num = num + 1
                if (i == this.$route.query.c_date) {
                    if (num > 0) {
                        this.active = num - 1
                    }
                }
            }
        } else {
            for (let i in this.liveList) {
                num = num + 1
                if (i == currentDate) {
                    if (num > 0) {
                        this.active = num - 1
                    }
                }
            }
        }
    }
}
</script>
<style lang="less" scoped>
@keyframes yuying1 {
		0% {
			height: 0%;
		}
 
		20% {
			height: 50%;
		}
 
		50% {
			height: 100%;
		}
 
		80% {
			height: 50%;
		}
 
		100% {
			height: 0%;
		}
	}
.__tab-scroll {
    // 皮肤定制
    /deep/.van-tabs{
        height:100%;
    }
    /deep/.van-tabs__nav{
        background: var(--backgroundColor);
    }
    /deep/.van-tabs__wrap {
        border: none;
        height: 2.5rem;
        max-width: @container;
        margin: 0 auto;
        position: sticky;
        top: 0;
        z-index: 2;
        box-sizing: border-box;
        position: relative;
        position: relative;
        &:before {
            position: absolute;
            content: '';
            height: 1px;
            width: 100%;
            left: 0;
            bottom: 1px;
            background: var(--borderColor);
            z-index: 3;
            opacity: 0.3;
        }
        &:before {
            position: absolute;
            content: '';
            height: 1px;
            width: 100%;
            left: 0;
            bottom: 1px;
            background: var(--borderColor);
            z-index: 3;
            opacity: 0.3;
        }
        .van-tab {
            padding: 0 0.3rem;
            flex: 0 !important;
            .van-tab__text {
                padding: 0.4rem 0.6rem;
                border-radius: 0.2rem;
                font-size: 0.6rem;
                line-height: 1;
                background-color: var(--liveListBackgroundColor);
                color: var(--liveActiveListFontColor);
                min-width: 2rem;
                width: auto;
                text-align: center;
            }
        }
        .van-tab--active {
            .van-tab__text {
                background-color: var(--liveActiveListBackgroundColor);
                color: var(--liveActiveListFontActiveColor);
                font-weight: bold;
                position: relative;
            }
            .van-tab__text::before {
                content: '';
                width: 0;
                height: 0;
                position: absolute;
                bottom: -0.7rem;
                left: 50%;
                transform: translate(-50%, 0);
                border: 0.5rem solid transparent;
                border-top-color: var(--liveActiveListBackgroundColor);
            }
        }
        .van-tabs__line {
            display: none;
        }
    }
    /deep/.van-tabs__content {
    }
    /deep/.van-tab__pane{
        height:100%;
    }
    /deep/.van-tabs__line {
        background-color: var(--liveActiveListBackgroundColor);
    }
    /deep/.van-tab {
        font-size: 0.6rem;
        line-height: 1.2rem;
    }
    /deep/.van-tab--active {
        color: var(--liveActiveListFontColor);
        font-weight: bold;
    }
    .__tab-info {
        padding: 0.5rem 0.2rem;
        width: 100%;
        box-sizing: border-box;
        ul {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            li {
                width: 3.54rem;
                height: 1.98rem;
                margin-left: 0.32rem;
                margin-bottom: 0.36rem;
                cursor: pointer;
                position: relative;
                &.active {
                    &:before {
                        width: 97%;
                        height: 97%;
                        content: '';
                        border: 2px solid #1cb871;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    .note {
                        position: absolute;
                        left: 50%;
                        height: 50%;
                        transform: translate(-50%, -50%);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        > div {
                            width: 0.1rem;
                            margin-left: 0.1rem;
                            background: #1cb871;
                            height: 0.5rem;
                            vertical-align: middle;
                            display: inline-block;
                        }
                        .one {
                            animation: yuying1 1s infinite 0.1s;
		                    -webkit-animation: yuying1 1s infinite 0.1s;
                        }
                        .two {
                            animation: yuying1 0.7s infinite 0.2s;
		                    -webkit-animation: yuying1 0.7s infinite 0.2s;

                        }
                        .three  {
                           animation: yuying1 0.7s infinite 0.3s;
		                    -webkit-animation: yuying1 0.7s infinite 0.3s; 
                        }
                        .four {
                            animation: yuying1 0.7s infinite 0.4s;
		                    -webkit-animation: yuying1 0.7s infinite 0.4s;
                        }
                    }
                }
                >div {
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translate(-50%, 0);
                    font-size: 0.34rem;
                    color: #ffffff;
                    width: 100%;
                    display: block;
                    text-align: center;
                    height: 1rem;
                    z-index: 2;
                    overflow: hidden;
                }
                p {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: url(https://files.sciconf.cn/asset/online/images/picscrollBg.png) center bottom no-repeat;
                    background-size: 100% 100%;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

// 移动端
@media screen and (max-width:550px) {
    .__tab-scroll {
        max-height: 100%;
    }
}
</style>