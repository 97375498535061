<template>
    <div class="container" id="container">
        <!-- 弹窗广告 -->
        <tips-adv class="flex-item"></tips-adv>
        <!-- 直播间内容 -->
        <div class="flex pos-rel">
            <div :style="isShowRightChat ? 'width:75%;' : 'width:100%;'">
                <!-- 直播 -->
                <live-header class="flex-item" v-if="!$route.query.video_id">
                </live-header>
                <!-- 点播 -->
                <m-vod class="flex-item" v-if="$route.query.video_id"></m-vod>
            </div>
            <div class="right-char" v-if="isShowRightChat">
                <right-char-message :title="$store.state.messageInfo[$i18n.locale == 'cn' ? 'title' : 'title_en']"  type="1" @scrollBottom="handleScrollBottomEvent"></right-char-message>
            </div>
            <div class="powerShade" v-if="shadeFlag">
                <div class="text">{{shadeText}}</div>
                <div class="btn" @click="toUrlHandle">
                    {{lookPower == 1 ? $t('loginText') : $t('registerText')}}
                    <span class="iconfont icon-youjiantou"></span>
                </div>
            </div>
        </div>
        <m-caption v-if="liveDetail.is_captions != '0' && liveStatus"></m-caption>
        <live-list :class="isMobile ? '' : 'flex-item'" v-if="isShowLiveList" :liveList="{}"></live-list>
        <live-info></live-info>
        <m-interpret v-if="liveDetail.is_tc == '2' || liveDetail.is_tc == '3'"></m-interpret>
        <!-- tabbar内容 -->
        <live-tab class="flex-item wrapper">
        </live-tab>
        <!-- <m-share v-if="live_config.is_open_share != '0'"></m-share> -->
        <m-share ></m-share>
        <m-answer></m-answer>
        <m-notice></m-notice>
    </div>
</template>
<script>
import { jsonFormat } from '@/utils/jsonFormat'
import { liveTab, liveHeader } from '@/components/live-comps'
import rightCharMessage from '@/components/live-comps/char-message/right-message/right-index.vue'
import liveList from '@/components/live-list/index.vue'
import liveInfo from '@/components/live-info/index.vue'
import tipsAdv from '@/components/tips-adv/index.vue'
import mVod from '@/components/m-vod/index.vue'
import mShare from '@/components/m-share/index.vue'
import mAnswer from '@/components/m-answer/index.vue'
import mNotice from '@/components/m-notice/index.vue'
import mInterpret from '@/components/m-interpret/index.vue'
import mCaption from '@/components/m-caption/index.vue'
import { isWeiXin } from '@/utils/common.js'
import { mapState } from 'vuex'
export default {
    components: {
        liveHeader,
        liveTab,
        rightCharMessage,
        liveList,
        liveInfo,
        tipsAdv,
        mVod,
        mShare,
        mAnswer,
        mNotice,
        mInterpret,
        mCaption
    },
    data () {
        return {
            // 是否加载完成
            isComputed: false,
            isMobile: (() => {
                return window.screen.width < 500 ? true : false;
            })(),
            isShowLiveList: true,
            shadeText: '',
            shadeFlag: false,
            code_url: '',
            lookPower: '', // 权限类型
            isShowRightChat: false
        }
    },
    computed: {
        ...mapState(['liveDetail', 'meeting_id', 'userInfo', 'isPower', 'live_banner', 'isMessage', 'live_config', 'liveStatus']),
    },
    watch: {
        live_config: {
            handler () {
                this.showRightChatHandle()
            },
            immediate: true,
            deep: true
        },
        isMessage () {
            this.showRightChatHandle()
        }
    },
    methods: {
        showRightChatHandle () {
            if (!this.isPower || !this.isMessage || window.screen.width < 980) {
                this.isShowRightChat = false
            } else {
                if (this.$store.state.live_config.display_mode == '1') { // 播放器右侧
                    this.isShowRightChat = true
                } else if (this.$store.state.live_config.display_mode == '2') { // 直播页tab展示
                    this.isShowRightChat = false
                } else {
                    this.isShowRightChat = false
                }
            }
        },
        // 获取微信openid
        getOpenId () {
            const loca = window.location.href
            if (isWeiXin()) {
                if (loca.indexOf('openid') == -1) {
                    window.location.href = `https://www.medmeeting.org/minisite/getopenid?returnurl=${loca.replace(/\?/g,"*").replace(/\&/g,"^").replace(/\#/g,"!")}`
                } else {
                    this.$store.commit('setOpenId', this.$route.query.openid)
                }
            }
        },
        toUrlHandle () {
            if (this.lookPower == 1) { // 平台用户
                window.location.href = `https://www.sciconf.cn/m/unified/login/${this.liveDetail.committee_id}?return_url=${encodeURIComponent(window.location.href)}`
            } else if (this.lookPower == 2 || this.lookPower == 3) { // 2登记观看 3 // 直播间密码
                this.$router.push({
                    name: 'lookPower',
                    params: {
                        id: this.__live_id,
                        lookPowerType: this.lookPower
                    }
                })
            } else if (this.lookPower == 6) { // 6会议注册
                // window.location.href = `https://medcon.sciconf.cn/cn/user/perfect-info/${this.meeting_id}?return_url=https://www.sciconf.cn/m/lives/details/${this.__live_id}?lang=cn&scene=ok`
                window.location.href = `${this.code_url}?return_url=${window.location.href}`
            } else if (this.lookPower == 7) { // 参会并缴费(含免费)
                // window.location.href = `https://mm.sciconf.cn/cn/reg/index/${this.meeting_id}`
                window.location.href = this.code_url
            } else if (this.lookPower == 8) { // 邀请码
                localStorage.setItem('liveBanner', this.live_banner)
                this.$router.push({
                    name: 'lookPower',
                    params: {
                        id: this.__live_id,
                        lookPowerType: this.lookPower
                    }
                })
            } else if (this.lookPower == 9) { // API权限定制类型
            } else if (this.lookPower == 12) { // 指定注册类型观看
                window.location.href = `https://mm.sciconf.cn/cn/reg/index/${this.meeting_id}`
            } else if (this.lookPower == -7) {
                window.location.href = `${this.code_url}?return_url=${window.location.href}`
            }
        },
        // 聊天功能置底
        handleScrollBottomEvent () {
            this.$nextTick(() => {
                // 紧移动端滚动置底
                this.$refs.charMessageBox && (this.$refs.charMessageBox[0].scrollTop = 9999999999)
            })
        },
        // 获取直播信息
        getLiveDetail () {
            let regType = localStorage.getItem(`setRegType-${this.__live_id}`) || ''
            let regid = localStorage.getItem(`setRegId-${this.__live_id}`) || ''
            this.request.get('liveDetail', {
                is_applet:0,
                is_reg: regType == 'reg' ? '1' : '0',
                is_mm: regType == 'password' ? '1' : '0',
                is_invite: regType == 'code' ? '1' : '0',
                is_applet: '0', // 公开免费
                type: 1,
                reg_id: regid,
                live_id: this.__live_id,
                c_date: this.$route.query.c_date ? this.$route.query.c_date : '',
                scene: this.$route.query.scene || 'ok',
                lang: this.$i18n.locale
            }).then((res) => {
                // 需要验证权限
                if (!res.data) {
                    this.$store.commit('setIsPower', false)
                    // 参会注册观看直播回放判断是否登录
                    if (this.$route.query.video_id && res.type == 7) {
                        if (!localStorage.getItem('auth')) {
                            window.location.href = `https://www.sciconf.cn/m/unified/login/${res.data.committee_id}/?return_url=${encodeURIComponent(window.location.href)}`
                        }
                    }
                    let { live_data } = res
                    if (live_data.cnen == '1' && this.$route.query.lang != 'en') {
                        this.$i18n.locale = 'en'
                        window.location.href = `${window.location.href}?lang=en`
                    }
                    // 是否跳转老版直播系统
                    if (live_data.is_jump_live) {
                        window.location.href = live_data.jump_live_url
                    }
                    this.$store.commit('setLiveBanner', live_data.live_banner)
                    this.lookPower = res.type
                    this.code_url = res.code_url
                     // 直播信息
                    this.$store.commit('setLiveDetail',live_data)
                    this.$store.commit('setMeetingId', live_data.meeting_id)
                    // if (res.type == 1) { // 平台用户
                    //     this.shadeText = this.$t('loginTip')
                    // } else if (res.type == 2) { // 登记观看
                    //     this.shadeText = this.$t('loginRecord')
                    // } else if (res.type == 3) { // 直播间密码
                    //     this.shadeText = this.$t('loginPass')
                    // } else if (res.type == 6) { // 会议注册
                    //     this.shadeText = this.$t('loginReg')
                    // } else if (res.type == 7) { //  参会并缴费
                    //     this.shadeText = this.$t('loginPay')
                    // } else if (res.type == 8) { // 邀请码
                    //     this.shadeText = this.$t('loginCode')
                    // } else if (res.type == 9) { // API权限定制类型
                    // } else if (res.type == 12) { // 指定注册类型观看
                    //     this.shadeText = this.$t('loginSpecialReg')
                    // } else if (res.type == -7) {
                    //     this.shadeText = this.$t('loginRegPay')
                    // }
                    this.shadeText = this.$i18n.locale == 'cn' ? live_data.permissions_hint_cn : live_data.permissions_hint_en
                    this.shadeFlag = true
                    // 加载完成
                    this.isComputed = true
                    // 直播配置
                    this.$store.commit('setLiveConfig', live_data.live_config)
                    if (live_data.live_config.meeting_hall == '1' || live_data.live_config.meeting_hall == '2') {
                        this.isShowLiveList = true
                    } else {
                        this.isShowLiveList = false
                    }
                    document.title = live_data.title
                    return
                }
                // 正常获取数据
                if (res.data.cnen == '1' && this.$route.query.lang != 'en') {
                    this.$i18n.locale = 'en'
                    window.location.href = `${window.location.href}?lang=en`
                }
                // 是否跳转老版直播系统
                if (res.data.is_jump_live) {
                    window.location.href = res.data.jump_live_url
                }
                let { live_ad_list, sdk_params, live_status, live_banner, live_config, user_info, meeting_id, aggree_tan_content } = res.data;
                // 获取直播广告列表
                if (live_ad_list && live_ad_list.length) {
                    live_ad_list.forEach((item, index) => {
                        /**
                         * ad_type 1 片头广告 3 暂停广告
                         * ad_resource 1 图片 2 视频 
                         */
                        let { ad_type, ad_resource } = item;
                        let currItem = jsonFormat([item], { src: 'matterurl', href: 'addrurl', ad_resource: 'ad_resource', skipTime: 'timesize' })[0];
                        if (ad_type == 1) {
                            this.$store.commit('setPrerollConfigAdv', currItem)
                        } else {
                            this.$store.commit('setStopConfigAdv', currItem)
                        }
                    })
                }
                this.$store.commit('setMeetingId', meeting_id)
                /**
                 * 1、判断 this.livesDeatils.live_status.open_all_live为true || this.livesDeatils.live_status.open_one_live为true
                 *      .open_all_live表示播放的是三联屏上下双屏的直播
                 *      .open_one_live表示单屏直播
                 *  如果 1 的结果为true
                 *      判断是否单屏  this.livesDeatils.show_type == 1  ||  this.livesDeatils.live_status.open_one_live为true
                 *       判断是否上下双屏ppt在下  this.livesDeatils.show_type == 3
                 *       判断是否是否上下双屏    this.livesDeatils.show_type == 2
                 * 
                 * 
                 *  如果 1 的结果为false  则显示banner或默认图
                 * 
                 * 播放器显示状态show_type
                 *  0：三联屏
                 *  1：全屏
                 *  2：上下双屏
                 *  3：上下双屏在文档下
                 * */
                // 直播状态
                this.$store.commit('setLiveStatus', live_status.open_all_live || live_status.open_one_live)
                // 没有直播时的封面图
                this.$store.commit('setLiveBanner', live_banner)

                // 直播流参数
                // sdk_params.pull_url = 'rtmp://testpull.sciconf.cn/live/t12023?txSecret=72a41d3f1bbb9b981ec6231f02f4f9b9&txTime=6427C98C' || sdk_params.pull_url;
                this.$store.commit('setSdkParams', sdk_params)
                // 直播配置
                
                this.$store.commit('setLiveConfig', live_config)
                this.$store.commit('setTanContent', aggree_tan_content)
                if (live_config.meeting_hall == '1' || live_config.meeting_hall == '2') {
                    this.isShowLiveList = true
                } else {
                    this.isShowLiveList = false
                }
                // 直播返回的用户信息
                this.$store.commit('setLiveUserInfo', user_info)
                // 直播间其他基本信息
                this.$store.commit('setLiveDetail', res.data)
                this.$store.commit('setIsPower', true)
                // 加载完成
                this.isComputed = true;
                if (live_config.is_shang != '0') {
                    this.getOpenId()
                }
                document.title = res.data.title
            }).catch((err) => {
                if (err.code == 401) { // 未登录
                    this.$store.commit('setIsPower', false)
                    this.lookPower = err.type
                    this.shadeFlag = true
                }
                let { live_data } = err;
                if (live_data) {
                    this.$store.commit('setMeetingId', live_data.meeting_id)
                    if (live_data.cnen == '1' && this.$route.query.lang != 'en') {
                        this.$i18n.locale = 'en'
                        window.location.href = `${window.location.href}?lang=en`
                    }
                    this.shadeText = this.$i18n.locale == 'cn' ? live_data.permissions_hint_cn : live_data.permissions_hint_en
                    this.$store.commit('setLiveBanner', live_data.live_banner)
                    // 直播配置
                    this.$store.commit('setLiveConfig', live_data.live_config)
                    // 直播信息
                    this.$store.commit('setLiveDetail',live_data)
                    // // 是否跳转老版直播系统
                    if (live_data.is_jump_live) {
                        window.location.href = live_data.jump_live_url
                    }
                    document.title = live_data.title
                }
                // 加载完成
                this.isComputed = true;
            })
        }
    },
    async created () {
        this.__live_id = this.$route.params.id;
        // 获取直播信息
        await this.getLiveDetail()
    }
}
</script>
<style lang="less" scope>
.wrapper {
    height: 100vh;
}
body {
    background: var(--bodyColor);
}
.char-right{
    width:30%;
    background-color: #fff;
}
.right-char {
    width: 25%;
    position: absolute;
    top: 0;
	right: 0;
	bottom: 0;
}
.powerShade {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 6;
    .text {
        width: 100%;
        font-size: 0.6rem;
        color: #FFFFFF;
        padding: 0 1rem;
        box-sizing: border-box;
        text-align: center;
        line-height: 1.1rem;
    }
    .btn {
        padding: 0.3rem 1rem;
        text-align: center;
        border: 1px solid #FFFFFF;
        border-radius: 20px;
        font-size: 0.6rem;
        color: #FFFFFF;
        margin-top: 1rem;
        cursor: pointer;
    }
}
</style>