<!-- 分享悬浮窗 -->
<template>
    <div>
        <div ref="mShare" class="m-share" :style="shareLeft ? `left:${shareLeft};` : `right:${shareRight}`" v-if="isPut">
            <div class="close-popup iconfont icon-guanbi1" v-if="isMobile" @click="isPut = false"></div>
            <!-- 签到 -->
            <div class="sign" v-if="signConfigData.show_SignIn_button" @click="liveSignHandle">
                <img src="@/assets/images/sign.png" alt="">
                <p>{{$t('float.sign')}}<!-- 签到 --></p>
            </div>
            <!-- 分享 -->
            <div class="share" @click="isMobile == true ? isShare = true : isShare = false">
                <img src="@/assets/images/share.png" alt="">
                <p>{{$t('float.share')}}<!-- 分享 --></p>
                <div class="content">
                    <div class="item first-item">
                        <img :src="appletQrcode" alt="">
                        <p>{{$t('float.miniprogram')}}{{$t('float.watch')}} <!-- 小程序观看 --></p>
                    </div>
                    <div class="item">
                        <div ref="livePageQrcode"></div>
                        <p style="margin-top:0.15rem;">{{$t('float.mpweixin')}}<!-- 微信观看 --></p>
                    </div>
                </div>
            </div>
            <!-- 扫码 -->
            <div class="scan" v-if="!isMobile">
                <img src="@/assets/images/scan.png" alt="">
                <p>{{$t('float.scanQrcode')}}<!-- 扫码 --></p>
                <div class="content">
                    <div class="title">
                        <img class="icon" :src="require('@/assets/images/share-poster.png')" alt="">
                        <div>{{$t('float.scanQrcodePoster')}}<!-- 扫码下方二维码分享海报 --></div>
                        <div ref="posterQrcode"></div>
                    </div>
                    <div class="wechat" @click="friendsShare">
                        <img src="@/assets/images/share-friend.png" alt="">
                        <p>{{$t('float.shareFriend')}}<!-- 分享好友 --></p>
                    </div>
                    <div class="ranking" @click="toRanking">
                        <img src="@/assets/images/share-ranking.png" alt="">
                        <p>{{$t('float.shareRank')}}<!-- 分享排行 --></p>
                    </div>
                </div>
            </div>
            <!-- 小程序 -->
            <div @click="isApplet = true" class="applet" v-if="isMobile">
                <div class="icon iconfont icon-xiaochengxu1"></div>
                <p>{{$t('float.miniprogram')}}<!-- 小程序 --></p>
            </div>
            <!-- 移动端小程序弹窗 -->
            <van-popup v-model="isApplet" position="bottom" :overlay="true" class="applet-popup">
                <img class="content-bg" src="@/assets/images/listbg2.png" alt="">
                <div class="popup-content">
                    <div class="title">{{$t('float.miniProgramTip')}}<!-- 长按前往小程序端进行观看 --></div>
                    <img :src="appletQrcode" alt="">
                    <div class="close iconfont icon-guanbi1" @click="isApplet = false"></div>
                </div>
            </van-popup>
            <!-- 移动端分享弹窗 -->
            <van-popup v-model="isShare" position="bottom" :overlay="true" class="share-popup">
                <div class="share-popup-content">
                    <div class="share-popup-item poseter-item" @click="toPoster">
                        <img src="@/assets/images/share-poster.png" alt="">
                        <p>{{$t('float.sharePoster')}}<!-- sharePoster --></p>
                    </div>
                    <div class="share-popup-item friend-item" @click="friendsShare">
                        <img src="@/assets/images/share-friend.png" alt="">
                        <p>{{$t('float.shareFriend')}}<!-- 分享好友 --></p>
                    </div>
                    <div class="share-popup-item ranking-item" @click="toRanking">
                        <img src="@/assets/images/share-ranking.png" alt="">
                        <p>{{$t('float.shareRank')}}<!-- 分享排行 --></p>
                    </div>
                </div>
            </van-popup>
            <!-- 微信浏览器分享指示 -->
            <van-popup v-model="shareIndicte" position="top" :overlay="true" class="share-indicte">
                <img src="@/assets/images/share-indicte.png" alt="">
            </van-popup>
            <van-popup v-model="signListIsShow" position="center" :overlay="true" class="sign-list">
                <div class="sign-list-title">{{$t('float.liveSign')}}</div>
                <div class="sign-list-content">
                    <ul>
                        <li v-for="(item, index) in signListData" :key="index" :class="item.is_sign ? 'success' : 'error'">
                            <div class="left-content">
                                <div class="date">{{item.date}}</div>
                                <div class="time">{{item.start_solt}}-{{item.end_solt}}</div>
                            </div>
                            <div class="right-content">
                                <span :class="['icon iconfont icon-chenggong1', item.is_sign ? 'success' : 'error']"></span>
                                <span class="text">{{item.is_sign ? $t('float.signed') : $t('float.noSign')}}</span> <!-- '已签到' : '未签到' -->
                            </div>
                        </li>
                    </ul>
                    <div class="close iconfont icon-guanbi1" @click="signListIsShow = false"></div>
                </div>
            </van-popup>
        </div>
        <div class="right iconfont icon-jiantou-zuo" v-if="!isPut && isMobile" @click="isPut = true"></div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { Toast, Dialog  } from 'vant'
import jWeixin from 'weixin-js-sdk'
import QRCode from 'qrcodejs2'
export default {
    data() {
        return {
            isMobile: false,
            isApplet: false,
            isShare: false,
            appletQrcode: '', // 小程序二维码
            posterQrcode: '',// 海报页面二维码
            shareIndicte: false,
            signConfigData: {
                show_SignIn_button: false, // 是否显示签到按钮 true 是 false 否
                sign_type: 1 // 签到类型 1 按时段签到 2 普通签到
            },
            signListIsShow: false,
            signListData: [],
            shareLeft: '',
            shareRight: '',
            isPut: true
        }
    },
    computed: {
        ...mapState(['userInfo', 'meeting_id', 'liveDetail'])
    },
    methods: {
        // 跳转排行榜
        toRanking () {
            window.location.href = `https://www.sciconf.cn/m/lives/live-share-ranking/${this.$route.params.id}/${this.liveDetail.committee_id}`
        },
        // 跳转海报页面
        toPoster () {
            window.location.href = `https://www.sciconf.cn/m/lives/online-share/${this.$route.params.id}?lang=cn`
        },
        // 分享好友
        friendsShare () {
            let url = `${window.location.origin}/#/${this.$route.params.id}?lang=${this.$i18n.locale}&share_code=${this.userInfo.user_id}`
            const ua = navigator.userAgent.toLowerCase();
            const isWeixin = ua.indexOf('micromessenger') != -1 // 判断是否为微信浏览器
            const input = document.createElement('input')
            input.style.position = 'fixed;'
            input.style.left = '-500px'
            input.style.top = '-300px'
            document.body.appendChild(input)
            input.setAttribute('value', url)
            input.select()
            document.execCommand("copy"); // 执行浏览器复制命令
            if (document.execCommand('copy')) {
                document.execCommand('copy')
                if (this.isMobile) {
                    if (isWeixin) { // 微信内置浏览器
                        this.shareIndicte = true
                    } else {
                        Toast(this.$t('float.copyTip'))  //链接已复制，去微信粘贴给好友，或在微信浏览器右上角中分享给好友
                    }
                } else {
                    Toast(this.$t('float.copyFrendTip'))  // 链接已复制，去微信粘贴给好友
                }
            }
            document.body.removeChild(input)
        },
        // 获取小程序二维码接口
        getAppletImg () {
            this.request.get('getAppletImg', {
                live_id: this.$route.params.id
            }).then((res) => {
                if (res.code == 200) {
                    this.appletQrcode = res.data.applet_code
                }
            })
        },
        // 获取直播分享数据接口
        getWxShareList () {
            this.request.get('getWxShareList', { // 获取分享信息
                live_id: this.$route.params.id
            }).then((res) => {
                let { data } = res
                let linkUrl = `${window.location.origin}/#/${this.$route.params.id}?lang=${this.$i18n.locale}&share_code=${this.userInfo.user_id}`
                const param  = {
                    title: `${data.cover_title}`,
                    desc: data.desc,
                    link: linkUrl,
                    imgUrl: `https://files.sciconf.cn/${data.cover_path}`
                }
                this.request.post('getWeixinJsapi', { url: window.location.href, appId: data.appId }).then((res1) => { // 获取签名
                    jWeixin.config({
                        debug: false,
                        appId: data.appId,
                        timestamp: res1.timestamp, // 签名的时间戳
                        nonceStr: res1.nonceStr, // 生成签名的时间戳
                        signature: res1.signature, // 签名
                        jsApiList:['updateTimelineShareData', 'updateAppMessageShareData', 'onMenuShareQQ', 'onMenuShareWeibo']
                    })
                    // config验证通过
                    jWeixin.ready(() => {
                        // 分享给朋友及分享到QQ
                        jWeixin.updateAppMessageShareData(param)
                        // 分享到朋友圈及qq空间
                        jWeixin.updateTimelineShareData(param)
                        // 分享到QQ
                        jWeixin.onMenuShareQQ(param)
                        // 微博
                        jWeixin.onMenuShareWeibo(param)
                    })
                    jWeixin.error((err) => {
                        console.log('微信分享错误信息', err)
                    })
                })
            })
        },
        // 获取签到配置接口
        getSignConfig () {
            this.request.get('getSignConfig', {
                live_id: this.$route.params.id
            }).then((res) => {
                if (res.code == 200) {
                    this.signConfigData = res.data
                }
            })
        },
        // 签到
        liveSignHandle () {
            this.request.post('liveSign', {
                live_id: this.$route.params.id,
                meeting_id: this.meeting_id
            }).then((res) => {
                console.log(res)
                if (res.code == 2000) {
                    Toast(res.Message)
                } else if (res.data.is_period_time) { // 弹窗签到列表
                    this.signListData = res.data.sign_period_list
                    this.signListIsShow = true
                } else { // 已经签过到的展示提示文案不弹窗
                    Dialog.alert({
                        message: '签到成功'
                    })   
                }
            })
        },
        // 创建当前直播页面二维码
        createLivePageQrcode () {
            const qrcode = new QRCode(this.$refs.livePageQrcode, {
                text: window.location.href, // 用于生成二维码的文本
                width: 200, // 高度
                height: 200, // 宽度
                colorDark: '#000000', //前景色
                colorLight: '#ffffff', //后景色
                correctLevel: QRCode.CorrectLevel.H,
            })
        },
        // 创建海报页面二维码
        createPosterQrcode () {
            const qrcode = new QRCode(this.$refs.posterQrcode, {
                text: `https://www.sciconf.cn/m/lives/online-share/${this.$route.params.id}?lang=cn`, // 用于生成二维码的文本
                width: 200, // 高度
                height: 200, // 宽度
                colorDark: '#000000', //前景色
                colorLight: '#ffffff', //后景色
                correctLevel: QRCode.CorrectLevel.H,
            })
        }
        
    },
    created () {
        this.getSignConfig()
        this.getAppletImg()
        this.getWxShareList()
       if (window.screen.width > 500) { // pc端
            this.isMobile = false
       } else { // 移动端
            this.isMobile = true
       }
    },
    mounted () {
        if (!this.isMobile) {
            this.createPosterQrcode()
            this.createLivePageQrcode()
            const offsetLeft = document.getElementById('container').offsetLeft
            let mShareWidth = this.$refs.mShare.clientWidth;
            if (offsetLeft > 0 && ((offsetLeft - 30) > mShareWidth)) {
                this.shareLeft = `${offsetLeft + 1200 + 30}px`
            } else {
                this.shareLeft = ''
                this.shareRight = `10px`
            }
        } else {
            this.shareLeft = ''
            this.shareRight = '0px'
        }
    },
}
</script>
<style lang="less" scoped>
.right {
    position: fixed;
    right: 0;
    bottom: 7rem;
    border-radius: 1.01rem 0rem 0rem 1.01rem;
    background: #FFFFFF;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 0.8rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 0.7rem;
    box-shadow: 0px 0px 10px 0px 
		rgba(61, 101, 232, 0.25);
    text-align: center;
}
.m-share {
    .close-popup {
        position: absolute;
        left: 0rem;
        top: -10px;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        border: 1px solid #a2a2a2;
        text-align: center;
        line-height: 0.7rem;
        font-size: 0.4rem;
        background: #FFFFFF;
    }
    position: fixed;
    right: 0;
    bottom: 5rem;
	border-radius: 1.01rem 0rem 0rem 1.01rem;
    background: var(--floatBgColor);
    padding: 0.64rem 0;
    box-sizing: border-box;
    width: 2.1rem;
    box-shadow: 0px 0px 10px 0px 
		rgba(61, 101, 232, 0.25);
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-wrap: wrap;
    z-index: 66;
    >div{
        img {
            width: 1.25rem;
        }
        >p {
            font-size: 0.48rem;
        }
        .content {
            display: block;
            position: absolute;
            padding: 0.5rem;
            background: #FFFFFF;
            right: 2rem;
            top: 0.1rem;
            border-radius: 10px;
            &:before {
                content: '';
                position: absolute;
                border: 10px solid transparent;
                border-left-color: #FFFFFF;
                right: -0.6rem;
                top: 0.4rem;
            }
        }
    }
    .sign {
        margin-right: 0.1rem;
        margin-bottom: 0.5rem;
        text-align: center;
        cursor: pointer;
        position: relative;
        >img {
            width: 1rem;
        }
        >p {
            color: #0067FA;
        }
    }
    .share {
        margin-bottom: 0.5rem;
        text-align: center;
        cursor: pointer;
        position: relative;
        >p {
            color: #E1675A;
        }
        .content {
            display: none;
            width: 11rem;
            .item {
                float: left;
                img {
                    width: 5rem;
                }
                /deep/ div {
                    img {
                        width: 5rem;
                    }
                }
            }
            .first-item {
                margin-right: 1rem;
            }
        }
    }
    .scan {
        text-align: center;
        cursor: pointer;
        position: relative;
       >p {
            color: #50CFE4;
        }
        .content {
            display: none;
            width: 4rem;
            .title {
                .icon {
                    width: 1.5rem;
                }
                 /deep/ div {
                    font-size: 0.48rem;
                    img {
                        width: 3.5rem;
                        margin: 0 auto;
                        margin-top: 0.2rem;
                    }
                }
            }
            .wechat {
                border-top: 1px solid #EEEEEE;
                border-bottom: 1px solid #EEEEEE;
                padding: 0.8rem 0;
                margin-top: 0.5rem;
                img {
                    width: 1.5rem;
                }
                p {
                    font-size: 0.48rem;
                }
            }
            .ranking {
                margin-top: 0.8rem;
                img {
                    width: 1.25rem;
                }
                p {
                    font-size: 0.48rem;
                }
            }
        }
    }
    .applet {
        color: #50CFE4;
        margin-top: 0.5rem;
        .icon {
            font-size: 0.8rem;
            text-align: center;
        }
        p {
            font-size: 0.48rem;
            margin-top: 0.1rem;
            text-align: center;
        }
    }
    .applet-popup {
        background: none;
        padding: 0 0.7rem;
        box-sizing: border-box;
        .content-bg {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: 66;
            height: 5rem;
        }
        .popup-content {
            width: 100%;
            position: relative;
            height: 15.5rem;
            background: #FFFFFF;
            padding-top: 1rem;
            box-sizing: border-box;
            border-radius: 20px;
            text-align: center;
            .title {
                width: 100%;
                text-align: center;
                font-size: 0.64rem;
            }
            img {
                width: 70%;
                margin-top: 0.8rem;
            }
            .close {
                position: absolute;
                right: 0.5rem;
                top: 0.5rem;
                font-size: 0.46rem;
            }
        }
    }
    .share-popup {
        width: 100%;
        height: 4rem;
        border-radius: 20px 20px 0 0;
        .share-popup-content {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .share-popup-item {
                flex: 1;
                text-align: center;
            }
            .poseter-item {
                img {
                    width: 1.3rem;
                }
            }
            .friend-item {
                img {
                    width: 1.6rem;
                }
            }
            .ranking-item {
                img {
                    width: 0.95rem;
                }
            }
        }
    }
    .share-indicte {
        background: none;
        img {
            width: 100%;
        }
    }
    .sign-list {
        width: 14rem;
        height: 18rem;
        border-radius: 20px;
        overflow: hidden;
        .sign-list-title {
            height: 2rem;
            line-height: 2rem;
            font-size: 0.62rem;
            font-weight: bold;
            text-align: center;
        }
        .sign-list-content {
            width: 100%;
            height: calc(100% - 2rem);
            padding: 0 1rem;
            box-sizing: border-box;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }
            &::-webkit-scrollbar-track-piece {
                background: rgba(0, 0, 0, 0.1);
                -webkit-border-radius: 6px;
            }
            .close {
                position: absolute;
                right: 0.4rem;
                top: 0.4rem;
                font-size: 0.62rem;
            }
            ul {
                li {
                    font-size: 0.56rem;
                    &.success {
                        background: #EBFFF6;
                    }
                    &.error {
                        background: #FFF2F2;
                    }
                    padding: 0.5rem;
                    box-sizing: border-box;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 0.5rem;
                    .left-content {
                        flex: 1;
                        .date {
                            font-weight: bold;
                            margin-bottom: 0.2rem;
                        }
                    }
                    .right-content {
                        .text {
                            font-size: 0.64rem;
                        }
                        .icon {
                            margin-right: 0.2rem;
                            font-size: 0.62rem;
                            &.success {
                                color: #2FAE79;
                            }
                            &.error {
                                color: #E84335;
                            }
                        }
                    }
                }
            }
        }
    }
}
// 移动端
@media screen and (max-width: 550px) {
    
}
// pc端
@media screen and (min-width: 550px) {
    .m-share {
        bottom: 14rem !important;
        border-radius: 1.01rem !important;
        >div{
            &:hover {
                .content {
                    display: block;
                }
            }
        }
    }
}
</style>